import Braid from "../Assets/Icons/Abilities/Bloodlov/Braid.svg"
import Debuff from "../Assets/Icons/Abilities/Bloodlov/Debuff.svg"
import Shield from "../Assets/Icons/Abilities/Bloodlov/Shield.svg"
import Vampire from "../Assets/Icons/Abilities/Bloodlov/Vampire.svg"

import Bullets from "../Assets/Icons/Abilities/Buller/Bullets.svg"
import Nade from "../Assets/Icons/Abilities/Buller/Nade.svg"
import Portal from "../Assets/Icons/Abilities/Buller/Portal.svg"
import Re from "../Assets/Icons/Abilities/Buller/Re.svg"

import GHNade from "../Assets/Icons/Abilities/GoldHunter/GHNade.svg"
import GHPortal from "../Assets/Icons/Abilities/GoldHunter/GHPortal.svg"
import Restart from "../Assets/Icons/Abilities/GoldHunter/Restart.svg"
import Stun from "../Assets/Icons/Abilities/GoldHunter/Stun.svg"

import Disarm from "../Assets/Icons/Abilities/Lomix/Disarm.svg"
import LomixNade from "../Assets/Icons/Abilities/Lomix/LomixNade.svg"
import LomixPortal from "../Assets/Icons/Abilities/Lomix/LomixPortal.svg"
import Mine from "../Assets/Icons/Abilities/Lomix/Mine.svg"

import PFBuff from "../Assets/Icons/Abilities/Powerf/Buff.svg"
import Heart from "../Assets/Icons/Abilities/Powerf/Heart.svg"
import PFShield from "../Assets/Icons/Abilities/Powerf/Shield.svg"
import Speed from "../Assets/Icons/Abilities/Powerf/Speed.svg"

import Bullet from "../Assets/Icons/Abilities/Silen/Bullet.svg"
import Dash from "../Assets/Icons/Abilities/Silen/Dash.svg"
import SilenNade from "../Assets/Icons/Abilities/Silen/Nade.svg"
import Storm from "../Assets/Icons/Abilities/Silen/Storm.svg"

import SolarayBuff from "../Assets/Icons/Abilities/Solaray/Buff.svg"
import Freeze from "../Assets/Icons/Abilities/Solaray/Freeze.svg"
import SolarayNade from "../Assets/Icons/Abilities/Solaray/Nade.svg"
import SolarayShield from "../Assets/Icons/Abilities/Solaray/Shield.svg"

const icons: { [key: string]: { icon: any; info: string }[] } = {
	bloodlov: [
		{
			icon: Braid,
			info: `Throws the scythe forward 3 squares, then it returns, it deals 10 damage to everyone it hits. CD 15 seconds`,
		},
		{
			icon: Shield,
			info: `Energy Shield 2. When pressed, it creates a shield that reduces the amount of damage received by 50% for 5 seconds. CD 40 seconds`,
		},
		{
			icon: Debuff,
			info: `General deceleration. Slowing down the speed of movement of cubes by 50% within a radius of 3 cells. CD 20 seconds`,
		},
		{
			icon: Vampire,
			info: `Cyber Vampire - steals 10% of the percentage of life from the damage inflicted. Passive ability`,
		},
	],
	buller: [
		{
			icon: Nade,
			info: `The slowing grenade Rushes forward At a distance of 3 cells when falling deals 10 damage within a radius of 3 cells Freezes the enemy for 2 seconds CD 10 seconds`,
		},
		{
			icon: Bullets,
			info: `Creates 2 cartridges in the hero's clip instead of 1 CD for 30 seconds`,
		},
		{
			icon: Portal,
			info: `Wandering Teleport - teleports a character to a random location within a radius of 3 to 6 CD cells for 20 seconds`,
		},
		{
			icon: Re,
			info: `Instant recharge and recovery of the laser charge`,
		},
	],
	goldhunter: [
		{
			icon: GHNade,
			info: `The attacking grenade Rushes forward At a distance of 3 cells when falling, it deals 30 damage within a radius of 3 cells CD 15 seconds`,
		},
		{
			icon: Stun,
			info: `When activated, the next 5 rounds will hang a Stun when hit for 2 seconds CD 30 seconds`,
		},
		{
			icon: GHPortal,
			info: `Teleport forward by 4 cells CD 15 seconds`,
		},
		{
			icon: Restart,
			info: `Restart - resets cooldowns of all other abilities for 40 seconds`,
		},
	],
	lomix: [
		{
			icon: Mine,
			info: `Setting a mine. A mine is installed in the place where the cube stands. Which does not cause damage to the creator, but causes damage to the first enemy that steps on it. Charges 3. CD 10 seconds. Lifetime 40 seconds`,
		},
		{
			icon: Disarm,
			info: `The Disarm effect Deprives the weapon of the firing function of all cubes within a radius of 3 cells for 3 seconds. CD 30 seconds`,
		},
		{
			icon: LomixPortal,
			info: `Sets a teleport to which it can return by pressing again if it is within a radius of 7 cells CD of pressing again for 20 seconds`,
		},
		{
			icon: LomixNade,
			info: `Throws a bomb that explodes in 3 seconds within a radius of 3 cells and will cause 100 damage to all enemies for 40 seconds`,
		},
	],
	powerf: [
		{
			icon: PFBuff,
			info: `Increased power. When used, it increases the damage from the laser and similar weapons, while increasing energy consumption`,
		},
		{
			icon: PFShield,
			info: `Energy shield. When pressed, it creates a shield that absorbs 30 damage for 10 seconds CD 40 seconds`,
		},
		{
			icon: Speed,
			info: `Increase the speed of the cube by 50% for 5 seconds CD 30 seconds`,
		},
		{
			icon: Heart,
			info: `Health treatment for yourself for 25 HP CD 30 seconds`,
		},
	],
	silen: [
		{
			icon: SilenNade,
			info: `The EMP grenade Rushes forward At a distance of 3 cells when falling, it causes 10 When hit, the heroes cannot use abilities for 2 seconds CD 15 seconds`,
		},
		{
			icon: Bullet,
			info: `EMP effect when hit, the enemy cube cannot use abilities. Within 2 seconds CD 30 seconds`,
		},
		{
			icon: Dash,
			info: `Dash jerk. Very fast movement of the character forward, for a certain number of CD cells for 15 seconds`,
		},
		{
			icon: Storm,
			info: `Electromagnetic storm - the creation of an electromagnetic storm with a certain radius that sweeps across the map deals damage and imposes EMP effects in the area of the CD for 40 seconds`,
		},
	],
	solaray: [
		{
			icon: SolarayNade,
			info: `A defensive grenade Rushes forward to a distance of 3 cells When falling, deals 10 damage within a radius of 3 cells and stuns everyone who has been damaged for 1.5 seconds CD 15 seconds`,
		},
		{
			icon: SolarayBuff,
			info: `A fatal hit will not cause damage, instead the hero will recover 20% hp CD 40 sec`,
		},
		{
			icon: Freeze,
			info: `Instantly freezes all heroes (unable to move, but can turn and use abilities and shoot) within a radius of 5 cells for 2 seconds CD 20 seconds`,
		},
		{
			icon: SolarayShield,
			info: `When activated, the hero gets to deal critical damage with a probability of 20% CD 30 sec`,
		},
	],
}

export function getAbilitiesIcons(nftName: string): { icon: any; info: string }[] {
	const name = nftName.toLowerCase()

	// if (!icons[name]) return []

	return icons[name]
}
