import { ArrowRight } from "Assets/Icons/Common"
import { useNavigate } from "react-router-dom"
import Title from "../../../Atoms/Auth/Title/styles"
import { AboutBlock, BackButton, HeadBlock, MainHeader } from "./styles"

function ManualTop() {
	const navigate = useNavigate()

	const backButtonHandler = () => {
		navigate('/')
	}

	return (
		<HeadBlock>
			<MainHeader>
				<BackButton onClick={backButtonHandler}>
					<ArrowRight />
				</BackButton>
				<Title fz={36} fw={700} uppercase={true}>
					How to create and set up MetaMask
				</Title>
			</MainHeader>
			<AboutBlock>
				<p>ABOUT METAMASK</p>
				<p>
					MetaMask can be downloaded for Chrome and Firefox, or iOS and Android for mobiles. Instructions would be similar for every
					platform.
					<br />
					<br />
					First – go to MetaMask download page -
					<a href="https://metamask.io/" target="_blank" rel="noreferrer">
						https://metamask.io/
					</a>
					<br />
					Choose the platform you are using and follow the instructions. Easy!
				</p>
			</AboutBlock>
		</HeadBlock>
	)
}

export default ManualTop
