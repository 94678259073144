import styled from "styled-components"

export const Wrapper = styled.div`
	grid-area: newMinPlayerForStart;
	white-space: nowrap;
	display: grid;
	place-items: center;
	grid-auto-flow: column;

	& * {
		user-select: none;
	}
`

export const ChooseTitle = styled.div`
	text-transform: uppercase;
	font-size: 30px;
	letter-spacing: 2px;
	font-weight: 900;

	@media (max-width: 1800px) {
		font-size: 25px;
		letter-spacing: 2px;
	}
	@media (max-width: 1350px) {
		font-size: 20px;
		letter-spacing: 2px;
	}
	@media (max-width: 1150px) {
		font-size: 15px;
		letter-spacing: 2px;
	}
`

export const ChooseMinPlayersForStartBody = styled.div`
	display: grid;
	grid-auto-flow: column;
	/* grid-template-columns: 1fr 1fr 1fr; */
	place-items: center;
	/* place-items: center; */
	/* height: 40px; */

	border-radius: 5px;
	/* backdrop-filter: blur(4px); */
`

export const ChooseCountPlayers = styled.div<{ warning: boolean }>`
	height: 65px;
	width: 65px;

	margin: 10px;
	padding: 10px;

	font-weight: 900;
	font-size: 42px;
	line-height: 42px;

	text-align: center;

	user-select: none;

	color: ${({ warning }) => (warning ? "red" : "white")};
	transition: all 0.3s;
	border: 1px white solid;
	border-radius: 2px;

	@media (max-width: 1800px) {
		height: 60px;
		width: 60px;

		margin: 10px;
		padding: 10px;

		font-weight: 900;
		font-size: 40px;
		line-height: 38px;
	}
	@media (max-width: 1350px) {
		height: 50px;
		width: 50px;

		margin: 8px;
		padding: 8px;

		font-weight: 900;
		font-size: 32px;
		line-height: 30px;
	}
	@media (max-width: 1150px) {
		height: 35px;
		width: 35px;

		margin: 8px;
		padding: 8px;

		font-weight: 900;
		font-size: 25px;
		line-height: 16px;
	}
`

const NumberChangeButton = styled.div`
	cursor: pointer;
	height: 35px;
	width: 35px;

	display: grid;
	place-items: center;

	border-radius: 5px;

	div {
		font-size: 42px;
		line-height: 0px;
		text-align: center;
		vertical-align: middle;
		margin-right: -2px;
	}

	text-align: center;

	background-color: rgba(0, 0, 0, 0.35);
	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
`

export const Dicrement = styled(NumberChangeButton)`
	div {
		margin-top: -6px;
	}
`
export const Increment = styled(NumberChangeButton)``
