import styled from "styled-components"

export const Container = styled.div`
	/* margin: 0 15px; */
	background-color: rgba(0, 0, 0, 0.4);
	padding: 10px 15px;
	border-radius: 5px;
	backdrop-filter: blur(5px);
	user-select: none;
	/* height: min-content; */
	display: grid;
	grid-template-rows: auto 1fr;

	@media (min-width: 1800px) {
	}
	`

export const Title = styled.div`
	position: relative;
	text-transform: uppercase;
	font-size: 18px;
	font-weight: 900;
	letter-spacing: 1px;
	@media (min-width: 1800px) {
		font-size: 21px;
	}
	& svg {
		margin-left: 20px;
		opacity: 0.5;
	}
`

export const Content = styled.div`
	/* height: 100%; */
	/* display: grid;
    justify-items: stretch; */

	display: grid;
	place-items: center;
	/* height: 100%; */
`
