import React from 'react';
import {OrangeButton as Btn} from '../../../../Assets/Icons/Matchmaking';
import {Container, Text} from './styles';

function OrangeButton({ text, onClick }: { text: string, onClick?: () => void }) {
    return (
        <Container onClick={onClick}>
            <Btn />
            <Text>{text}</Text>
        </Container>
    );
}

export default OrangeButton;
