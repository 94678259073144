import React from 'react';
import UpdateUser from '../UpdateUser';
import ConnectTelegram from '../ConnectTelegram';
import {CloseIcon} from 'Assets/Icons/Common'
import {Overlay, Container, Close} from './styles';

interface IProps {
    popup: string | null
    changePopup: (popup: string | null) => void
}

function Popups({ popup, changePopup }: IProps) {
    return (
        <Overlay visible={Boolean(popup)}>
            <Container>
                <Close>
                    <CloseIcon onClick={() => changePopup(null)}/>
                </Close>
                {
                    popup === 'update' && <UpdateUser closePopup={() => changePopup(null)}/>
                }
                {
                    popup === 'telegram' && <ConnectTelegram closePopup={() => changePopup(null)}/>
                }
            </Container>
        </Overlay>
    )
}

export default Popups;
