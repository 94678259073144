import React, {useEffect} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import AuthLayout from '../Components/Layouts/AuthLayout/Layout';
import Select from '../Pages/Auth/SelectAuthMethod/SelectAuthMethod';
import SelectAccount from '../Pages/Auth/Metamask/SelectAccount/SelectAccount';
import NotInstalled from '../Pages/Auth/NotInstalled/NotInstalled';
import FailedToConnect from '../Pages/Auth/FailedToConnect/FailedToConnect';
import VerifyMessage from '../Pages/Auth/VerifyMessage/VerifyMessage';
import FailedToVerify from '../Pages/Auth/FailedToVerify/FailedToVerify';
import NewUser from '../Pages/Auth/NewUser/NewUser';
import NotFound from '../Pages/Auth/NotFound/NotFound';
import NearConnect from 'Pages/Auth/Near/NearConnect/NearConnect'
import FailedToConnectNear from 'Pages/Auth/Near/FailedToConnectNear'
// import ArcanaAuthPage from 'Pages/Auth/Arcana'
// import MeteorAuthPage from 'Pages/Auth/Meteor'

const EmailLogin = () => <h1>Email Login</h1>
const SocialLogin = () => <h1>Social Login</h1>


const AuthRoutes = () => {
    const location = useLocation()

    useEffect(() => {
        console.log(location.pathname)
    }, [location])

    return (
        <Routes>
            <Route element={<AuthLayout/>}>
                {/* <Route index element={<Select/>}/> */}
                {/* <Route path="email" element={<EmailLogin/>}/>
                <Route path="social" element={<SocialLogin/>}/>
                <Route path="metamask">
                    <Route index element={<SelectAccount/>}/>
                    <Route path="not_installed" element={<NotInstalled/>}/>
                    <Route path="failure" element={<FailedToConnect/>}/>
                    <Route path="verification">
                        <Route index element={<VerifyMessage/>}/>
                        <Route path="failure" element={<FailedToVerify/>}/>
                    </Route>
                </Route>
                <Route path='arcana'>
                    <Route index element={<ArcanaAuthPage/>}/>
                </Route> */}
                {/* <Route path='near_apps'>
                    <Route index element={<MeteorAuthPage/>}/>
                </Route> */}
                {/* <Route path="near">
                    <Route index element={<NearConnect/>}/>
                    <Route path="failure" element={<FailedToConnectNear/>}/>
                </Route> */}
                {/* <Route path="new_user" element={<NewUser/>}/> */}
            </Route>
            <Route path="*" element={<NotFound />}/>
        </Routes>
    )
}

export default AuthRoutes;
