import { AuthContainer, AuthContentBlock, AuthWrapper } from "Components/Layouts/AuthLayout/styles"
import { Title } from "Components/Organisms/Matchmaking/ChooseMap/styles"
import React from "react"
import SubTitle from "../../../Components/Atoms/Auth/SubTitle/styles"

function NotFound() {
	return (
		<AuthWrapper>
			<AuthContainer>
				<AuthContentBlock>
					<Title fz={60} style={{ textAlign: "center" }}>
						404 | Not Found
					</Title>
				</AuthContentBlock>
			</AuthContainer>
		</AuthWrapper>
	)
}

export default NotFound
