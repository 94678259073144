import styled from "styled-components"
import { Flex } from "globalStyles"

export const Container = styled(Flex)`
	flex-direction: column;
	align-items: center;
	margin-top: 50px;
	padding-bottom: 45px;

	> span {
		margin-bottom: 10px;
	}
`

export const InputTitle = styled(Flex)`
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 16px;
	margin-left: 10px;
	width: 100%;

	> span {
		font-family: "Red Hat Display", sans-serif;
		font-size: 16px;
		line-height: 20px;
	}
`

export const Input = styled.input`
	width: 100%;
	height: 45px;
	padding: 10px;
	margin-bottom: 30px;
	background: none;
	font-family: "Red Hat Display", sans-serif;
	font-size: 16px;
	color: rgba(255, 255, 255, 0.6);
	line-height: 20px;
	border: 1px solid rgba(255, 255, 255, 0.15);
	border-radius: 8px;
	outline: none;
`

export const SendInfo = styled.span`
	font-family: "Red Hat Display", sans-serif;
	font-size: 16px;
	line-height: 20px;
	color: rgba(255, 255, 255, 0.5);
	margin-top: 15px;
`
