import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import storage from "redux-persist/lib/storage"
import MetamaskReducer from "./reducers/auth/MetamaskSlice"
import NearReducer from "./reducers/auth/NearSlice"
import coinReducer from "./reducers/CoinSlice"
import assetReducer from "./reducers/AssetSlice"
import exchangeReducer from "./reducers/ExchangeSlice"
import marketReducer from "./reducers/MarketSlice"
import gameReducer from "./reducers/GameSlice"
import wnftReducer from "./reducers/WNFTSlice"
import tonReducer from "./reducers/auth/TonSlice"
// import arcanaReducer from "./reducers/auth/AcranaSlice"
import { authAPI } from "../services/AuthService"
import { filesAPI } from "../services/FilesService"
import { userAPI } from "../services/UserService"
import { walletAPI } from "../services/WalletService"
import { coinAPI } from "../services/CoinService"
import { assetAPI } from "../services/AssetService"
import { exchangeAPI } from "../services/ExchangeService"
import { marketAPI } from "../services/MarketService"
import { matchmakingAPI } from "../services/MatchmakingService"
import { gamesAPI } from "../services/GamesService"
import { ledgerAPI } from "../services/LedgerService"
import { rentNFTAPI } from "../services/RentNFTService"

const persistConfig = {
	key: "root",
	storage,
	whitelist: [],
}

const rootReducer = combineReducers({
	metamaskReducer: MetamaskReducer,
	nearReducer: NearReducer,
	coin: coinReducer,
	asset: assetReducer,
	exchange: exchangeReducer,
	market: marketReducer,
	game: gameReducer,
	wnft: wnftReducer,
	ton: tonReducer,
	// arcana: arcanaReducer,
	[authAPI.reducerPath]: authAPI.reducer,
	[filesAPI.reducerPath]: filesAPI.reducer,
	[userAPI.reducerPath]: userAPI.reducer,
	[walletAPI.reducerPath]: walletAPI.reducer,
	[coinAPI.reducerPath]: coinAPI.reducer,
	[assetAPI.reducerPath]: assetAPI.reducer,
	[exchangeAPI.reducerPath]: exchangeAPI.reducer,
	[marketAPI.reducerPath]: marketAPI.reducer,
	[matchmakingAPI.reducerPath]: matchmakingAPI.reducer,
	[gamesAPI.reducerPath]: gamesAPI.reducer,
	[ledgerAPI.reducerPath]: ledgerAPI.reducer,
	[rentNFTAPI.reducerPath]: rentNFTAPI.reducer,
})

// const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = () => {
	return configureStore({
		// reducer: persistedReducer,
		reducer: rootReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: false
				// {
				// 	ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				// },
			})
				.concat(authAPI.middleware)
				.concat(filesAPI.middleware)
				.concat(userAPI.middleware)
				.concat(walletAPI.middleware)
				.concat(coinAPI.middleware)
				.concat(assetAPI.middleware)
				.concat(exchangeAPI.middleware)
				.concat(marketAPI.middleware)
				.concat(matchmakingAPI.middleware)
				.concat(gamesAPI.middleware)
				.concat(ledgerAPI.middleware)
				.concat(rentNFTAPI.middleware),
	})
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
