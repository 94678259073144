export const savedNFTs = [
	{
		metadata: {
			description: "Warrior. Deals heavy melee damage to any enemy.",
			external_url: "https://hub.qubixinfinity.io/",
			image: "https://hub.qubixinfinity.io/contracts/1.png",
			name: "Bloodlov",
			attributes: [
				{
					trait_type: "Health",
					value: 120,
					max_value: 125,
					min_value: 80,
				},
				// {
				// 	trait_type: "Armor",
				// 	value: 15,
				// 	max_value: 20,
				// },
				{
					trait_type: "Speed",
					value: 4,
					max_value: 6,
					min_value: 4,
				},
				{
					trait_type: "Strength",
					value: 450,
					max_value: 517.5,
					min_value: 420,
				},
			],
		},
		metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
		blockchain: "GOR",
		tokenId: "111111",
		tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
		wNFTTokenId: "111111",
		wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
		walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
		status: "wrapped",
		isDefaultNFT: true,
	},
	{
		metadata: {
			description: "Versatile fighter. Equally good in both melee and ranged combat.",
			external_url: "https://hub.qubixinfinity.io/",
			image: "https://hub.qubixinfinity.io/contracts/4.png",
			name: "GoldHunter",
			attributes: [
				{
					trait_type: "Health",
					value: 100,
					max_value: 125,
					min_value: 80,
				},
				{
					trait_type: "Speed",
					value: 5,
					max_value: 6,
					min_value: 4,
				},
				{
					trait_type: "Strength",
					value: 460,
					max_value: 517.5,
					min_value: 420,
				},
			],
		},
		metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
		blockchain: "GOR",
		tokenId: "444444",
		tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
		wNFTTokenId: "444444",
		wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
		walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
		status: "wrapped",
		isDefaultNFT: true,
	},
	{
		metadata: {
			description: "Lively and slow. Deals little damage.",
			external_url: "https://hub.qubixinfinity.io/",
			image: "https://hub.qubixinfinity.io/contracts/5.png",
			name: "Powerf",
			attributes: [
				{
					trait_type: "Health",
					value: 125,
					max_value: 125,
					min_value: 80,
				},
				{
					trait_type: "Speed",
					value: 4,
					max_value: 6,
					min_value: 4,
				},
				{
					trait_type: "Strength",
					value: 470,
					max_value: 517.5,
					min_value: 420,
				},
			],
		},
		metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
		blockchain: "GOR",
		tokenId: "555555",
		tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
		wNFTTokenId: "555555",
		wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
		walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
		status: "wrapped",
		isDefaultNFT: true,
	},
	{
		metadata: {
			description: "Prevents enemies from using abilities.",
			external_url: "https://hub.qubixinfinity.io/",
			image: "https://hub.qubixinfinity.io/contracts/6.png",
			name: "Silen",
			attributes: [
				{
					trait_type: "Health",
					value: 100,
					max_value: 125,
					min_value: 80,
				},
				{
					trait_type: "Speed",
					value: 4,
					max_value: 6,
					min_value: 4,
				},
				{
					trait_type: "Strength",
					value: 480,
					max_value: 517.5,
					min_value: 420,
				},
			],
		},
		metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
		blockchain: "GOR",
		tokenId: "666666",
		tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
		wNFTTokenId: "666666",
		wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
		walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
		status: "wrapped",
		isDefaultNFT: true,
	},
	{
		metadata: {
			description: "Engineer. Able to set traps and break enemy mechanisms.",
			external_url: "https://hub.qubixinfinity.io/",
			image: "https://hub.qubixinfinity.io/contracts/2.png",
			name: "Lomix",
			attributes: [
				{
					trait_type: "Health",
					value: 100,
					max_value: 125,
					min_value: 80,
				},
				// {
				// 	trait_type: "Armor",
				// 	value: 0,
				// 	max_value: 20,
				// },
				{
					trait_type: "Speed",
					value: 5,
					max_value: 6,
					min_value: 4,
				},
				{
					trait_type: "Strength",
					value: 500,
					max_value: 517.5,
					min_value: 420,
				},
			],
		},
		metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
		blockchain: "GOR",
		tokenId: "222222",
		tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
		wNFTTokenId: "222222",
		wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
		walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
		status: "wrapped",
		isDefaultNFT: true,
	},
	{
		metadata: {
			description: "Very fast, hard to catch up.",
			external_url: "https://hub.qubixinfinity.io/",
			image: "https://hub.qubixinfinity.io/contracts/7.png",
			name: "Solaray",
			attributes: [
				{
					trait_type: "Health",
					value: 100,
					max_value: 125,
					min_value: 80,
				},
				{
					trait_type: "Speed",
					value: 5,
					max_value: 6,
					min_value: 4,
				},
				{
					trait_type: "Strength",
					value: 517.5,
					max_value: 517.5,
					min_value: 420,
				},
			],
		},
		metadataURI: "https://gateway.pinata.cloud/ipfs/QmSYCnGu9sCiGdP2Yrz1Ti9rUqoYe9aVscEmJwfwAH8sBv/3",
		blockchain: "GOR",
		tokenId: "777777",
		tokenAddress: "0x5a0A1347F946D0823c901f5A4a752E9a268465dB",
		wNFTTokenId: "777777",
		wNFTTokenAddress: "0xA9D984B954fBB83D68d7F3e16fbF02F1999fB4ed",
		walletAddress: "0x168A5557F29702f442fF9767129914dFCF53dD93",
		status: "wrapped",
		isDefaultNFT: true,
	},
]
