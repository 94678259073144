import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { Error } from "./types"
import { IUser } from "models/IUser"
import { hubQueryWithReauth } from "./index"

export interface CreateUserQuery {
	nickname: string
	icon: string
}

export const userAPI = createApi({
	reducerPath: "userAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getMe: build.query<IUser, void>({
			query: () => ({
				url: "/users/me",
			}),
		}),
		updateUser: build.mutation<{ avatars: string[] }, CreateUserQuery>({
			query: body => ({
				url: "/users/me",
				method: "PATCH",
				body,
			}),
		}),
		checkNickname: build.query<{ exist: boolean }, string>({
			query: nickname => ({
				url: `/users/checkNickname?nickname=${nickname}`,
			}),
		}),
		initTelegram: build.mutation<void, { nickname: string }>({
			query: body => ({
				url: "/users/telegram-check",
				method: "POST",
				body,
			}),
		}),
	}),
})

export const { useGetMeQuery, useUpdateUserMutation, useCheckNicknameQuery, useInitTelegramMutation, useLazyCheckNicknameQuery } = userAPI
