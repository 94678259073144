import React, { useEffect } from "react"
import { Routes, Route, useNavigate, useLocation, Navigate, redirect } from "react-router-dom"
import AuthRoutes from "./AuthRoutes"
// import MarketplaceRoutes from './MarketplaceRoutes';
import NotFound from "../Pages/Auth/NotFound/NotFound"
import PrivateRoute from "./PrivateRoute"
import WrongNetwork from "../Pages/Auth/WrongNetwork/WrongNetwork"
import Manual from "../Pages/Auth/Manual/Manual"
import About from "../Pages/About"
import GamesRoutes from "./GamesRoutes"
import MatchmakingRoutes from "./MatchmakingRoutes"
import ProfileRoutes from "./ProfileRoutes"
import LendingRoutes from "./LendingRouter"
import { isAnonym, isArcanaUser, isNearUser } from "utils/checkUser"
import PlaceBet from "Components/Organisms/Bet/PlaceBet"
import NearRoutes from "./NearRoutes"
import { RoomsPage } from "Pages/Rooms"

const AppRouter = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()

	useEffect(() => {
		if (isAnonym()) return
		if (isNearUser()) return
		if (isArcanaUser()) return
		if (pathname !== "/") return
		console.log({ pathname })
		const access = sessionStorage.getItem("access")
		const refresh = sessionStorage.getItem("refresh")
		if (!access || !refresh) {
			console.log("REDIRECT")
			return navigate("/auth")
		}
		// navigate("/")
	}, [pathname, navigate])

	return (
		<Routes>
			<Route path="/">
				<Route index element={<Navigate to="/profile" />} />
				<Route
					path="profile/*"
					element={
						<PrivateRoute>
							<ProfileRoutes />
						</PrivateRoute>
					}
				/>
				<Route path="auth/*" element={<AuthRoutes />} />
				{/*<Route path="marketplace/*" element={*/}
				{/*    <PrivateRoute>*/}
				{/*        <MarketplaceRoutes/>*/}
				{/*    </PrivateRoute>}*/}
				{/*/>*/}
				<Route
					path="games/*"
					element={
						<PrivateRoute>
							<GamesRoutes />
						</PrivateRoute>
					}
				/>
				<Route
					path="matchmaking/*"
					element={
						<PrivateRoute>
							<MatchmakingRoutes />
						</PrivateRoute>
					}
				/>
				<Route path="about" element={<About />} />
				<Route path="not_found" element={<NotFound />} />
			</Route>
 			{/* <Route path="rooms" element={<RoomsPage/>} /> */}
 			<Route path="near/*" element={<NearRoutes/>} />
			<Route path="place_a_bet" element={<PlaceBet />} />
			<Route path="manual" element={<Manual />} />
			{/* <Route path="wrong_network" element={<WrongNetwork />} /> */}
			<Route path="*" element={<Navigate to="/not_found" />} />
		</Routes>
	)
}

export default AppRouter
