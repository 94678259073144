import { Circle, Instruct, Line, Number, Row, Rows, ScreenShot, SteppedLine, Text, Wrapper } from "./styles"

import step1 from "Assets/Images/Manual/manual1.png"
import step2 from "Assets/Images/Manual/manual2.png"
import step3 from "Assets/Images/Manual/manual3.png"
import step4 from "Assets/Images/Manual/manual4.png"
import step5 from "Assets/Images/Manual/manual5.png"
import step6 from "Assets/Images/Manual/manual6.png"
import step7 from "Assets/Images/Manual/manual7.png"

const List = [
	"Then click MetaMask icon in (plugins) and welcome screen would appear. Press (Begin Button)",
	"The (first time on MetaMaks) would pop up. Choose (Create Wallet) option.",
	"Then the page with (Help us improve MetaMask) would appear. You can agree or decline, the choice would not affect your following experience.",
	"After that you would be asked to create a password. It is better to make a long and difficult password, because this wallet would hold your valuable assets!",
	"After the wallet is created, you would be shown a video about a (secret phrase). Click (next) after you watch it.",
	"After that, you would see the secret (secret phrase) to restore your wallet. You must write it down and keep it safe. If you forget your password – this (phrase) would help you restore access. Then click (next) after you saved the (secret phrase).",
	"The page to confirm (secret phrase) would appear. You would need to place words and phrases if a correct order in the upper field. You can either click the words in a correct order in the lower field, or change the order in the upper. When the phrase would be correct – the button to (confirm) would become active.",
]

const steps = [
	{ url: step1, width: 58 },
	{ url: step2, width: 100 },
	{ url: step3, width: 60 },
	{ url: step4, width: 60 },
	{ url: step5, width: 90 },
	{ url: step6, width: 80 },
	{ url: step7, width: 70 },
]

function ManualTable() {
	return (
		<Wrapper>
			<SteppedLine>
				<Line />
			</SteppedLine>
			<Rows>
				{List.map((text, index) => (
					<Row>
						<Instruct>
							<Number>0{(index + 1)}</Number>
							<Text>{text}</Text>
						</Instruct>
						<ScreenShot width={steps[index].width}>
							<img src={steps[index].url} alt="" />
						</ScreenShot>
						{index !== List.length - 1 && <Circle />}
					</Row>
				))}
			</Rows>
		</Wrapper>
	)
}

export default ManualTable
