import React from "react"
import { Outlet } from "react-router-dom"
import { GamesWrapper, GamesContainer } from "./styles"
import SideMenu from "../../Organisms/Common/SideMenu/SideMenu"

function GamesLayout() {
	return (
		<GamesWrapper>
			<Outlet />
		</GamesWrapper>
	)
}

export default GamesLayout
