import { FC } from "react"
import { Container, Title, Content } from "./style"
import { ReactComponent as InfoIcon } from "../../../../Assets/Images/InfoIcon.svg"

interface Props {
	title: string
	children: React.ReactNode
	style?: any
	contentStyle?:any
}

const ContentBlockHOC: FC<Props> = ({ title, children, style, contentStyle }) => {
	return (
		<Container style={style}>
			<Title>
				{title}
				{/* <InfoIcon /> */}
			</Title>
			<Content style={contentStyle}>{children}</Content>
		</Container>
	)
}

export default ContentBlockHOC
