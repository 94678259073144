import styled from "styled-components"
import { Content } from "../../../globalStyles"
import background from "Assets/Images/Background.jpg"
import { ProfileWrapper } from "../ProfileLayout/styles"

export const GamesWrapper = styled(ProfileWrapper)`
	/* position: relative;
    display: flex; */
	/* background: url(${background}) no-repeat; */
	/* background-size: 100% 100%; */
	/* width: 100%;
    min-height: 100vh;
    z-index: 1;
    box-sizing: border-box;
    overflow-x: hidden; */
	display: block;

	@media (max-height: 719px) {
		overflow-x: scroll;
	}
`

export const GamesContainer = styled.div`
	//     margin-top: 40px;
	//     margin-right: 40px;
	//     margin-left: 20px;
	//     gap: 40px;
	//     width: 75%;
`
