import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { hubQueryWithReauth } from "./index"
import { Error } from "./types"

export interface Mode {
	name: string
	description: string
	anotherDescription: string
	query: string
	scheme: string
}

export interface Map {
	id: number
	uuid: string
	name: string
	sizes: string
	mode: string
	scheme: string
	url: string
}

export const gamesAPI = createApi({
	reducerPath: "gamesAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getModes: build.query<Mode[], void>({
			query: () => ({
				url: "/games/modes",
			}),
		}),
		getMapsByModes: build.query<Map[], string | null>({
			query: query => ({
				url: `/games/maps?mode=${query}`,
			}),
		}),
		getAllMaps: build.query<Map[], void>({
			query: () => ({
				url: "/games/allmaps",
			}),
			transformResponse(response: Map[]) {
				const arr = response
					.filter(map => map.name === "Neon" || map.name === "Colloseum")
					.map(map => {
						if (map.name === "Colloseum") {
							map.id = 1
						}
						if (map.name === "Neon") {
							map.id = 2
						}
						return map
					})
				console.log({ arr })
				return arr
			},
		}),
	}),
})

export const { useGetModesQuery, useGetMapsByModesQuery, useGetAllMapsQuery } = gamesAPI
