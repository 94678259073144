import React, { useEffect, useState } from "react"
import { Outlet, Link, useNavigate } from "react-router-dom"
import SideMenu from "Components/Organisms/Common/SideMenu/SideMenu"
import ProfileHeader from "Components/Organisms/Profile/ProfileHeader"
import Popups from "Components/Organisms/Profile/Popups"
import {
	ProfileWrapper,
	ProfileFooter,
	Links,
	QubixAuthor,
	Socials,
	SocialsList,
	SocialsTitle,
	SocialItem,
	ProfileBody
} from "./styles"
import { MenuLogo } from "Assets/Images"
import { Logo } from "Components/Organisms/Profile/ProfileHeader/styles"
import { Discord2, DiscordIcon, Facebook2, Instagram2, Telegram2, Twitter2 } from "Assets/Icons/Social"
import HeroPanel from "Components/Molecules/Profile/HeroPanel"
import StartGamePanel from "Components/Molecules/Profile/StartGamePanel/Default"
import { getWalletAccount } from "store/reducers/auth/MetamaskSlice"
import { useDispatch } from "react-redux"

function ProfileLayout() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [popup, setPopup] = useState<null | string>(null)

	const links = {
		discord: "https://discord.gg/DhxxNTTy5B",
		twitter: "https://twitter.com/QubixInfinity",
		instagram: "https://www.instagram.com/qubix.infinity/",
		telegram: "https://t.me/qubixinfinity",
	}

	useEffect(() => {
		dispatch(getWalletAccount())
	})

	const handleNavigate = (to: "discord" | "twitter" | "instagram" | "telegram") => {
		window.open(links[to])
	}

	return (
		<ProfileWrapper>
			<Popups popup={popup} changePopup={(popup: string | null) => setPopup(popup)} />
			<ProfileHeader changePopup={(popup: string | null) => setPopup(popup)} />
			<ProfileBody>
				{/* <SideMenu /> */}
					<Outlet />
			</ProfileBody>
			{/* <ProfileFooter>
				<Logo href="/">
					<MenuLogo />
				</Logo>
				<Links>
					<Link to="">About</Link>
					<Link to="">Marketplace</Link>
					<Link to="">Games</Link>
					<Link to="">News</Link>
					<Link to="">Terms of use</Link>
					<Link to="">Privacy Policy</Link>
				</Links>
				<Socials>
					<SocialsTitle>FOLLOW US</SocialsTitle>
					<SocialsList>
						<SocialItem onClick={() => handleNavigate('discord')}>
							<Discord2></Discord2>
						</SocialItem >
						<SocialItem  onClick={() => handleNavigate('telegram')}>
							<Telegram2></Telegram2>
						</SocialItem>
						<SocialItem onClick={() => handleNavigate('instagram')}>
							<Instagram2></Instagram2>
						</SocialItem>
						<SocialItem onClick={() => handleNavigate('twitter')}>
							<Twitter2></Twitter2>
						</SocialItem>
					</SocialsList>
				</Socials>
				<QubixAuthor>QUBIX © {new Date().getFullYear()}</QubixAuthor>
			</ProfileFooter> */}
		</ProfileWrapper>
	)
}

export default ProfileLayout
