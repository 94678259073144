import React from 'react';
import {Route, Routes} from 'react-router-dom';
import MatchmakingLayout from '../Components/Layouts/MatchmakingLayout';
import NotFound from '../Pages/Auth/NotFound/NotFound';
import Matchmaking from '../Pages/Games/Matchmaking';

const MatchmakingRoutes = () => {
    return (
        <Routes>
            <Route element={<MatchmakingLayout/>}>
                <Route index element={<Matchmaking />}/>
            </Route>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default MatchmakingRoutes;
