import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const MainLogo = styled(Flex)`
	justify-content: center;

	> img {
		height: 200px;

		@media screen and (min-width: 1281px) and (max-width: 1366px) {
			height: 200px;
		}

		@media screen and (min-width: 1367px) and (max-width: 1919px) {
			height: 250px;
		}

		@media screen and (min-width: 1920px) {
			height: 300px;
		}
	}
`

export const ResultText = styled.div`
	font-weight: 900;
	font-size: 96px;
	line-height: 100px;
`
