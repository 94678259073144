import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { getProgressByLocation } from "../Components/Atoms/Auth/ProgressBar/utils"

export const useProgress = () => {
	const { pathname } = useLocation()
	const [value, setValue] = useState(0)

	useEffect(() => {
		const progress = getProgressByLocation(pathname)
		setValue(progress)
	}, [pathname])

	return { value }
}
