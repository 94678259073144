import styled from "styled-components"
import { Flex, Grid } from "globalStyles"

export const Header = styled.span`
	font-family: "Red Hat Display", sans-serif;
	font-size: 20px;
	line-height: 25px;
	margin-bottom: 20px;
`

export const IconsTable = styled(Grid)`
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 15px;
	margin-bottom: 30px;
	max-height: 225px;
	overflow-y: scroll;
	padding-right: 4px;

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-track {
		background: #13224d;
	}

	::-webkit-scrollbar-thumb {
		margin-top: 100px;
		margin-bottom: 25px;
		background-color: #00ffff;
		border-radius: 1px;
	}
`

export const IconCard = styled(Flex)<{ active: boolean }>`
	width: 100px;
	height: 100px;
	align-items: center;
	justify-content: center;
	background: ${({ active }) => (active ? "linear-gradient(96.25deg, #DA2EAF 0%, #02B7B7 100%)" : "none")};
	border: 1px solid transparent;
	border-image-source: linear-gradient(96.25deg, #da2eaf 0%, #02b7b7 100%);
	border-image-slice: 1;

	> img {
		width: 100%;
	}

	:hover {
		cursor: pointer;
	}
`
