import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const Container = styled.div`
	position: relative;
`

export const InfoContainer = styled.div`
	position: relative;

	> svg {
		width: 534px;

		@media screen and (max-width: 1680px) {
			width: 425px;
		}
	}
`

export const NumberContainer = styled.div`
	position: absolute;
	width: 94px;
	top: 18px;
	right: 18px;

	> #info-block-number {
		position: relative;
		transform: rotate(360deg);
	}

	> div {
		position: absolute;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		font-family: "Red Hat Display", sans-serif;
		font-size: 36px;
		line-height: 44px;
	}

	@media screen and (max-width: 1680px) {
		width: 70px;
	}
`

export const Info = styled(Flex)`
	width: 80%;
	flex-direction: column;
	position: absolute;
	top: 65px;
	left: 65px;
	justify-content: center;
	align-items: flex-start;

	> p {
		text-transform: uppercase;
		font-family: "Red Hat Display", sans-serif;
		font-size: 36px;
		line-height: 44px;
	}

	> div {
		margin-top: 14px;
	}

	@media screen and (max-width: 1680px) {
		top: 40px;
		left: 40px;

		> p {
			font-size: 28px;
			line-height: 36px;
		}

		> div {
			margin-top: 10px;
			font-size: 14px;
			line-height: 22px;
		}
	}
`

export const Buttons = styled(Flex)`
	justify-content: flex-end;
	margin-right: 40px;
`

export const Button = styled.div<{ active: boolean }>`
	:nth-child(n + 2) {
		margin-left: 10px;
	}

	> svg path {
		opacity: ${({ active }) => (active ? 1 : 0.5)};
		transition: opacity 0.3s ease-in-out;

		:hover {
			cursor: pointer;
			opacity: 1;
		}
	}
`
