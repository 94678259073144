import "App.css"
import React, { useEffect } from "react"
import AppRouter from "./Routes"
import { BrowserRouter as Router } from "react-router-dom"
import "swiper/swiper-bundle.min.css"
import ledgerAPI from "services/LedgerService"
import { isAnonym } from "utils/checkUser"
import { useAppDispatch, useAppSelector } from "hooks/redux"
// import { Auth, useAuth } from "@arcana/auth-react"
// import { setArcanaAuthLoading, setUserInfo } from "store/reducers/auth/AcranaSlice"
import { authToNear, getAccountNameFromSS, nearSlice } from "store/reducers/auth/NearSlice"
import { getAllUserInfoTON, getUserAssetsTON, tonSlice } from "store/reducers/auth/TonSlice"
import TonError from "Components/Organisms/Auth/TonError"
import { useWalletSelector } from "utils/near/wallet"
import { ConvertYoctoNearToNear } from "utils/nearUtils"
import { ConvertNearToYoctoNear } from "utils/nearUtils"

let timeoutLoading: ReturnType<typeof setTimeout>

let selectedWallet = ""

function App() {
	const dispatch = useAppDispatch()
	const [sendUserInfoForRetentionUserInfo] = ledgerAPI.useSendRetentionUsersMutation()
	const { address } = useAppSelector(state => state.metamaskReducer.account)
	const { account } = useAppSelector(state => state.nearReducer)
	const { account: tonAccount, error: tonError } = useAppSelector(state => state.ton)
	const { modal, accounts, selector, accountId } = useWalletSelector()

	useEffect(() => {
		// отправка id пользователя для отслеживания возвращений пользователей
		let userId

		if (isAnonym()) {
			userId = localStorage.getItem("anonymUserId")
		} else if (address) {
			userId = address
		} else if (account?.accountId) {
			userId = account?.accountId
		} else {
			userId = localStorage.getItem("nftID")
		}

		if (!userId) return
		sendUserInfoForRetentionUserInfo({ date: new Date().toLocaleDateString(), userId })
	}, [])

	// auth near connect
	// подписание смарт контракта
	useEffect(() => {
		const selectedWalletId = selector?.store?.getState()?.selectedWalletId
		if (selectedWalletId) {
			selectedWallet = selectedWalletId
		}
	}, [selector?.store?.getState()?.selectedWalletId])
	useEffect(() => {
		if (selectedWallet) {
			const fn = async () => {
				const wallet = await selector.wallet(selectedWallet)
				const accounts = await wallet.signIn({ contractId: "dev-1669118952110-57858798417486", accounts: [] })
			}
			fn()
		}
	}, [selectedWallet])

	//auth ton
	useEffect(() => {
		function inIframe() {
			try {
				return window.self !== window.top
			} catch (e) {
				return true
			}
		}

		const authTon = async () => {
			const appWorkInIframe = inIframe()
			const tokenFromQuery = new URLSearchParams(window.location.search).get("token")
			const tokenFromSS = sessionStorage.getItem("ton_token")
	
			if (tokenFromQuery) {
				// токен есть в query
				dispatch(getAllUserInfoTON({ token: tokenFromQuery }))
	
				// TODO: logout near
				await dispatch(nearSlice.actions.resetAuthData())
				const wallet = await selector.wallet()
				await wallet.signOut()
				
			} else if (tokenFromSS && appWorkInIframe) {
				// токена нет в query, но он есть в sessionStorage и приложение запущено в iframe
				dispatch(getAllUserInfoTON({ token: tokenFromSS }))
				
				// TODO: logout near
				await dispatch(nearSlice.actions.resetAuthData())
				const wallet = await selector.wallet()
				await wallet.signOut()
			} else if (appWorkInIframe) {
				// токена нет в query и sessionStorage, но приложение запущено в iframe
				dispatch(tonSlice.actions.setTonError("App error. Not found environment."))
			} else {
				dispatch(tonSlice.actions.notFoundToken())
			}
		}
		authTon()
	}, [])

	//auth near
	useEffect(() => {
		const nearAccountNameFromSS = getAccountNameFromSS()
		if (nearAccountNameFromSS) {
			dispatch(authToNear({ accountName: nearAccountNameFromSS }))
		}
	}, [])

	// localStorage.clear()
	// sessionStorage.clear()

	//auth near dapps
	useEffect(() => {
		function inIframe() {
			try {
				return window.self !== window.top
			} catch (e) {
				return true
			}
		}

		(async () => {
			try {
				if (inIframe()) return
				const selectedWalletId = await selector?.store?.getState()?.selectedWalletId
				if (selectedWalletId) {
					const wallet = await selector.wallet()
					const accounts = await wallet.getAccounts()

					if (!accounts?.length) return

					if (selectedWalletId === "near-wallet") {
						dispatch(nearSlice.actions.setAuthData({account: accounts[0], walletType: "near-wallet"}))
					} else if (selectedWalletId === "nightly") {
						dispatch(nearSlice.actions.setAuthData({account: accounts[0], walletType: "nightly"}))
					}
					// await wallet.signIn({ contractId: "dev-1669118952110-57858798417486", accounts: [] });
				} else {
					dispatch(nearSlice.actions.resetAuthData())
				}
			} catch (error) {
				console.error(error)
			}
		})()
	}, [selector?.store?.getState()?.accounts])
	
	if (tonError) {
		return <TonError text={tonError} />
	}

	return (
		<Router>
			<AppRouter />
		</Router>
	)
}

export default App
