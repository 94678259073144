import styled from "styled-components"
import { Colors, Flex } from "../../../../globalStyles"

export const Container = styled(Flex)`
	width: 100%;
	grid-area: header;
	display: grid;
	/* grid-auto-flow: column; */
	grid-template-columns: 20vw 1fr 30%;
	place-items: center;
	backdrop-filter: blur(10px);
	background-color: rgba(0, 0, 0, 0.2);
`

export const Logo = styled.a`
	/* margin-top: 20px; */
	/* margin-left: 30px; */
	margin-left: 12vw;
	justify-self: start;
	&:hover {
		cursor: pointer;
	}
	& svg {
		width: 30px;
		top: 10px;
		/* width: min(50px, 3vw); */
	}
	& svg:first-child {
		width: 120px;
		/* width: min(170px, 10vw); */
	}
`

export const Navigate = styled.nav`
	justify-self: start;
	display: grid;
	grid-auto-flow: column;
	column-gap: 0px;
	width: 100%;
	justify-content: space-around;

	margin-right: 50px;

	> div:not(:last-of-type) {
		margin-right: 1px;
		@media (max-width: 1300px) {
			margin-right: 1px;
		}
	}
`

export const NavItem = styled(Flex)<{ isActive?: boolean; disable?: boolean }>`
	font-family: "Red Hat Display", sans-serif;
	font-size: min(18px, 1.3vw);
	font-weight: 900;
	text-align: center;
	color: ${({ isActive }) => (isActive ? Colors.blue : "white")};

	opacity: ${({ disable }) => (disable ? 0.3 : "unset")};
	cursor: ${({ disable }) => (disable ? "not-allowed" : "pointer")};

	transition: all 0.3s ease;
	border-bottom: 3px solid transparent;

	${({ disable }) =>
		!disable &&
		`
	&:hover {
		border-bottom: 3px solid ${Colors.blue};
	}
	`}
`

export const UserPanel = styled(Flex)`
	align-items: center;
	justify-self: end;
	margin-right: 50px;
	& p {
		font-size: 16px;
		font-family: "Red Hat Display", sans-serif;
	}

	svg:last-of-type {
		width: 30px;
		height: 30px;
		:hover {
			cursor: pointer;
		}
	}
`

export const PlayersCount  = styled.div`
	opacity: 0.3;
	display: grid;
	grid-auto-flow: column;
	column-gap: 5px;
	margin-right: 30px;

	div {
		font-size: 14px;
		font-weight: 600;
	}
`
export const PlayersCountTitle  = styled.div`
	text-align: center;
	
`
export const PlayersCountValue  = styled.div`
	text-align: center;

`

export const Avatar = styled(Flex)`
	align-items: center;
	justify-content: center;
	margin-left: 30px;
	position: relative;
	img {
		border-radius: 5px;
	}
	&:after {
		content: "";

		position: absolute;
		bottom: 5px;
		right: 5px;
		
		display: block;
		width: 10px;
		height: 10px;	

		border-radius: 50%;
		background-color: #68FF5B;

		z-index: 2;
	}
`

// export const WalletStatusWrap = styled.div`
// 	display: grid;
// 	grid-template-columns: auto 1fr 1fr;
// 	place-items: center;
// 	gap: 10px;
// 	height: 40px;

// 	padding: 6px 16px;
// 	border: 1px solid ${Colors.blue};
// 	border-radius: 6px;

// 	& img {
// 		width: 25px;
// 		height: 25px;
// 	}
// 	& p:last-child {
// 		font-weight: 700;
// 	}
// `

export const Wallet = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	& svg {
		width: 10px;
	}
	user-select: none;
`

export const WalletAddress = styled.div`
	font-size: min(1.1vw, 18px);
	font-weight: 500;
	margin-right: 10px;
`

export const ConnectWalletBtn = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	cursor: pointer;

	gap: 20px;

	border: 1px solid rgba(255, 255, 255, 0.5);
	padding: 10px 15px;
	border-radius: 7px;
`

export const ConnectWalletText = styled.div`
	user-select: none;
	font-size: min(1.1vw, 18px);
	font-weight: 900;
	/* letter-spacing: 1/px; */
	text-transform: uppercase;
`

export const LoadingAccount = styled.div`
	opacity: 0.3;
	color: white;
`

export const SingOut = styled.div`
	text-transform: uppercase;
	font-size: 14px;
	border-radius: 5px;
	border: white 1px solid;
	opacity: 0.5;
	padding: 5px 10px;
`

export const AnonymId = styled.div`
	opacity: 0.3;
	font-size: 14px;
	font-weight: 600;
`

// --------------------------------------------------------
// DropDown

export const DropdownOverlay = styled.div`
	display: grid;
	grid-auto-flow: row;
	padding: 10px 0;
	gap: 10px;

	width: 100%;
	text-align: center;
	color: black;
	& > * {
		background-color: rgb(255, 255, 255);
		color: black;
		border-radius: 5px;
	}
`

export const DropdownOverlayAnonym = styled(DropdownOverlay)`
	& > * {
		background-color: gray;
		padding: 10px;
	}
`

export const Balance = styled.div`
	& * {
		color: black;
	}
	display: grid;
	grid-auto-flow: column;
	/* & p {
		color: black;
	} */
`

export const BalanceValue = styled.span`
	font-weight: 700;
`

export const LogOutButton = styled.div`
	/* color: black; */
	cursor: pointer;
`
// --------------------------------------------------------
