import React, { useEffect } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { generateAnonymUserId, isAnonym, isArcanaUser, isNearUser } from "utils/checkUser"
// import useChainChange from "../hooks/useChainChange"

function PrivateRoute({ children }: { children: JSX.Element }) {
	const navigate = useNavigate()
	const access = sessionStorage.getItem("access")
	const refresh = sessionStorage.getItem("refresh")

	useEffect(() => {
		if (isAnonym() || isArcanaUser() || isNearUser()) return
		if (typeof window.ethereum === "undefined") {
			// return navigate("/auth/metamask/not_installed")
		}
	})

	// useChainChange()

	if (isNearUser() || isArcanaUser()) {
		sessionStorage.setItem("isAnonymUser", "false")
		return children
	}

	console.log({sessionStorage: !sessionStorage.getItem("isAnonymUser"), access, refresh})
	if (!sessionStorage.getItem("isAnonymUser") && (access || refresh)) {
		sessionStorage.setItem("isAnonymUser", "false")
	} else {
		sessionStorage.setItem("isAnonymUser", "true")
		// localStorage.removeItem('access')
		// localStorage.removeItem('refresh')
		if (!localStorage.getItem("anonymUserId")) {
			localStorage.setItem("anonymUserId", generateAnonymUserId())
		}
	}
	
	// console.log({ "!isAnonym()": !isAnonym() })
	// if (!isAnonym()) {
	// 	console.log({ "!access || !refresh": !access || !refresh })
	// 	if (!access || !refresh) {
	// 		console.log("return auth")
	// 		return <Navigate to="/auth" />
	// 	}
	// 	console.log('set false 2')
	// 	sessionStorage.setItem("isAnonymUser", "false")
	// } else {
	// 	console.log('set true 2')
	// 	sessionStorage.setItem("isAnonymUser", "true")

	// 	if (!localStorage.getItem("anonymUserId")) {
	// 		localStorage.setItem("anonymUserId", generateAnonymUserId())
	// 	}
	// }

	return children
}

export default PrivateRoute
