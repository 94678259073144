import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICoin } from "../../models/ICoin"

interface IState {
	currentCoin: ICoin | null
}

const initialState: IState = {
	currentCoin: null,
}

export const coinSlice = createSlice({
	name: "coin",
	initialState,
	reducers: {
		setCurrentCoin(state, action: PayloadAction<ICoin | null>) {
			state.currentCoin = action.payload
		},
	},
})

export const { setCurrentCoin } = coinSlice.actions

export default coinSlice.reducer
