export { dateFormat } from "./dateFormat"

export function average(numbers: number[]) {
	let sum = 0
	const count = numbers.length

	for (let i = 0; i < count; i++) {
		sum += numbers[i]
	}

	const average = sum / count
	return Math.floor(average)
}
