import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { MetaData } from "./WNFTSlice"
import { Server } from "Components/Molecules/Profile/StartGamePanel/Default"
import { notification } from "antd"

export type MapId = number
export type Map = [MapId, number[]]
type GameMode = string

interface IState {
	preferences: {
		map_id: string
		game_mode: GameMode | null
		nft: MetaData | null
		minPlayersForStart: number
		nickname: string | null
	}
	isStartMatchmaking: boolean
	isStartReconnecting: boolean
	pings: {
		bestServerRegion: { server: Server; avg_ping?: number; userSelected?: boolean } | null
		regionPings: { [region: string]: { server: Server; avg_ping?: number } }
	}
	urlToBestMatchServer: string
}

const initialState: IState = {
	preferences: {
		map_id: "",
		game_mode: "deathmatch",
		nft: null,
		minPlayersForStart: 2,
		nickname: null,
	},
	isStartMatchmaking: false,
	isStartReconnecting: false,
	pings: {
		bestServerRegion: null,
		regionPings: {},
	},
	urlToBestMatchServer: "",
}

export const gameSlice = createSlice({
	name: "game",
	initialState,
	reducers: {
		forceSelectMatchServer(state, action: PayloadAction<{ region: string }>) {
			const servers = state.pings.regionPings

			if (Object.keys(servers).find(serverRegion => serverRegion === action.payload.region)) {
				const selectedServer = state.pings.regionPings[action.payload.region]
				if (selectedServer) {
					// если сервер не удовлетворяет требованиям
					if (!selectedServer.avg_ping) {
						notification.error({ message: "The selected server has too high a ping" })
						return
					}
					state.pings.bestServerRegion = { ...selectedServer, userSelected: true }
					sessionStorage.setItem("region", selectedServer.server.region)

					// определение ссылки на сервер
					let url_to_match = ""
					if (selectedServer?.server?.server_port) {
						url_to_match = `http://${selectedServer.server.server_ip}:${selectedServer.server.server_port}`
					} else {
						url_to_match = `https://${selectedServer.server.server_url}`
					}

					if (url_to_match) {
						state.urlToBestMatchServer = url_to_match
						sessionStorage.setItem("url_to_match", url_to_match)
					} else {
						notification.error({ message: "Not found url to match" })
						return
					}
					notification.info({ message: `Selected server ${action.payload.region}`, duration: 1 })
				}
			}
		},
		setPingsInfo(
			state,
			action: PayloadAction<{
				regionPings: { server: Server; avg_ping?: number }[]
			}>
		) {
			const regionPings = action.payload.regionPings
			if (!Array.isArray(regionPings) || !regionPings?.length) {
				state.pings = initialState.pings
				return console.error("Нет серверов")
			} else {
				// проверка если с ММ не пришел сервер который присутствовал ранее
				if (state.pings.bestServerRegion && !regionPings.find(rp => state.pings.bestServerRegion?.server?.region === rp?.server?.region)) return

				// запись полученных данных в state
				regionPings?.map(rp => {
					const region = rp?.server?.region
					const avg_ping = rp?.avg_ping
					if (region) {
						if (state.pings.regionPings) {
							if (avg_ping) {
								state.pings.regionPings[region] = { server: rp.server, avg_ping }
							} else {
								// если выбранный пользователем сервер перестал удовлетворять требованиям
								// лучший сервер сбрасывается и далее в функции присваивается новый
								if (state.pings.bestServerRegion?.server.region === region) {
									state.pings.bestServerRegion = null
								}
								state.pings.regionPings[region] = { server: rp.server }
							}
						}
					} else {
						return console.error("Нет информации по серверу")
					}
				})

				// если сервер выбран пользователем в ручную
				if (state.pings.bestServerRegion?.userSelected) {
					return
				}

				// поиск сервера с наименьшим пингом
				const best_server = regionPings
					?.filter(item => item.avg_ping)
					?.reduce(
						(acc, item) => (!acc?.avg_ping || (item?.avg_ping as number) < acc?.avg_ping ? item : acc),
						{} as { server: Server; avg_ping: number }
					)
				if (best_server?.server?.region) {
					state.pings.bestServerRegion = best_server
					sessionStorage.setItem("region", best_server.server.region)

					// определение ссылки на сервер который оказалсся лучшим
					let url_to_match = ""
					if (best_server?.server?.server_port) {
						url_to_match = `http://${best_server.server.server_ip}:${best_server.server.server_port}`
					} else {
						url_to_match = `https://${best_server.server.server_url}`
					}

					if (url_to_match) {
						state.urlToBestMatchServer = url_to_match
						sessionStorage.setItem("url_to_match", url_to_match)
					} else {
						notification.error({ message: "Not found url to match" })
						return
					}
				} else {
					state.pings.bestServerRegion = null
				}
			}
		},
		addMap(state, action: PayloadAction<{ map_id: string; map_index: number }>) {
			sessionStorage.setItem("map_id", action.payload.map_id)
			sessionStorage.setItem("map_index", String(action.payload.map_index))
			state.preferences.map_id = action.payload.map_id
		},
		removeAllMap(state) {
			state.preferences.map_id = ""
		},
		// removeMap(state, action: PayloadAction<MapId>) {
		//     state.preferences.map_id = state.preferences.map_id.filter(map => map[0] !== action.payload)
		// },
		addGameMode(state, action: PayloadAction<GameMode>) {
			state.preferences.game_mode = action.payload
		},
		addNickName(state, action: PayloadAction<string>) {
			localStorage.setItem("nickname", action.payload)
			state.preferences.nickname = action.payload
		},
		addNFT(state, action: PayloadAction<MetaData>) {
			console.log("setNFT")
			console.log({ player_id: action.payload.metadata.player_id })
			console.log(state.preferences.nft)
			state.preferences.nft = action.payload
			console.log(state.preferences.nft)
		},
		removeNFT(state) {
			state.preferences.nft = null
			console.log(state.preferences.nft)
		},
		removeGameMode(state) {
			state.preferences.game_mode = null
		},
		resetState(state) {
			state.preferences = {
				map_id: "",
				game_mode: null,
				nft: null,
				minPlayersForStart: 2,
				nickname: null,
			}
		},
		startMatchmaking(state) {
			state.isStartMatchmaking = true
		},
		startReconnecting(state) {
			state.isStartReconnecting = true
		},
		stopMatchmaking(state) {
			state.isStartMatchmaking = false
		},
		setMinPlayersForStart(state, action: PayloadAction<number>) {
			if (action.payload < 2 || action.payload > 6) {
				console.error("Number out of range")
				return
			} else {
				state.preferences.minPlayersForStart = action.payload
			}
		},
	},
	extraReducers: {},
})

export const {
	addNFT,
	removeNFT,
	addMap,
	removeAllMap: removeMap,
	addGameMode,
	removeGameMode,
	removeAllMap,
	resetState,
	startMatchmaking,
	stopMatchmaking,
	setMinPlayersForStart,
} = gameSlice.actions

export default gameSlice.reducer
