import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const Container = styled.div`
	position: relative;
	padding: 10px;
	height: 100%;
	background: #c4c4c4;

	> #pentagon {
		position: relative;
		border-width: 5px 5px 0 5px;
		height: 85%;

		> img {
			width: 100%;
			height: 100%;
		}

		> div {
			position: absolute;
			bottom: -28px;
			right: -1px;
			width: 0;
			height: 0;
			border-bottom: 85px solid #c4c4c4;
			border-left: 185px solid transparent;
		}
	}

	filter: drop-shadow(24.2007px 24.2007px 121.003px rgba(226, 226, 226, 0.64));
`

export const Info = styled(Flex)`
	position: relative;

	> div {
		font-family: "Red Hat Display", sans-serif;
		font-size: 40px;
		line-height: 48px;
		text-transform: uppercase;
		color: #3f3f3f;

		@media screen and (min-width: 1440px) and (max-width: 1680px) {
			font-size: 32px;
			line-height: 40px;
		}

		@media screen and (max-width: 1439px) {
			font-size: 26px;
			line-height: 34px;
		}
	}

	> svg {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 70px;
		height: 76px;

		@media screen and (min-width: 1440px) and (max-width: 1919px) {
			width: 60px;
			bottom: 5px;
		}

		@media screen and (max-width: 1439px) {
			width: 55px;
			bottom: 0;
		}
	}
`
