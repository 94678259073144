import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Container} from './styles';
import {CloseButtonImg} from '../../../../Assets/Icons/Matchmaking';
import ledgerAPI from '../../../../services/LedgerService';
import {useAppDispatch} from '../../../../hooks/redux';

function CloseButton() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleClick = () => {
        dispatch(ledgerAPI.util.resetApiState())
        navigate('/games')
    }

    return (
        <Container onClick={handleClick}>
            <CloseButtonImg />
        </Container>
    );
}

export default CloseButton;
