import React from 'react';
import {Container, ModesContainer} from './styles';
import SelectedMode from '../../../Atoms/Matchmaking/SelectedMode';
import SelectedMapsTitle from '../../../Atoms/Matchmaking/SelectedMapsTitle';
import ModeInfo from '../../../Molecules/Matchmaking/ModeInfo';
import OrangeButton from '../../../Atoms/Matchmaking/OrangeButton';

const arr = ['team deathmatch', 'deathmatch', 'quest']

function MatchmakingFooter() {
    return (
        <Container>
            <ModesContainer>
                <SelectedMapsTitle text="game modes" />
                {arr.map(map => <SelectedMode text={map}/>)}
            </ModesContainer>
            <ModeInfo />
            <OrangeButton text="play" />
        </Container>
    );
}

export default MatchmakingFooter;
