import { Button, Modal } from "antd"
import { FC } from "react"
import { CloseButton, ConnectAnywayButton, HighPingModalText } from "./style"
import "./style.css"

interface HighPingProps {
	isModalOpen: boolean
	setIsModalOpen: (mode: boolean) => void
	connectAction: any
}

const HighPing: FC<HighPingProps> = ({ isModalOpen, setIsModalOpen, connectAction }) => {
	const handleOnClose = () => {
		setIsModalOpen(false)
	}

	return (
		<Modal
			title="Oops, bad connection!"
			open={isModalOpen}
			// onOk={reconnectAction}
			onCancel={handleOnClose}
			className="bugreport"
			centered={true}
			style={{ borderRadius: "10px" }}
			footer={[
				<Button onClick={connectAction} type="primary" style={{ backgroundColor: "purple", borderColor: "purple" }}>
					<ConnectAnywayButton>Connect anyway</ConnectAnywayButton>
				</Button>,
				<Button onClick={handleOnClose} type="ghost">
					<CloseButton>Close</CloseButton>
				</Button>,
			]}
		>
			<HighPingModalText>
				{
					"Sorry, we found that you have problems with the Internet connection at the moment and this may affect your connection speed while you will play :("
				}
			</HighPingModalText>
		</Modal>
	)
}

export default HighPing
