export const getProgressByLocation = (location: string) => {
	switch (location) {
		default:
			return 0
		case "/auth":
			return 25
		case "/auth/":
			return 25
		case "/auth/metamask/":
			return 50
		case "/auth/arcana/":
			return 50
		case "/auth/near":
			return 50
		case "/auth/near/":
			return 50
		case "/auth/near/successfully":
		case "/auth/metamask/verification":
		case "/auth/metamask/verification/failure":
			return 75
		case "/auth/new_user":
			return 90
		case "/auth/new_user/":
			return 90
	}
}
