import { setupWalletSelector } from "@near-wallet-selector/core"
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet"
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import BN from "bn.js"
import * as nearAPI from "near-api-js1"
import { AppStore, RootState } from "store/store"
import { ConvertYoctoNearToNear } from "utils/nearUtils"
import { setupModal } from "@near-wallet-selector/modal-ui"
import { notification } from "antd"

function timeout(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms))
}

const privederPogoda = "https://near-testnet.api.pagoda.co/rpc/v1/"
const API_KEY = "642cf4ec-7b3e-4e17-8cb8-f36d47e0d982"

const connectionConfig = {
	networkId: "testnet",
	keyStore: null as any,
	nodeUrl: "https://rpc.testnet.near.org",
	walletUrl: "https://wallet.meteorwallet.org",
	// helperUrl: "https://helper.testnet.near.org",
	// explorerUrl: "https://explorer.testnet.near.org",
	// headers: { 'x-api-key': API_KEY }
}

export const setAccountNameInSS = (accountName: string) => sessionStorage.setItem("near_account_name", accountName)

export const getAccountNameFromSS = () => sessionStorage.getItem("near_account_name")

export const setTransaction = createAsyncThunk("setTransaction", async ({ hash }: { hash: string }, { rejectWithValue, dispatch }) => {
	try {
		const transactionInfo1 = (await dispatch(getStatusTransactionByHash({ hash }))) as any
		console.log({ transactionInfo1 })
		return { transactionInfo: transactionInfo1.payload, hash }
	} catch (error) {
		console.log(error)
		return rejectWithValue(error)
	}
})

export const getStatusTransactionByHash = createAsyncThunk(
	"getStatusTransactionByHash",
	async ({ hash }: { hash: string }, { rejectWithValue }) => {
		try {
			const { providers } = nearAPI
			const accountNameFromSS = getAccountNameFromSS()
			const provider = new providers.JsonRpcProvider(`https://rpc.testnet.near.org` as any)
			const response = await provider.txStatus(hash, accountNameFromSS as string)

			return response
		} catch (error) {
			console.log(error)
			return rejectWithValue("")
		}
	}
)

export const sendMoneyToCommonWalletNC = createAsyncThunk("sendMoneyToCommonWalletNC", async (_, { getState }) => {
	const { keyStores, connect, KeyPair } = nearAPI
	const state = getState() as RootState
	const { account: senderAccount, contract, bet: amount } = state.nearReducer

	if (!amount) {
		console.log("not bet")
		return
	}
	if (!senderAccount) {
		console.log("not senderAccount")
		return
	}

	try {
		const keyStore = new keyStores.BrowserLocalStorageKeyStore()

		// const connectionConfig = {
		// 	networkId: "testnet",
		// 	keyStore: keyStore,
		// 	nodeUrl: "https://rpc.testnet.near.org",
		// 	walletUrl: "https://wallet.testnet.near.org",
		// 	helperUrl: "https://helper.testnet.near.org",
		// 	explorerUrl: "https://explorer.testnet.near.org",
		// }
		connectionConfig.keyStore = keyStore
		const nearConnection = await connect(connectionConfig)
		const account = await nearConnection.account(senderAccount.accountId)
		// const account = await nearConnection.account("ilya2.testnet")
		const formatedAmount = nearAPI.utils.format.parseNearAmount(amount)
		const transaction = await (contract as any).send({ account }, 300000000000000, formatedAmount)
		// const transaction = await account.sendMoney(receiver, amount as any)
	} catch (error) {
		console.error(error)
	}
})

export const sendMoneyToCommonWallet = createAsyncThunk("sendMoneyToCommonWallet", async (_, { getState }) => {
	const { keyStores, connect, KeyPair } = nearAPI
	const state = getState() as RootState
	const { account: senderAccount, contract, bet: amount } = state.nearReducer

	if (!amount) {
		console.log("not bet")
		return
	}
	if (!senderAccount) {
		console.log("not senderAccount")
		return
	}

	try {
		const keyStore = new keyStores.BrowserLocalStorageKeyStore()

		// const connectionConfig = {
		// 	networkId: "testnet",
		// 	keyStore: keyStore,
		// 	nodeUrl: "https://rpc.testnet.near.org",
		// 	walletUrl: "https://wallet.testnet.near.org",
		// 	helperUrl: "https://helper.testnet.near.org",
		// 	explorerUrl: "https://explorer.testnet.near.org",
		// }
		connectionConfig.keyStore = keyStore
		const nearConnection = await connect(connectionConfig)
		const account = await nearConnection.account(senderAccount.accountId)
		// const account = await nearConnection.account("ilya2.testnet")
		const formatedAmount = nearAPI.utils.format.parseNearAmount(amount)
		const transaction = await (contract as any).send({ account }, 300000000000000, formatedAmount)
		// const transaction = await account.sendMoney(receiver, amount as any)
	} catch (error) {
		console.error(error)
	}
})

export const checkAccountName = async (accountName: string): Promise<{ valid: boolean; error: string }> => {
	const { connect, keyStores, WalletConnection } = nearAPI

	const myKeyStore = new keyStores.BrowserLocalStorageKeyStore()

	const connectionConfig = {
		networkId: "testnet",
		keyStore: myKeyStore,
		nodeUrl: "https://rpc.testnet.near.org",
		walletUrl: "https://wallet.testnet.near.org",
		helperUrl: "https://helper.testnet.near.org",
		explorerUrl: "https://explorer.testnet.near.org",
	}

	connectionConfig.keyStore = myKeyStore
	// connect to NEAR
	const nearConnection = await connect(connectionConfig)

	// ------------------------------------------------------------
	// разная информация по аккаунту
	const account = await nearConnection.account(accountName)

	try {
		const accountState = await account.state()
		const balance = await account.getAccountBalance()
		return { valid: true, error: "" }
	} catch (error) {
		console.error(error)
		return { valid: false, error: JSON.stringify(error) || "" }
	}
}

export const authToNear = createAsyncThunk(
	"authToNear",
	async ({ accountName, redirectPath }: { accountName?: string; redirectPath?: string }, { rejectWithValue, getState }) => {
		try {
			const accountName = "qubix_beta.testnet"
			const state = getState() as RootState

			if (state.nearReducer.account || window.location.href.includes("/near/failure")) {
				return rejectWithValue("exist")
			}
			console.log("start authorization NEAR")

			const { connect, keyStores, WalletConnection } = nearAPI

			const myKeyStore = new keyStores.BrowserLocalStorageKeyStore()
			const connectionConfig = {
				networkId: "testnet",
				keyStore: myKeyStore,
				nodeUrl: "https://rpc.testnet.near.org",
				walletUrl: "https://wallet.testnet.near.org",
				helperUrl: "https://helper.testnet.near.org",
				explorerUrl: "https://explorer.testnet.near.org",
			}
			connectionConfig.keyStore = myKeyStore

			// connect to NEAR
			// const nearConnection = await connect(connectionConfig)

			// ------------------------------------------------------------
			// разная информация по аккаунту
			// const account = await nearConnection.account(accountName)

			// const accessKeys = await account.getAccessKeys()

			// const accountState = await account.state()
			// // можно посмотреть подключенные проекты пользователя в near
			// const accountDetails = await account.getAccountDetails()

			// const balance = await account.getAccountBalance()
			// console.log({account, accessKeys, accountState, accountDetails, balance})
			// console.log(JSON.stringify({account, accessKeys, accountState, accountDetails, balance}))
			// ------------------------------------------------------------

			// create wallet connection
			// данные после авторизации

			// const walletConnection = new WalletConnection(nearConnection, null)

			// const walletAccountId = walletConnection.getAccountId()

			// setAccountNameInSS(walletAccountId)

			// const walletAccountObj = walletConnection.account()

			// console.log({ walletConnection, walletAccountId, walletAccountObj })

			// const protocol = process.env.NODE_ENV === "production" ? "https://" : "http://"

			// const timeout_ms = 1000
			// await timeout(timeout_ms)

			// if (!walletConnection.isSignedIn()) {
			// 	const signObj: { [key: string]: any } = {
			// 		failureUrl: protocol + window.location.host + "/", // если нажать "отмена"
			// 		successUrl: redirectPath || protocol + window.location.host + "/near/successfully",
			// 	}
			// 	// if (!accountDetails.authorizedApps.find(item => item.contractId === "dev-1669118952110-57858798417486")) {
			// 	// 	signObj.contractId = "dev-1669118952110-57858798417486"
			// 	// }
			// 	walletConnection.requestSignIn(signObj)
			// 	// return rejectWithValue("no auth Near")
			// } else if (redirectPath) window.location.href = redirectPath

			// // ------------------------------------------------------------
			// const contract = await new nearAPI.Contract(walletConnection.account(), "dev-1669118952110-57858798417486", {
			// 	// View methods are read only. They don't modify the state, but usually return some value.
			// 	viewMethods: ["get_greeting"],
			// 	// Change methods can modify the state. But you don't receive the returned value when called.
			// 	changeMethods: ["set_greeting", "send"],
			// })
			// // const contract = null
			// // ------------------------------------------------------------
			// // if (!isValidNearAuthData({ account, balance: ConvertYoctoNearToNear(balance.total), walletConnection, contract })) {
			// if (!isValidNearAuthData({ account: walletAccountObj, walletConnection, contract })) {
			// 	return rejectWithValue("invalid near data")
			// }
			// return { account: walletAccountObj, walletConnection, contract }
			return { account: {}, walletConnection: {}, contract: {} } as any
		} catch (e: any) {
			console.error({ authError: JSON.stringify(e) })
			return rejectWithValue(e)
		}
	}
)

const isValidNearAuthData = ({
	account,
	walletConnection,
	contract,
}: {
	account: nearAPI.Account
	walletConnection: nearAPI.WalletConnection
	contract: nearAPI.Contract | null
}) => {
	if (!account?.accountId) return false
	// if (!contract?.contractId) return false
	return true
}

interface IState {
	isLoading: boolean
	error: string | null
	account: nearAPI.Account | null
	walletConnection: nearAPI.WalletConnection | null
	contract: nearAPI.Contract | null
	bet: string | null
	transaction: nearAPI.providers.FinalExecutionOutcome | null
	rowHash: string | null
	walletType: "near-wallet" | "nightly" | null
}

const initialState: IState = {
	isLoading: false,
	error: null,
	account: null,
	walletConnection: null,
	contract: null,
	bet: "5",
	transaction: null,
	rowHash: null,
	walletType: null,
}

export const nearSlice = createSlice({
	name: "wallet",
	initialState,
	reducers: {
		setAccount(state, action: PayloadAction<nearAPI.Account>) {
			state.account = action.payload
		},
		setWallet(state, action: PayloadAction<nearAPI.WalletConnection>) {
			state.walletConnection = action.payload
		},
		setBet(state, action: PayloadAction<string>) {
			state.bet = action.payload
		},
		setAuthData(state, action: PayloadAction<{ account: any; walletType: "near-wallet" | "nightly" }>) {
			const accountId = action.payload?.account?.accountId
			if (accountId) {
				state.account = action.payload?.account
				state.walletType = action.payload?.walletType
				setAccountNameInSS(accountId)
			} else {
				notification.info({message: "No user data"})
			}
		},
		resetAuthData() {
			const clear = (storage: any) => {
				Object.keys(storage)
				.filter(function(k) { return /near.*/.test(k); })
				.forEach(function(k) {
					console.log({k})
					storage.removeItem(k);
				});
			}
			// clear(sessionStorage)
			clear(localStorage)
			return initialState
		},
	},
	extraReducers: {
		// [authToNear.pending.type]: (state, action) => {
		// 	state.isLoading = true
		// },
		// [authToNear.fulfilled.type]: (
		// 	state,
		// 	action: PayloadAction<{
		// 		account: nearAPI.Account
		// 		walletConnection: nearAPI.WalletConnection
		// 		contract: nearAPI.Contract
		// 	}>
		// ) => {
		// 	state.isLoading = false
		// 	console.log({ payload: action?.payload })
		// 	console.log(action.payload.account.accountId)
		// 	state.account = action.payload.account
		// 	state.walletConnection = action.payload.walletConnection
		// 	state.contract = action.payload.contract
		// },
		// [authToNear.rejected.type]: (state, action) => {
		// 	state.isLoading = false
		// 	console.log(JSON.stringify({ action }))
		// 	console.log({ action })
		// },
		[setTransaction.fulfilled.type]: (state, action: PayloadAction<{ transactionInfo: any; hash: string }>) => {
			console.log("setTransaction.fulfilled.type")
			state.rowHash = action.payload.hash
			state.transaction = action.payload.transactionInfo
			try {
				console.log(action.payload.transactionInfo.receipts_outcome[0])
				console.log(ConvertYoctoNearToNear(action.payload.transactionInfo.receipts_outcome[0].outcome.logs[2].split(" ")[1]))
				state.bet = ConvertYoctoNearToNear(action.payload.transactionInfo.receipts_outcome[0].outcome.logs[2].split(" ")[1])
			} catch (error) {
				console.log(error)
			}
		},
	},
})

export default nearSlice.reducer
