import styled from "styled-components"

export const Wrapper = styled.div`
	width: 100%;
	position: relative;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	/* height: max-content; */
	backdrop-filter: blur(5px);
	grid-area: hero_panel;
	display: grid;
	justify-items: center;
	/* opacity: 0;
	transition: opacity .3s ease; */
	/* display: grid; */
	/* grid-auto-flow: row;
	align-items: center;
	gap: 15px; */
	/* grid-area: hero; */
	/* padding: 15px 70px 20px; */
	user-select: none;
`

export const InfoIconWrap = styled.div`
	position: absolute;
	top: 10px;
	right: 20px;
	opacity: 0.5;
`

export const HeroImage = styled.div`
	display: grid;
	place-items: center;
	/* height: 240px; */
	& img {
		width: 60%;
		margin-top: -20px;
		/* margin: -30px 0; */
		@media (min-width: 1800px) {
			width: 60%;
		}
	}
	& svg {
		margin-top: 55px;
		width: 60%;
	}
`

export const InfoIcon = styled.div``

export const HeroName = styled.div`
	font-weight: 900;
	font-size: 26px;
	text-transform: uppercase;
	letter-spacing: 3px;
	text-align: center;
`

export const Abilities = styled.div`
	width: 80%;
	margin: 5px auto;
	@media (min-width: 1800px) {
		margin: 10px auto;
	}
`

export const AbilitiesTitle = styled.div`
	font-weight: 900;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 2px;
`

export const AbilitiesItems = styled.div`
	display: grid;
	grid-auto-flow: column;
	text-align: center;
	column-gap: 10px;
	margin-top: 8px;
	@media (max-width: 1800px) {
		column-gap: 5px;
	}
`

export const AbilitiesItem = styled.div`
	height: 75px;
	width: 75px;
	display: grid;
	place-items: center;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 5px;

	@media (max-width: 1800px) {
		height: 60px;
		width: 60px;
	}
`

export const Parameters = styled.div`
	width: 80%;
	margin: 5px auto;
	@media (min-width: 1800px) {
		margin: 10px auto;
	}
`

export const ParametersTitle = styled.div`
	font-weight: 900;
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 2px;
`

export const ParametersItems = styled.div`
	display: grid;
	grid-auto-flow: column;
	/* margin: 10px 0; */
	padding: 10px 0px;
	background-color: black;
	border-radius: 5px;
`

export const ParametersItem = styled.div`
	/* display: grid;
    grid-auto-flow: row;
    gap: 20px; */
`

export const ParametersValue = styled.div`
	font-weight: 900;
	font-size: 18px;
	text-align: center;
	@media (min-width: 1800px) {
		font-size: 25px;
	}
`

export const ParametersDescription = styled.div`
	font-weight: 400;
	font-size: 12px;
	text-align: center;
	@media (min-width: 1800px) {
		font-size: 12px;
	}
`

export const PopoverText = styled.div`
	color: black;
	font-size: 13px;
	max-width: 500px;
	text-align: center;

	@media (min-width: 1800px) {
		max-width: 150px;
		font-size: 15px;
	}
`
