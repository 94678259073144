import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IState {
	currentAsset: string
}

const initialState: IState = {
	currentAsset: "",
}

export const assetSlice = createSlice({
	name: "asset",
	initialState,
	reducers: {
		setCurrentAsset(state, action: PayloadAction<string>) {
			state.currentAsset = action.payload
		},
	},
})

export const { setCurrentAsset } = assetSlice.actions

export default assetSlice.reducer
