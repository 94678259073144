import styled from "styled-components"
import { Typography } from "antd"

const { Title } = Typography

interface Props {
	fz?: number
	fw?: number
	uppercase?: boolean
}

const StyledTitle = styled(Title)<Props>`
	&.ant-typography {
		margin-bottom: 0;
		font-family: "Red Hat Display";
		text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
		font-style: normal;
		font-weight: ${({ fw = 600 }) => fw};
		font-size: ${({ fz = 35 }) => fz}px;
		line-height: 1em;
		color: #ffffff;
		text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	}
`

export default StyledTitle
