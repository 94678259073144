import React from 'react';
import {MatchmakingWrapper} from './styles';
import {Outlet} from 'react-router-dom';

function MatchmakingLayout() {
    return (
        <MatchmakingWrapper>
            <Outlet/>
        </MatchmakingWrapper>
    );
}

export default MatchmakingLayout;
