import React, {ChangeEvent} from 'react';
import {Container, Header, Input} from './styles';

interface IProps {
    value: string
    onInput: (event: ChangeEvent<HTMLInputElement>) => void
}

const EnterNickname = ({ value, onInput }: IProps) => {
    return (
        <Container>
            <Header>Enter your nickname</Header>
            <Input
                placeholder="Your name"
                autoFocus={true}
                value={value}
                onChange={onInput}
                maxLength={20}
            />
        </Container>
    );
};

export default EnterNickname;
