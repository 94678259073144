import React, {MouseEvent, useState} from 'react';
import {Button, Buttons, Container, Info, InfoContainer, NumberContainer} from './styles';
import {FirstButton, InfoBlock, InfoBlockNumber, SecondButton, ThirdButton} from '../../../../Assets/Icons/Matchmaking';

const buttonsArr = [
    {
        component: <FirstButton/>
    },
    {
        component: <SecondButton/>
    },
    {
        component: <ThirdButton/>
    },
]

function ModeInfo() {

    const [option, setOption] = useState(1)

    const handleSetOption = (e: MouseEvent<HTMLDivElement>) => {
        const target = (e.target as HTMLDivElement)
        const element = target.closest('[data-button]') as HTMLElement
        if (!element) return;
        const buttonNumber = element.dataset.button
        if (!buttonNumber) return;
        setOption(+buttonNumber)
    }

    return (
        <Container>
            <InfoContainer>
                <InfoBlock/>
                <Info>
                    <p>info</p>
                    <div>
                        The Colosseum is an oval amphitheatre in the centre of the city of Rome, Italy, just east of
                        the Roman Forum. It is the largest ancient amphitheatre ever built, and is still the largest
                        standing amphitheatre in the world today, despite its age.
                    </div>
                </Info>
            </InfoContainer>
            <NumberContainer>
                <InfoBlockNumber/>
                <div>{String(option).padStart(2, '0')}</div>
            </NumberContainer>
            <Buttons onClick={handleSetOption}>
                {buttonsArr.map((item, idx) =>
                    <Button key={`button-${idx}`} active={option === idx + 1} data-button={idx + 1}>
                        {item.component}
                    </Button>
                )}
            </Buttons>
        </Container>
    );
}

export default ModeInfo;
