import React from 'react';
import {Container, Title} from './styles';
import {SelectedMapsContainer} from '../../../../Assets/Icons/Matchmaking';

function SelectedMapsTitle({ text }: { text: string }) {
    return (
        <Container>
            <SelectedMapsContainer />
            <Title>{text}</Title>
        </Container>
    );
}

export default SelectedMapsTitle;
