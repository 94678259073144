import React, { useState, useEffect, useMemo, useCallback } from "react"
import { injected } from "Components/Connector/connectors"
import { useWeb3React } from "@web3-react/core"
import { isAnonym } from "utils/checkUser"

export const MetaMaskContext = React.createContext<any>(null)

export const MetaMaskProvider = ({ children }: any) => {
	const { activate, account, active, deactivate } = useWeb3React()

	const [isActive, setIsActive] = useState(false)
	const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to MetaMask
	const [isLoading] = useState(true)
	const [error, setError] = useState("")

	// Check when App is Connected or Disconnected to MetaMask
	const handleIsActive = useCallback(() => {
		console.log("App is connected with MetaMask ", active)
		setIsActive(active)
	}, [active])

	useEffect(() => {
		handleIsActive()
	}, [handleIsActive])

	// Connect to MetaMask wallet
	const connect = async () => {
		if (isAnonym()) return
		// console.log("Connecting to MetaMask...")
		// setShouldDisable(true)
		// try {
		// 	await activate(injected, e => {
		// 		console.log({ e })
		// 		setError(e.name)
		// 		throw e.name
		// 	}).then(() => {
		// 		setShouldDisable(false)
		// 	})
		// } catch (error) {
		// 	console.log("Error on connecting: ", error)
		// 	return error
		// }
	}

	// Disconnect from Metamask wallet
	const disconnect = async () => {
		console.log("Disconnecting wallet from App...")
		try {
			await deactivate()
		} catch (error) {
			console.log("Error on disconnect: ", error)
		}
	}

	const values = useMemo(
		() => ({
			isActive,
			account,
			isLoading,
			connect,
			disconnect,
			shouldDisable,
			error,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isActive, isLoading, shouldDisable, account, error]
	)

	return <MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>
}

export default function useMetaMask() {
	const context = React.useContext(MetaMaskContext)

	if (context === undefined) {
		throw new Error("useMetaMask hook must be used with a MetaMaskProvider component")
	}

	return context
}
