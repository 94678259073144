import styled from "styled-components"
import { Flex } from "globalStyles"

export const Container = styled(Flex)`
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 40px;
`

export const StatusContainer = styled(Flex)`
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	> span {
		font-family: "Red Hat Display", sans-serif;
		font-size: 20px;
		line-height: 25px;
		margin-bottom: 30px;
	}

	> button {
		margin-bottom: 30px;
	}
`
