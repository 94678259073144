import { ErrorBlock, ErrorImage, ErrorText, ErrorTitle, Wrapper } from "./style"
import ErrImage from "../../../Assets/Images/ErrorImage.png"
import { Collapse } from "antd"
import { MainMenuBtn } from "../MatchmakingResult/styles"
import { useEffect, useState } from "react"

const { Panel } = Collapse

const GameError = () => {
	const [errorText, setErrorText] = useState("")

	const mainMenuButtonHandler = () => {
		window.location.href = "/profile"
	}

	useEffect(() => {
		const errorFromLS = localStorage.getItem("game_error")
		if (!errorFromLS) {
			// mainMenuButtonHandler()
			return
		}

		setErrorText(errorFromLS)
	}, [])

	return (
		<Wrapper>
			<ErrorBlock>
				<ErrorTitle>Error occured</ErrorTitle>
				<ErrorImage>
					<img src={ErrImage} alt="" />
				</ErrorImage>
				{errorText && (
					<ErrorText>
						<Collapse bordered={false}>
							<Panel header="Error details" key="1">
								<p>{errorText}</p>
							</Panel>
						</Collapse>
					</ErrorText>
				)}
				<MainMenuBtn onClick={mainMenuButtonHandler} style={{ textAlign: "center", margin: 0 }}>
					main menu
				</MainMenuBtn>
			</ErrorBlock>
		</Wrapper>
	)
}

export default GameError
