import styled from "styled-components"
import { Button as Btn } from "antd"
import { Colors } from "globalStyles"

export const Button = styled(Btn)<{ filled: boolean; fz?: number; fw?: number; disable?: boolean }>`
	display: block;
	width: 100%;
	height: 100%;

	line-height: 3em;
	// padding: 10px 0;

	font-size: ${({ fz = 18 }) => fz}px;
	font-weight: ${({ fw = 400 }) => fw};

	color: white;
	border-radius: 5px;
	${({ filled, disable }) =>
		filled
			? `
          background-color: ${disable ? "transparent" : Colors.blue};
          // border-color: ${Colors.blue};
          border-color: ${disable ? Colors.lightBlue : Colors.blue};
          
          :hover, :focus {
            background-color: transparent;
            ${
				disable &&
				`
            border-color: ${Colors.lightBlue};
            cursor: default;
            `
			}
        }
        `
			: `
        background-color: transparent;
        border-color: ${disable ? Colors.lightBlue : Colors.blue};
        :hover, :focus {
          ${
				disable
					? `
            background-color: transparent;
            border-color: ${Colors.lightBlue};
            cursor: default;
            `
					: `
            background-color: ${Colors.blue};
            `
			}
          }
        `}
`
