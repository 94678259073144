import { Colors } from "globalStyles"
import styled from "styled-components"

export const Wrapper = styled.div`
	// background-color: red;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: 10% 1fr;
`

export const SteppedLine = styled.div`
	position: relative;
	display: grid;
	grid-auto-flow: column;
`

export const Line = styled.div`
	height: 2544px;
	width: 26px;
	border-right: 3px solid ${Colors.blue};
`

export const Circle = styled.div`
	// // position: absolute;
	// // top: 0;
	// // left: 0;
	// // left: -25px;

	// margin-top: 245px;
	// margin-left: -73px;

	position: absolute;
	bottom: 0;
	left: 0;
	left: -118px;

	// margin-top: 245px;
	// margin-left: -73px;

	height: 53px;
	width: 53px;

	border-radius: 50%;
	background-color: ${Colors.cardBG};
	border: 3px solid ${Colors.blue};
`

export const Rows = styled.div``

export const Row = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 100px;
	margin-bottom: 70px;
`

export const Instruct = styled.div`
	align-self: start;
`

export const Number = styled.div`
	font-family: Red Hat Display;
	font-weight: 700;
	font-size: 80px;

	line-height: 65px;

	border-bottom: 3px solid white;
	padding-bottom: 20px;
	margin-bottom: 35px;
`

export const Text = styled.div`
	font-family: Red Hat Display;
`

export const ScreenShot = styled.div<any>`
	height: 300px;
	width: 532px;

	overflow: hidden;
	background-color: white;
	border-radius: 16px;

	position: relative;

	& img {
		// height: 150%;
		width: ${props => props.width}%;
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
	}
`
