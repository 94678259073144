import React from 'react';
import { Container, Title } from './styles';
import { SelectedModeContainer } from 'Assets/Icons/Matchmaking'

function SelectedMode({ text }: { text: string }) {
    return (
        <Container>
            <SelectedModeContainer />
            <Title>{text}</Title>
        </Container>
    );
}

export default SelectedMode;
