import ProfileLayout from "Components/Layouts/ProfileLayout/Layout"
import FreeAssetsDetails from "Components/Organisms/FreeAssets/Details"
import FreeAssetsTable from "Components/Organisms/FreeAssets/Table"
import AccountPage from "Components/Organisms/Profile/Account"
import StatisticsPage from "Pages/Profile/Statistics"
import WalletPage from "Pages/Profile/Wallet"
import { RoomsPage } from "Pages/Rooms"
import { Navigate, Route, Routes } from "react-router-dom"

const ProfileRoutes = () => {
	return (
		<Routes>
			<Route element={<ProfileLayout />}>
				{/* <Route index element={<Navigate to="wallet"/>}/> */}
				<Route index element={<AccountPage />} />
				{/* <Route path="/free_assets" element={<FreeAssetsTable />} />
				<Route path="/free_assets/*" element={<FreeAssetsDetails />} />
				<Route path="/wallet" element={<WalletPage />} />
				<Route path="/statistics" element={<StatisticsPage />} />
				<Route path="/rooms" element={<RoomsPage />} /> */}
			</Route>
			<Route path="*" element={<Navigate to="/not_found" />} />
		</Routes>
	)
}

export default ProfileRoutes
