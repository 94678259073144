import BG from "../../../Assets/Icons/Abilities/bg.svg"

import styled from "styled-components"

export const StyledAbilityItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 60px;

	background-image: url(${BG});
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
`
