import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface IMarketItem {
	name: string
	owner: string
}

interface IState {
	popup: string | null
	item: IMarketItem | null
}

const initialState: IState = {
	popup: null,
	item: null,
}

export const marketSlice = createSlice({
	name: "market",
	initialState,
	reducers: {
		setPopup(state, action: PayloadAction<string | null>) {
			state.popup = action.payload
		},
		setItem(state, action: PayloadAction<IMarketItem | null>) {
			state.item = action.payload
		},
	},
})

export const { setPopup, setItem } = marketSlice.actions

export default marketSlice.reducer
