import { useAppSelector } from "hooks/redux"
import { useEffect } from "react"
import ledgerAPI from "services/LedgerService"
import { getAccountNameFromSS } from "store/reducers/auth/NearSlice"
import { Content, Wrapper } from "./style"
import { getQueryParameterByName } from "utils/getQueryParameterByName"

const NearReturnMoney = () => {
	const [returnMoney, { isError, isLoading, isSuccess, data }] = ledgerAPI.useReturnMoneyMutation()
	const { account, bet, rowHash } = useAppSelector(state => state.nearReducer)

	useEffect(() => {
        console.log("useEffect")
        console.log({account, bet, rowHash})
        if ((!account || !getAccountNameFromSS()) || !bet || !rowHash) return
        console.log("SEND RETURN MONEY")
		returnMoney({ accountName: account.accountId || getAccountNameFromSS()!, hash: rowHash })
	}, [account, bet, rowHash])

	useEffect(() => {
		setTimeout(() => {
			window.location.pathname = "/profile"
        }, 3000)
	}, [])

	if (data?.message == "Хэш уже оплачен") {
		return (
			<Wrapper>
				<Content>Tickets for this hash have already been received</Content>
			</Wrapper>
		)
	}
    
	if (data?.message == "Прошло меньше 5 минут") {
		return (
			<Wrapper>
				<Content>Tickets for this hash have already been received</Content>
			</Wrapper>
		)
	}

	if (data?.message == "money sended") {
		return (
			<Wrapper>
				<Content>Tickets have been refunded</Content>
			</Wrapper>
		)
	}

	if (isLoading) {
		return (
			<Wrapper>
				<Content>Loading...</Content>
			</Wrapper>
		)
	}

	// setTimeout(() => {
	// 	window.location.pathname = "/profile"
	// }, 5000)

	return (
		<Wrapper>
			{!!bet ? (
				<Content>
					Tickets for this hash in the amount of  - <span>{bet} NEAR</span> <br /> will be refunded in the near future
				</Content>
			) : (
				<Content>Tickets for this hash will be refunded in the near future</Content>
			)}
		</Wrapper>
	)
}

export default NearReturnMoney
