import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const Container = styled(Flex)`
	align-items: flex-start;
	justify-content: space-between;
	margin-left: 41%;

	> #qubix_logo {
		width: 30%;
	}
`
