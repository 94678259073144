import React from 'react';
import {Container, Info} from './styles';
import Biohive from '../../../../Assets/Images/Matchmaking/maps/Biohive.jpg';
import {MapIcon} from '../../../../Assets/Images';

function SelectedMap() {
    return (
        <Container>
            <div id="pentagon">
                <img src={Biohive} alt="biohive"/>
                <div />
            </div>
            <Info>
                <div>biohive</div>
                <MapIcon />
            </Info>
        </Container>
    );
}

export default SelectedMap;
