import {
	Abilities,
	AbilitiesItem,
	AbilitiesItems,
	AbilitiesTitle,
	HeroImage,
	HeroName,
	Parameters,
	ParametersDescription,
	ParametersItem,
	ParametersTitle,
	ParametersValue,
	Wrapper,
	ParametersItems,
	InfoIconWrap,
	PopoverText,
} from "./style"
import AbilitiesImage1 from "../../../../Assets/Images/Abilities/Abilities1.png"
import AbilitiesImage2 from "../../../../Assets/Images/Abilities/Abilities2.png"
import AbilitiesImage3 from "../../../../Assets/Images/Abilities/Abilities3.png"
import AbilitiesImage4 from "../../../../Assets/Images/Abilities/Abilities4.png"
import { ReactComponent as AbilitiesImage5 } from "../../../../Assets/Images/Abilities/Abilities5.svg"
import { ReactComponent as AbilitiesImage6 } from "../../../../Assets/Images/Abilities/Abilities6.svg"
import { ReactComponent as InfoIcon } from "../../../../Assets/Images/InfoIcon.svg"
import { useAppSelector } from "hooks/redux"
import { ReactComponent as RentNFT } from "../../../../Assets/Images/RentNFT.svg"
import { AbilityItem } from "Components/Atoms/Abilities"
import { useMemo } from "react"
import { getAbilitiesIcons } from "utils/getAbillitiesIcons"
import { Popover } from "antd"

const HeroPanel = () => {
	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)

	const icons = useMemo(() => {
		console.log("selectedNFT?.metadata.name", selectedNFT?.metadata.name )
		return getAbilitiesIcons(selectedNFT?.metadata.name || "")
	}, [selectedNFT])

	const iconsExists = icons?.length > 0

	const renderAbilities = useMemo(() => {
		return iconsExists ? (
			<AbilitiesItems>
				{icons.map((item, index) => (
					<Popover placement="bottomLeft" key={index} content={<PopoverText>{item.info}</PopoverText>} trigger="hover">
						<>
							<AbilityItem>
								<img src={item.icon} alt="ability" />
							</AbilityItem>
						</>
					</Popover>
				))}
			</AbilitiesItems>
		) : (
			<AbilitiesItems>
				<AbilitiesItem>
					<AbilitiesImage6 />
				</AbilitiesItem>
				<AbilitiesItem>
					<AbilitiesImage6 />
				</AbilitiesItem>
				<AbilitiesItem>
					<AbilitiesImage6 />
				</AbilitiesItem>
				<AbilitiesItem>
					<AbilitiesImage6 />
				</AbilitiesItem>
			</AbilitiesItems>
		)
	}, [icons, iconsExists])

	return (
		<>
			{
				selectedNFT ? (
					<Wrapper style={{ opacity: 1 }}>
						<HeroImage>
							<img src={selectedNFT?.metadata.image} alt="" />
						</HeroImage>
						<HeroName>{selectedNFT?.metadata.name}</HeroName>
						<Abilities>
							<AbilitiesTitle>Abilities</AbilitiesTitle>
							{renderAbilities}
						</Abilities>
						<Parameters>
							<ParametersTitle>Parameters</ParametersTitle>
							<ParametersItems>
								{selectedNFT?.metadata?.attributes?.map((attr, index) => (
									<ParametersItem key={index}>
										<ParametersValue>{attr.value}</ParametersValue>
										<ParametersDescription>{attr.trait_type}</ParametersDescription>
									</ParametersItem>
								))}
							</ParametersItems>
						</Parameters>
					</Wrapper>
				) : (
					<Wrapper>
						<HeroImage>
							<RentNFT />
						</HeroImage>
						<HeroName>CHOOSE NFT</HeroName>
						<Abilities>
							<AbilitiesTitle>Abilities</AbilitiesTitle>

							{renderAbilities}
						</Abilities>
						<Parameters>
							<ParametersTitle>Parameters</ParametersTitle>
							<ParametersItems>
								<ParametersItem>
									<ParametersValue>?</ParametersValue>
									<ParametersDescription>Health</ParametersDescription>
								</ParametersItem>
								<ParametersItem>
									<ParametersValue>?</ParametersValue>
									<ParametersDescription>Armor</ParametersDescription>
								</ParametersItem>
								<ParametersItem>
									<ParametersValue>?</ParametersValue>
									<ParametersDescription>Speed</ParametersDescription>
								</ParametersItem>
								<ParametersItem>
									<ParametersValue>?</ParametersValue>
									<ParametersDescription>Strength</ParametersDescription>
								</ParametersItem>
							</ParametersItems>
						</Parameters>
					</Wrapper>
				)
				// null
			}
		</>
	)
}

export default HeroPanel
