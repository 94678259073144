import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { hubQueryWithReauth } from "./index"
import { Error } from "./types"
import { ICoinTransactionResponse, ICoinsNames } from "../models/ICoin"

export const coinAPI = createApi({
	reducerPath: "coinAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getTransactions: build.query<ICoinTransactionResponse, ICoinsNames>({
			query: coin => ({
				url: `/test/coin-transactions?coin=${coin}`,
			}),
		}),
	}),
})

export const { useGetTransactionsQuery } = coinAPI
