import { Button, Modal } from "antd"
import { FC } from "react"
import { BuyTicketButton, ToManySessionsModalText, CloseButton } from "./style"
import "./style.css"

interface ToManySessionsProps {
	isModalOpen: boolean
	setIsModalOpen: (mode: boolean) => void
	action: any
}

const ToManySessionsModal: FC<ToManySessionsProps> = ({ isModalOpen, setIsModalOpen, action }) => {
	const handleOnClose = () => {
		setIsModalOpen(false)
	}

	return (
		<Modal
			title="Game unavailable"
			open={isModalOpen}
			// onOk={reconnectAction}
			onCancel={handleOnClose}
			className="bugreport"
			centered={true}
			style={{ borderRadius: "10px" }}
			footer={[
				<Button onClick={action} type="primary" style={{ backgroundColor: "purple", borderColor: "purple" }}>
					<BuyTicketButton>Buy a ticket</BuyTicketButton>
				</Button>,
				<Button onClick={handleOnClose} type="ghost">
					<CloseButton>Close</CloseButton>
				</Button>,
			]}
		>
			<ToManySessionsModalText>
				You have played the maximum number of free games :{"("} <br /> To continue playing - buy a ticket
			</ToManySessionsModalText>
		</Modal>
	)
}

export default ToManySessionsModal
