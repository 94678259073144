import { TonErrorText } from "./style"

const TonError = ({text} : {text: string}) => {
    return (
        <TonErrorText>
            {text}
        </TonErrorText>
    )
}

export default TonError