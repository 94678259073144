import styled, { AnyStyledComponent } from "styled-components"
import { Progress, Button } from "antd"
import { Flex } from "globalStyles"

export const StyledButton = styled(Button)`` // ok

export const StyledProgress = styled(Progress as unknown as AnyStyledComponent)`` // error

interface Props {
	mt?: number
}

export const Container = styled(Flex)<Props>`
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
	margin-top: ${({ mt = 0 }) => mt}px;
`

export const ProgressText = styled.div`
	color: #f4f4f4;
	opacity: 0.5;
`
