import React from 'react';
import {Container, ProgressText, StyledProgress as Progress} from './styles'
import {useProgress} from '../../../../hooks/useProgressBar';

interface Props {
    mt?: number
    importantValue?: null | number
}

function ProgressBar({ mt, importantValue=null }: Props) {
    const { value: progress } = useProgress()

    return (
        <Container mt={mt}>
            <ProgressText>{importantValue || progress}%</ProgressText>
            <Progress
                percent={importantValue || progress}
                trailColor="#0D1938"
                showInfo={false}
                strokeColor={{
                    '0%': '#DA2EAF',
                    '100%': '#02B7B7',
                }}
            />
        </Container>
    );
}

export default ProgressBar;
