import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useState } from "react"
import { setMinPlayersForStart } from "store/reducers/GameSlice"
import { ChooseCountPlayers, ChooseMinPlayersForStartBody, Dicrement, Increment, Wrapper, ChooseTitle } from "./style"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"


const NewChooseMinPlayersForStart = () => {
	const dispatch = useAppDispatch()
	const { preferences: gamePreferences } = useAppSelector(state => state.game)
	const [countMinPlayersForStartError, setCountMinPlayersForStartError] = useState(false)

	const changeCountMinPlayersForStart = (diff: number) => {
		const newValue = gamePreferences.minPlayersForStart + diff

		if (newValue < 2 || newValue > 6) {
			// моргнуть красным цветом
			setCountMinPlayersForStartError(true)
			setTimeout(() => {
				setCountMinPlayersForStartError(false)
			}, 300)
			
			console.error("Number out of range")
			return
		}
		localStorage.setItem("minPlayersForGames", newValue + "")
		dispatch(setMinPlayersForStart(newValue))
	}

	return (
		<Wrapper>
			<ChooseTitle>Players</ChooseTitle>
			<ChooseMinPlayersForStartBody>
				<Dicrement onClick={() => changeCountMinPlayersForStart(-1)}>
					<div>
						-
					</div>
				</Dicrement>
				<ChooseCountPlayers warning={countMinPlayersForStartError}>{gamePreferences.minPlayersForStart}</ChooseCountPlayers>
				<Increment onClick={() => changeCountMinPlayersForStart(1)}>
					<div>
						+
					</div>
				</Increment>
			</ChooseMinPlayersForStartBody>
		</Wrapper>
	)
}

export default NewChooseMinPlayersForStart
