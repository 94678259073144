import styled from "styled-components"
import { Grid } from "../../../../globalStyles"

export const Container = styled.div``

export const MapsContainer = styled(Grid)`
	margin-top: 15px;
	gap: 40px;
	grid-template-columns: repeat(4, 1fr);
`
