import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { gameQueryWithReauth } from "./index"
import { Error } from "./types"

export interface EncodedMessage {
	payload: string
	signatures: [
		{
			protected: string
			signature: string
		}
	]
}

interface FindRoomReq {
	auth_data: EncodedMessage
	wallet_data: EncodedMessage
	preferences: {
		map_ids: number[]
		game_modes: string[]
		multiplayer_schemas: Array<number[]>
	}
}


export interface ProcessStartGameInfoRes {
	fake_percent_load: number
	orig_percent_load: number
	net_speed: number
	user_id: string
	room_id: string
	nftName: string
	ping: number,
	playersCount: number
	isException?: boolean
	timeLastMessage: number
	firstMessage?: number
	nickname: string
}
export interface ProcessStartGameInfoReq {
	fake_percent_load: number
	orig_percent_load: number
	net_speed: number
	user_id: string
	room_id: string
	nftName: string
	nickname: string
}

export const matchmakingAPI = createApi({
	reducerPath: "matchmakingAPI",
	baseQuery: gameQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		findRoom: build.mutation<EncodedMessage, FindRoomReq>({
			query: body => ({
				url: "/find_room",
				method: "POST",
				body,
			}),
		}),
		sendProcessStartGameInfo: build.mutation<Record<string, ProcessStartGameInfoRes>, ProcessStartGameInfoReq>({
			query: body => ({
				url: "/status_process",
				method: "POST",
				body
			})
		}),
		getPlayersInGameAmount: build.mutation<{playersInGameAmount: number}, {roomId: string}>({
			query: body => ({
				url: "/playersInGameAmount",
				method: "POST",
				body
			})
		})
	}),
})

export const { useFindRoomMutation } = matchmakingAPI
