import styled from "styled-components"

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    place-items: center;
`
export const Content = styled.div`
    padding: 30px;
    background-color: blue;
    border-radius: 10px;
    font-weight: 500;
    font-size: min(2.2vw, 25px);
    text-align: center;

    & span {
        font-weight: 900;
        letter-spacing: 1px;
        font-size: 35px;
    }
`