import seedrandom from 'seedrandom'

export default function () {
	// Подключаем библиотеку seedrandom

	// Задаем сид и создаем генератор случайных чисел с фиксированным сидом
	const rng = seedrandom(new Date().getHours() + "")

	// Генерируем случайное число от 0 до 99
	const randomNumber = Math.floor(rng() * 500)

	// Выводим результат
	return randomNumber
}
