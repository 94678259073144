import styled from "styled-components"

export const Container = styled.div`
	position: relative;

	> svg {
		display: block;
	}

	> svg path {
		transition: fill 0.2s ease-in-out;
	}

	:hover {
		cursor: pointer;

		> svg path {
			fill: #f44708;
		}
	}

	> div {
		position: absolute;
		inset: 0;
		margin: 0 auto;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "Red Hat Display", sans-serif;
		text-transform: uppercase;

		@media screen and (max-width: 1440px) {
			font-size: 38px;
			line-height: 46px;
		}

		@media only screen and (min-width: 1441px) and (max-width: 1599px) {
			font-size: 36px;
			line-height: 44px;
		}

		@media only screen and (min-width: 1600px) and (max-width: 1919px) {
			font-size: 44px;
			line-height: 52px;
		}

		@media only screen and (min-width: 1920px) {
			font-size: 52px;
			line-height: 60px;
		}
	}
`

export const Text = styled.div``
