import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import OrangeButton from "Components/Atoms/Matchmaking/OrangeButton"
import { Header, Stat, StatisticsBlock, Stats } from "./styles"
import { StatisticsContainer, StatisticsHeader } from "Assets/Icons/Matchmaking"
import { useGetLastGameStatsMutation } from "../../../../services/LedgerService"
import { useAppDispatch } from "../../../../hooks/redux"
import { resetState } from "store/reducers/GameSlice"

type Props = {
	stats: {
		kills: number
		deaths: number
		damage: number
		avglifetime: number
	}
}

const MatchmakingStatistics: FC<Props> = ({ stats }) => {
	const arr: [string, number][] = [
		["Kills", stats.kills],
		["Deaths", stats.deaths],
		["Damage", stats.damage],
		["Average lifetime", stats.avglifetime],
	]

	return (
		<>
			<Header>
				{/* <StatisticsHeader/> */}
				<span>overall breakdown</span>
			</Header>
			<StatisticsBlock>
				{/* <StatisticsContainer/> */}
				<Stats>
					{/* {isLoading && <div>Loading...</div>} */}
					{/* {Object.entries(stats).map(([key, value]: [string, string | number]) => */}
					{arr.map(([key, value]) => (
						<Stat key={key}>
							<span>{key}</span>
							<span>{value}</span>
						</Stat>
					))}
				</Stats>
			</StatisticsBlock>
		</>
	)
}

export default MatchmakingStatistics