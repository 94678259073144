import React from 'react';
import {useGetAvatarsQuery} from 'services/FilesService';
import {Header, IconsTable, IconCard} from './styles';

interface IProps {
    checked: string
    onCheck: (icon: string) => void
}

const SelectIcon = ({ checked, onCheck }: IProps) => {
    const {data, isSuccess, isLoading} = useGetAvatarsQuery()

    const onCheckIcon = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement
        const cond = target.closest('[data-icon]') as HTMLDivElement

        if (cond) {
            const icon = cond.dataset['icon'] || ''
            onCheck(icon)
        }
    }

    return (
        <>
            <Header>Select icon</Header>
            <IconsTable onClick={onCheckIcon}>
                {
                    isSuccess && (
                        data.avatars.map((icon) => (
                            <IconCard key={icon} data-icon={icon} active={icon === checked}>
                                <img src={icon} alt="avatar"/>
                            </IconCard>
                        ))
                    )
                }
                {
                    isLoading && (
                        <span>Loading...</span>
                    )
                }
            </IconsTable>
        </>
    );
};

export default SelectIcon;
