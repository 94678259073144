import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { Error } from "./types"
import { hubQueryWithReauth } from "./index"

type getUrlWebGLBuildTypes = {
	mode: "hub" | "roomlist" 
	env: "dev" | "prod" | "test"
}

export const filesAPI = createApi({
	reducerPath: "filesAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getAvatars: build.query<{ avatars: string[] }, void>({
			query: () => ({
				url: "/files/mockAvatars",
			}),
		}),
		getUrlWebGLBuild: build.query<{ url: string }, getUrlWebGLBuildTypes>({
			query: params => ({
				url: "/webgl_builds/link",
				params,
			}),
		}),
	}),
})

export const { useGetAvatarsQuery } = filesAPI
