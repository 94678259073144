import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { ITicket, IUser } from "../models/IUser"
import { IAccount } from "models/IAccount"
import { Error } from "./types"
import { hubQueryWithReauth } from "./index"

export const authAPI = createApi({
	reducerPath: "authAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error, Record<string, unknown>>,
	endpoints: build => ({
		verifySignature: build.mutation<
			{
				tickets: ITicket
				user: IUser
			},
			Partial<IAccount>
		>({
			query: ({ address, message, signature, chainId }) => ({
				url: "/auth/withMetamask",
				method: "POST",
				body: {
					address,
					message,
					signature,
					chain_id: chainId?.toString(),
				},
			}),
		}),
	}),
})

export const { useVerifySignatureMutation } = authAPI
