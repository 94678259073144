import styled from "styled-components"
import { Flex } from "globalStyles"

export const Wrapper = styled(Flex)`
	justify-content: center;
	width: 100vw;
	max-height: 100vh;
	background: #040c1e;
	padding: 0 40px;
	overflow-y: auto;
`

export const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	max-width: 1440px;
	min-height: 100%;
	padding-top: 70px;

	> img {
		max-width: 1360px;
		margin: 0 auto 80px auto;
		border-radius: 8px;
	}
`

export const Navigate = styled.nav`
	position: fixed;
	display: flex;
	justify-content: space-between;
	height: 100px;
	align-items: flex-end;
	padding: 0 40px 20px 40px;
	width: 100%;
	max-width: 1440px;
	margin-bottom: 60px;
	background: #040c1e;
	z-index: 2;

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> div:not(:last-of-type) {
			margin-right: 10px;
		}
	}
`

export const NavItem = styled(Flex)<{ active?: boolean }>`
	align-items: center;
	justify-content: center;
	padding: 5px 20px;
	border: 1px solid rgba(255, 255, 255, 0.15);
	border-radius: 8px;
	cursor: pointer;
	transition: background-color ease-in-out 0.2s;
	background: ${({ active }) => (active ? "linear-gradient(108.07deg, #DA2EAF -4.96%, #02B7B7 105.85%)" : "none")};

	&:hover {
		background: linear-gradient(108.07deg, #da2eaf -4.96%, #02b7b7 105.85%);
	}
`

export const History = styled(Flex)`
	justify-content: space-between;
	margin-bottom: 100px;

	div {
		span {
			font-family: "Red Hat Display", sans-serif;
			font-size: 20px;
			line-height: 130%;
		}

		div {
			display: flex;
			align-items: center;
			margin-top: 50px;

			div {
				margin-right: 60px;
				border: 2px solid #d81ab5;
				border-radius: 50%;
				padding: 25px;
			}
		}
	}
`

export const Section = styled(Flex)`
	justify-content: space-between;
	margin-bottom: 120px;

	div:first-of-type {
		flex-direction: column;
		margin-right: 80px;
	}

	div:last-of-type {
		span {
			font-family: "Red Hat Display", sans-serif;
			font-size: 20px;
			line-height: 26px;
		}

		span:first-of-type {
			padding-bottom: 30px;
		}
	}
`

export const GradientText = styled.span`
	background-image: linear-gradient(108.07deg, #da2eaf -4.96%, #02b7b7 105.85%);
	color: transparent;
	-webkit-background-clip: text;
`

export const DivHeader = styled.h3`
	font-family: "Red Hat Display", sans-serif;
	font-size: 60px;
	line-height: 72px;
	color: #ffffff;
`

export const Irreplaceable = styled(Flex)`
	justify-content: center;
	margin-top: 200px;

	div {
		width: 30ch;
	}

	div:first-of-type {
		margin-right: 80px;
	}
`

export const Games = styled(Flex)`
	width: 100%;
	flex-direction: column;

	h3 {
		margin-bottom: 80px;
	}

	div:last-of-type {
		border-top: none;
	}
`

export const Game = styled(Flex)`
	justify-content: space-between;
	padding-top: 40px;
	border: 1px solid #00bdff;
	border-right: none;
	border-left: none;

	img {
		margin-right: 80px;
	}

	div {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
`

export const PlayButton = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 145px;
	height: 45px;
	background: linear-gradient(96.25deg, #da2eaf 0%, #02b7b7 100%);
	border-radius: 8px;
	margin-bottom: 40px;

	font-family: "Red Hat Display", sans-serif;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	cursor: pointer;

	&:hover {
		color: #fff;
	}
`
