import styled from "styled-components"

export const BugReportButton = styled.div`
	position: absolute;
	background-color: purple;
	font-size: 15px;
	font-weight: 700;
	padding: 2px 7px;
	right: 30px;
	border-radius: 5px;
	cursor: pointer;
`

export const BugReportModal = styled.div`
	color: black;
    .ant-modal-content {
        border-radius: 10px;
    }
	& * {
		color: black !important;
	}
`

export const SendButton = styled.span`
    color: white;
    font-size: 14px;
`

export const BugReportFieldTitle = styled.div`
	font-size: 14px;
`