import styled from "styled-components"
import { Flex, Grid } from "../../../../globalStyles"
import StyledTitle from "Components/Atoms/Auth/Title/styles"

export const Container = styled(Flex)`
	margin-top: 30px;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

export const Title = styled(StyledTitle)`
	text-transform: uppercase;
`

export const MapsContainer = styled(Grid)`
	margin-top: 32px;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
`
