import styled from "styled-components"
import background1 from "../../../Assets/Images/Background1.png"
import background2 from "../../../Assets/Images/Background2.png"
import { Content, Flex } from "../../../globalStyles"

export const AuthWrapper = styled(Content)`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url(${background2}) no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-clip: padding-box;
	z-index: 1;
	box-sizing: border-box;

	@media (max-height: 550px) {
		overflow-x: scroll;
	}
`

export const AuthContainer = styled(Flex)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	// position: relative;
	width: 50vw;
	/* height: 100%; */
	z-index: 2;
	background: url(${background1}) no-repeat center;
	/* background-size: 100% 100%; */
	background-size: min(100%, 700px);
`

export const AuthContentBlock = styled(Flex)`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px;
	justify-content: center;
	border-radius: 5px;

	/* max-height: 600px; */

	&:before {
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		heigth: 100%;
		position: absolute;
		z-index: -1;
		border-radius: 25px;
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(6px) opacity(0.9);
	}
`

export const ContinueAsAnonymButton = styled.div`
	position: absolute;
	top: 20px;
	left: 20px;

	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
	background-color: rgba(38, 38, 38, 1);
	transition: all 0.3s;

	& svg{
		margin-right: 10px;
	}

	&:hover {
		background-color: grey;
	}	
`
