import styled from "styled-components"

export const BuyTicketButton = styled.span`
    color: white;
    font-size: 14px;
    font-weight: 500;
`

export const BugReportFieldTitle = styled.div`
	font-size: 14px;
`
export const ToManySessionsModalText = styled.div`
    text-align: center;
`

export const CloseButton = styled.div`
	color: black;
    font-size: 14px;
`