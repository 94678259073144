import * as nearAPI from 'near-api-js'

export const ConvertNearToYoctoNear = (amount: string) => {
	// return new BN(Math.round(+amount * 10 ** 8)).mul(new BN("10000000000000000"))
	return nearAPI.utils.format.parseNearAmount(amount)
}

export const ConvertYoctoNearToNear = (amount: string) => {
	// return new BN(Math.round(+amount * 10 ** 8)).mul(new BN("10000000000000000"))
	return nearAPI.utils.format.formatNearAmount(amount)
}