import { v4 } from "uuid"

export const isArcanaUser = () => {
	const exist = sessionStorage.getItem("isArcanaUser")
	return exist === "true"
}

export const isNearUser = () => {
	const near_account_name = sessionStorage.getItem("near_account_name")
    if (near_account_name) {
        return true
    }
    return false
}

export const isMetaMaskUser = () => {
	return false
	const access = sessionStorage.getItem("access")
	const refresh = sessionStorage.getItem("refresh")
    const game_token = sessionStorage.getItem("game_token")
    if (game_token && access && refresh) {
        return true
    }
    return false
}

export const isAnonym = () => {

	if (isNearUser() || isMetaMaskUser() || isArcanaUser()) return false

	const onAuth = window.location.toString().includes("auth")
	// console.log({ onAuth })
	if (onAuth) return false

	const isAnonymUser = sessionStorage.getItem("isAnonymUser")
	// console.log({ isAnonym: !isAnonymUser || isAnonymUser === "true" || !onAuth })
	return !isAnonymUser || isAnonymUser === "true"
}

export const generateAnonymUserId = () => {
	return "anonymous_" + v4()
}
