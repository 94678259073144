import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ethers } from "ethers"
import axios from "axios"
import { IAccount } from "../../../models/IAccount"
import { isAnonym, isArcanaUser, isNearUser } from "utils/checkUser"

interface IState {
	loading: boolean
	error: string | null
	account: IAccount
}

const initialState: IState = {
	loading: false,
	error: null,
	account: {
		address: null,
		balance: null,
		signature: null,
		message: "I want to join QBIX universe!",
		chainId: null,
	},
}

export const getWalletAccount = createAsyncThunk("getWalletAccount", async (_, { rejectWithValue }) => {
	if (isNearUser()) {
		return rejectWithValue("near user")
	}
	try {
		if (true || typeof window.ethereum === "undefined") return
		const provider = new ethers.providers.Web3Provider(window.ethereum)
		console.log("getWalletAccount")
		if (isAnonym() || isArcanaUser()) return rejectWithValue(null)
		// await provider.send("eth_requestAccounts", []).catch(e => {
		// 	throw new Error(e)
		// })
		const signer = provider.getSigner()
		const address = await signer.getAddress()
		const balance = await signer.getBalance()
		const chainId = await signer.getChainId()
		return { address, balance: ethers.utils.formatEther(balance), chainId }
	} catch (e) {
		console.log({getWalletAccountError: e})
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

export const verifyMetamask = createAsyncThunk("verifyMetamask", async (_, { rejectWithValue, getState }) => {
	// try {
	// const { ethereum } = window
	// // @ts-ignore
	// const { address, message } = getState().metamaskReducer.account
	// console.log({ ethereum: !!ethereum })
	// 	const signature = await ethereum.request({
	// 		method: "personal_sign",
	// 		params: [address, message],
	// 	})
	// 	return signature
	// } catch (e: any) {
	// 	console.log({ "e.message": e.message })
	// 	return rejectWithValue(e.message)
	// }
})

export const metamaskSlice = createSlice({
	name: "wallet",
	initialState,
	reducers: {
		setMessage(state, action: PayloadAction<string>) {
			state.account.message = action.payload
		},
		resetMessage(state) {
			state.account.message = ""
		},
	},
	extraReducers: {
		// [getWalletAccount.pending.type]: state => {
		// 	state.loading = true
		// },
		// [getWalletAccount.rejected.type]: (state, action) => {
		// 	console.log({ error: action.payload })
		// 	state.loading = false
		// 	state.error = action.payload
		// },
		// [getWalletAccount.fulfilled.type]: (state, action) => {
		// 	const { address, balance, chainId } = action.payload
		// 	state.loading = false
		// 	state.error = null
		// 	state.account.address = address
		// 	state.account.balance = balance
		// 	state.account.chainId = chainId
		// },
		[verifyMetamask.pending.type]: state => {
			state.loading = true
		},
		[verifyMetamask.rejected.type]: (state, action) => {
			state.loading = false
			state.error = action.payload
		},
		[verifyMetamask.fulfilled.type]: (state, action) => {
			state.loading = false
			state.error = null
			state.account.signature = action.payload
		},
	},
})

export default metamaskSlice.reducer
