import "./index.css"
import "antd/dist/antd.css"
import "@near-wallet-selector/modal-ui/styles.css"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import Web3 from "web3"
import { Web3ReactProvider } from "@web3-react/core"
import { ThemeProvider } from "styled-components"
import { MetaMaskProvider } from "./hooks/metamask"
import { setupStore } from "./store/store"
import { theme } from "./theme"
import App from "./App"
import ErrorBoundary from "Components/Organisms/Common/ErrorBoundary"
// import { AuthProvider } from "@arcana/auth"
// import { ProvideAuth as ProvideArcanaAuth } from "@arcana/auth-react"
import { Buffer } from "buffer"
import { WalletSelectorContextProvider } from "utils/near/wallet"

global.Buffer = Buffer

const store = setupStore()

export const persistor = persistStore(store)

const getLibrary = (provider: any) => {
	return new Web3(provider)
}

// disable all alerts and confirms
if (window) {
	window.alert = () => false
	window.confirm = (message: string) => false
}

// const providerArcanaAuth = new AuthProvider("24a4fea0e5873a7c1ed559868be8c2f2c4872e51", {
// 	alwaysVisible: true,
// 	network: "testnet",
// 	theme: "light",
// })

ReactDOM.render(
	<Web3ReactProvider getLibrary={getLibrary}>
		<MetaMaskProvider>
			<Provider store={store}>
				{/* <PersistGate loading={null} persistor={persistor}> */}
				<WalletSelectorContextProvider>
					<ThemeProvider theme={theme}>
						<ErrorBoundary>
							{/* <ProvideArcanaAuth provider={providerArcanaAuth}> */}
							<App />
							{/* </ProvideArcanaAuth> */}
						</ErrorBoundary>
					</ThemeProvider>
					{/* </PersistGate> */}
				</WalletSelectorContextProvider>
			</Provider>
		</MetaMaskProvider>
	</Web3ReactProvider>,
	document.getElementById("root")
)
