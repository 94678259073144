import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { hubQueryWithReauth } from "services"

type WalletResponse = {
	data: {
		blockchain: string
		walletAddress: string
		tokenAddress: string
		wNFTs: WNFT[]
	}
	code: number
}

type WNFT = {
	tokenId: string
	tokenAddress: string
	wNFTTokenId: string
	wNFTTokenAddress: string
}

type WNFTResponse = {
	data: {
		blockchain: string
		tokenId: string
		tokenAddress: string
		wNFTTokenId: string
		wNFTTokenAddress: string
		walletAddress: string
		status: string
	}
	code: number
}

export const rentNFTAPI = createApi({
	reducerPath: "rent NFT",
	baseQuery: hubQueryWithReauth,
	endpoints: build => ({
		getWallet: build.mutation<WalletResponse, string>({
			query: (walletAddress: string) => ({
				url: `/wnft/wallet`,
				method: "GET",
				params: {
					walletAddress,
					tokenContractAddress: process.env.REACT_APP_UNITBOX_CONTRACT_ADDRESS,
					blockchain: "ROP",
				},
			}),
		}),
		getWNFTInfo: build.mutation<WNFTResponse, string>({
			query: (wNFTTokenId: string) => ({
				url: `/wnft/token/${wNFTTokenId}`,
				method: "GET",
			}),
		}),
	}),
})

export const { useGetWNFTInfoMutation, useGetWalletMutation } = rentNFTAPI
