import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/react"
import { isAnonym, isNearUser } from "utils/checkUser"
import { ITicket } from "../models/IUser"

type BaseQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>
type ReturnedQuery = BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>

const hubBaseQuery = fetchBaseQuery({
	baseUrl: `${process.env.REACT_APP_HUB_SERVER}/api/v1`,
	prepareHeaders: headers => {
		const access = sessionStorage.getItem("access")
		if (access) {
			headers.set("authorization", `Bearer ${access}`)
		}
		return headers
	},
})

const gameBaseQuery = fetchBaseQuery({
	// baseUrl: `${process.env.REACT_APP_MATCHMAKING_SERVER}/matchmaking`,
	baseUrl: `${process.env.REACT_APP_MATCHMAKING_SERVER}`,
	prepareHeaders: headers => {
		const access = sessionStorage.getItem("access")
		if (access) {
			headers.set("authorization", `Bearer ${access}`)
		}
		return headers
	},
})

const ledgerBaseQuery = fetchBaseQuery({
	baseUrl:
		process.env.NODE_ENV === "production"
			? `${process.env.REACT_APP_MATCHMAKING_SERVER_LEDGER}/ledger`
			: `${process.env.REACT_APP_MATCHMAKING_SERVER_LEDGER}`,
	prepareHeaders: headers => {
		const access = sessionStorage.getItem("access")
		if (access) {
			headers.set("authorization", `Bearer ${access}`)
		}
		return headers
	},
})

// // FIXME: удалить захардкорженый Bearer
// export const unitBoxQuery = fetchBaseQuery({
//     baseUrl: process.env.REACT_APP_HUB_SERVER,
//     prepareHeaders: (headers) => {
//         headers.set('authorization', `Bearer ${process.env.REACT_APP_UNITBOX_BEARER}`)
//         return headers
//     }
// })

const queryFactory = (baseQuery: BaseQuery): ReturnedQuery => {
	return async (args, api, extraOptions) => {
		let result = await baseQuery(args, api, extraOptions)

		if (isAnonym()) return result
		
		if (result.error && result.error.status === 401) {
			const refresh = sessionStorage.getItem("refresh")
			const refreshResult = (await baseQuery(
				{
					url: `${process.env.REACT_APP_HUB_SERVER}/api/v1/auth/refresh/`,
					method: "POST",
					body: {
						refresh,
					},
				},
				api,
				extraOptions
			)) as { data: ITicket }

			if (refreshResult.data) {
				const { access, refresh } = refreshResult.data
				localStorage.setItem("access", access)
				localStorage.setItem("refresh", refresh)
				// retry the initial query
				result = await baseQuery(args, api, extraOptions)
			} else {
				// if (isAnonym()) {
				// 	console.log('redirect to auth')
				// 	window.location.href = "/auth"
				// }
			}
		}
		return result
	}
}

export const hubQueryWithReauth = queryFactory(hubBaseQuery)
export const gameQueryWithReauth = queryFactory(gameBaseQuery)
export const ledgerQueryWithReauth = queryFactory(ledgerBaseQuery)
