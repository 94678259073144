import { Modal } from "antd"
import { FC, useState } from "react"
import { MapsContainer, Map, Title, ContinueButton } from "./style"
import "./style.css"
import ChooseRoomImage from "../../../../../Assets/Images/ChooseRoom.png"

interface ChooseMapModalProps {
	isModalOpen: boolean
	setIsModalOpen: (mode: boolean) => void
	setActiveMap: (map_id: number) => void
	onClickContinue: () => void
	activeMap: number
	maps: {
		shortTitle: string;
		title: string;
		bg: string;
		map_id: string;
	}[]
}

const ChooseMapModal: FC<ChooseMapModalProps> = ({ isModalOpen, setIsModalOpen, maps, setActiveMap, activeMap, onClickContinue }) => {
	const handleOnClose = () => {
		setIsModalOpen(false)
	}

	const handleClickMap = (map_index: number) => {
		setActiveMap(map_index)
	}

	const handleClickContinue = () => {
		onClickContinue()
		handleOnClose()
	}

	return (
		<Modal
			title="Choose map"
			open={isModalOpen}
			onCancel={handleOnClose}
			className="choosemap"
			centered={true}
			style={{ borderRadius: "10px" }}
			footer={
				[
					// <Button onClick={connectAction} type="primary" style={{ backgroundColor: "purple", borderColor: "purple" }}>
					// 	<ConnectAnywayButton>Connect anyway</ConnectAnywayButton>
					// </Button>,
					// <Button onClick={handleOnClose} type="ghost">
					// 	<CloseButton>Close</CloseButton>
					// </Button>,
				]
			}
		>
			<MapsContainer>
				{maps.map((map, map_index) => (
					<Map bg={map.bg} active={map_index === activeMap} onClick={() => handleClickMap(map_index)} index={map_index}>
						<Title>{map.title}</Title>
					</Map>
				))}
				<ContinueButton onClick={handleClickContinue}>Continue</ContinueButton>
			</MapsContainer>
		</Modal>
	)
}

export default ChooseMapModal
