import styled from "styled-components"

export const Container = styled.div<{showGame: boolean}>`
	height: 100%;
	position: ${({showGame}) => showGame ? "relative" : "absolute"};

	> canvas {
		width: 100%;
		background: ${({ theme }) => theme.color.background.auth};
	}

	& .unity {
		opacity: ${({showGame}) => showGame ? 1 : 0};
		z-index: 3;
	}
`
