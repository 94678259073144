import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { Error } from "./types"
import { ledgerQueryWithReauth } from "./index"
import { EncodedMessage } from "./MatchmakingService"

interface Req {
	// game_token: EncodedMessage
	userId: string
}

interface LastGameRes {
	game_id: string
	user_id: string
	kills: number
	winner: boolean
	deaths: number
	slot: number
	character: string
	kdr: number
	damage: number
	winnerid: string
	avglifetime: number
	bet: string
	// otherUsers: LastGameRes[]
}

interface GameInfo {
	id: string
	room_id: string
	map_id: number
	time: string
	type: string
	multiplayer_schema: number[]
	start_at: string
	finish_at: string
	created_at: string
	updated_at: string
	sum_bets?: string
}

interface DailyStats {
	avglifetime: number
	damage: number
	deaths: number
	defeat: number
	game_played: number
	id: string
	kills: number
	victory: number
	winrate: number
}

export const ledgerAPI = createApi({
	reducerPath: "ledgerAPI",
	baseQuery: ledgerQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getLastGameStats: build.mutation<LastGameRes & { game: GameInfo }, Req>({
			query: body => ({
				url: "/public/statistic/game_stats?last=true",
				method: "POST",
				body,
			}),
		}),
		getGamesStats: build.mutation<any, Req>({
			query: body => ({
				url: "/public/statistic/games_stats",
				method: "POST",
				body,
			}),
		}),
		returnMoney: build.mutation<any, {accountName: string, hash: string}>({
			query: body => ({
				url: "/public/transactions/return_money",
				method: "POST",
				body,
			}),
		}),
		getDailyGamesStats: build.mutation<DailyStats, Req>({
			query: body => ({
				url: "/public/statistic/games_stats",
				method: "POST",
				params: {
					daily: true,
				},
				body,
			}),
		}),
		sendTimeWaitingMatch: build.mutation<any, any>({
			query: body => ({
				url: "/private/server_load/waiting_game_time",
				method: "POST",
				body,
			}),
		}),
		sendRetentionUsers: build.mutation<any, any>({
			query: body => ({
				url: "/private/server_load/retention_users",
				method: "POST",
				body,
			}),
		}),
		sendEmergencyErrorFront: build.mutation<any, {error: string, time: string}>({
			query: body => ({
				url: "/private/server_load/emergency_error_front",
				method: "POST",
				body,
			}),
		}),
		sendBugReport: build.mutation<any, {userId: string, text: string}>({
			query: body => ({
				url: "/private/server_load/bug_report",
				method: "POST",
				body,
			}),
		}),
	}),
})

export default ledgerAPI
export const { useGetGamesStatsMutation, useGetLastGameStatsMutation, useGetDailyGamesStatsMutation, useReturnMoneyMutation } = ledgerAPI
