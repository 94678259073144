import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const Container = styled.div`
	position: relative;
	transition: transform 0.3s ease-in-out;
	width: 484px;

	:hover {
		transform: translateX(50px);

		> div {
			cursor: default;
			color: #da2eaf;
		}
	}
`

export const Title = styled(Flex)`
	position: absolute;
	inset: 0;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-family: "Red Hat Display", sans-serif;
	font-size: 36px;
	line-height: 44px;

	transition: color 0.3s ease-in-out;

	@media screen and (max-width: 1440px) {
		font-size: 32px;
		line-height: 24px;
	}
`
