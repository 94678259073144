import { Colors } from "globalStyles"
import styled from "styled-components"

export const AccountWrap = styled.div`
	/* width: 100%; */
	height: calc(100% - 30px);
	display: grid;
	margin: 15px;
	grid-template-areas:
		"hero_panel dayli_missions  dayli_missions"
		"hero_panel your_nft your_nft"
		"start_game_panel your_progress your_progress"
		"start_game_panel server_status server_status";
	/* grid-template-columns: min(400px, 30vw) 12fr 7fr; */
	grid-template-columns: min(500px, 20vw) 50vw 7fr;
	grid-template-columns: clamp(540px, 340px, 28vw) 1fr;
	gap: 15px;

	@media (max-width: 1800px) {
		height: calc(100% - 24px);
		margin: 12px;
		gap: 12px;
		grid-template-columns: 24vw 1fr;
		/* grid-template-columns: clamp(440px, 320px, 28vw) 1fr; */
	}
`

export const LoaderWrap = styled.div`
	height: 100%;
	display: grid;
	place-items: center;
`

export const Market = styled.div`
	grid-area: market;
	position: relative;
	height: 100%;
	background: rgba(0, 83, 207, 0.34);
	backdrop-filter: blur(13.1378px);
	border-radius: 5px;
	text-align: end;
	cursor: pointer;
	img {
		position: absolute;
		bottom: 0;
		right: 0;
		-webkit-mask-image: linear-gradient(180deg, #d9d9d9 44.52%, rgba(217, 217, 217, 0) 100%);
		max-width: 100%;
		max-height: 100%;
		width: auto;
		height: auto;
	}
`

export const MarketTitle = styled.div`
	user-select: none;
	text-transform: uppercase;
	position: absolute;
	bottom: 20px;
	left: 20px;
	font-size: min(2.2vw, 32px);
	font-weight: 900;
	z-index: 3;
`

export const CommandCentre = styled.div`
	grid-area: command_centre;
	opacity: 0.5;

	& * {
		font-size: min(0.9vw, 16px);
	}
	& div:first-child {
		margin-top: 10px;
	}
	& div:not(:first-child) {
		margin-top: 16px;
	}
	@media (min-width: 1800px) {
		& div:first-child {
			margin-top: 15px;
		}
		& div:not(:first-child) {
			margin-top: 30px;
		}
	}
`

export const DailyMissions = styled.div`
	/* grid-area: dayli_missions; */
	/* margin-top: 15px; */
	/* width: 100%; */
	text-align: center;
`

export const DailyMissionsBlock = styled.div<{ complete: boolean }>`
	display: inline-block;
	background: ${({ complete }) => (complete ? "green" : "rgba(0, 83, 207, 0.34)")};
	backdrop-filter: blur(5px);

	padding: 15px 25px;

	margin: 5px 0px;

	z-index: 3;
	clip-path: polygon(calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 20px 50%, 0 0);
	/* clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0); */

	padding: 15px 40px 8px;

	&:first-child {
		clip-path: polygon(calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 0 0);
		padding: 15px 40px 8px;
	}

`
export const DailyMissionsValue = styled.div`
	text-align: center;

	display: grid;
	grid-auto-flow: column;

	z-index: 3;

	font-weight: 700;
	
	@media (max-width: 1800px) {
		font-size: 35px;
		line-height: 20px;
		& div {
			display: block;
			margin-top: -3px;
			font-size: 35px;
		}
	}
	@media (min-width: 1800px) {
		font-size: 35px;
		line-height: 20px;
		& div {
			display: block;
			margin-top: -3px;
			font-size: 35px;
		}
	}
	@media (max-width: 1350px) {
		font-size: 35px;
		line-height: 20px;
		& div {
			display: block;
			margin-top: -3px;
			font-size: 35px;
		}
	}
	@media (max-width: 1150px) {
		font-size: 35px;
		line-height: 20px;
		& div {
			display: block;
			margin-top: -3px;
			font-size: 35px;
		}
	}
`

export const DailyMissionsDescription = styled.div`
	margin-top: 10px;
	text-align: center;
	font-size: 18px;
	/* font-size: min(0.8vw, 18px); */
	font-weight: 500;
	z-index: 3;
	@media (max-width: 1800px) {
		font-size: 14px;
	}
	@media (max-width: 1350px) {
		font-size: 12px;
	}
	@media (max-width: 1150px) {
		font-size: 11px;
	}
`

export const ServerStatus = styled.div`
	grid-area: server_status;
	position: relative;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	grid-template-columns: repeat(6, max-content);
	border-radius: 5px;
	column-gap: 30px;
	background-color: rgba(0, 0, 0, 0.4);
	padding: 5px 15px;
	backdrop-filter: blur(4px);

	& svg {
		opacity: 0.5;
	}
	user-select: none;
`

export const ServerStatusTitle = styled.div`
	display: inline-block;
	text-transform: uppercase;
	font-size: 21px;
	font-weight: 900;
	height: 50px;
	line-height: 50px;
	letter-spacing: 1px;
`

export const ServerStatusOnline = styled.div`
	display: inline-block;
	background-color: #68ff5b;
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;
	padding: 2px 7px;
	letter-spacing: 3px;
	border-radius: 5px;
`

export const YourProgress = styled.div`
	display: grid;
	place-items: center;
	place-content: center;
	/* grid-template-columns: 1fr 1fr; */
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	width: 100%;
	margin-top: 5px;
	gap: 3px;
`

export const YourProgressItem = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr;
	place-items: center;
	line-height: 15px;
`
export const YourProgressName = styled.div`
	justify-self: start;

	text-transform: uppercase;
	font-size: 15px;
	font-weight: 900;
	letter-spacing: 2px;
`
export const YourProgressValue = styled.div`
	color: #00f0ff;

	text-transform: uppercase;
	font-size: 18px;
	font-weight: 900;
	letter-spacing: 2px;
`

//

export const AccoutContent = styled.div``

// general info start

export const GeneralInfo = styled.div`
	display: grid;
	grid-auto-flow: column;

	padding: 50px;
	text-align: center;

	border-bottom: 1px solid ${Colors.lightBlue};

	& img {
		width: 150px;
		height: 150px;
		background-color: ${Colors.cardBG};
		border: 1px solid ${Colors.cardStroke};
		border-radius: 10px;

		margin-right: 35px;
	}
`

export const Info = styled.div`
	display: grid;
	/* height: max-content; */
	box-sizing: border-box;
	grid-template-rows: 40% 1fr;
	overflow: hidden;
`

export const Stats = styled.div`
	border-image: linear-gradient(266deg, rgba(5, 84, 209, 0) 0%, #0554d1 43.23%, rgba(5, 84, 209, 0.899) 53.65%, rgba(5, 84, 209, 0) 100%);
	border-width: 2px 0;
	border-style: solid;
	border-image-slice: 1;
	border-radius: 10px;
	overflow: hidden;

	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: minmax(0, 1fr);
	place-items: center;

	column-gap: 50px;

	background-color: ${Colors.cardBG};
	/* padding: 8px 0 10px; */
`

export const Stat = styled.div`
	text-align: center;
	// display: grid;
`

export const StatNumber = styled.div`
	font-size: 36px;
	font-weight: 700;
`

export const StatDescription = styled.div`
	font-size: 12px;
	font-weight: 500;
	color: ${Colors.lightBlue};
`

// general info end
// nft start
// export const MyNFTs = styled.div`
// 	margin: 50px 0;
// 	border-bottom: 1px solid ${Colors.lightBlue};
// 	padding-bottom: 40px;
// `

// export const Title = styled.div`
// 	font-size: 28px;
// 	font-weight: 500;
// `

// export const NFTCards = styled.div`
// 	margin-top: 20px;

// 	display: grid;
// 	justify-items: center;
// 	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

// 	column-gap: 20px;
// 	row-gap: 30px;
// `

// export const NFTCard = styled.div`
// 	background-color: ${Colors.cardBG};
// 	border-radius: 10px;
// 	padding: 20px;

// 	& img {
// 		width: 100%;
// 	}
// `
// nft end

// statistic start

export const Statistics = styled.div``

export const SubTitle = styled.div`
	font-weight: 400;
	font-size: 18px;
	color: ${Colors.lightBlue};
	margin-top: 20px;
`

export const StatLines = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;

	margin-top: 10px;
	column-gap: 40px;
`

export const StatLine = styled.div`
	border-image: linear-gradient(266deg, rgba(5, 84, 209, 0) 0%, #0554d1 43.23%, rgba(5, 84, 209, 0.899) 53.65%, rgba(5, 84, 209, 0) 100%);
	border-width: 0 0 1px 0;
	border-style: solid;
	border-image-slice: 1;

	display: grid;
	height: 80px;
	grid-template-columns: 13% 1fr 13%;

	text-align: center;
	align-items: center;
	justify-items: start;

	& img {
		width: 50px;
		height: 50px;
	}
`

export const StatName = styled.div`
	font-weight: 600;
	font-size: 16px;
	justify-self: start;
`
export const StatCount = styled.div`
	font-weight: 700;
	font-size: 20px;
`

// statictic end

export const HelpTextWrap = styled.div`
	display: grid;
	place-items: center;
	height: 100%;
`
export const HelpTextConteiner = styled.div`
	text-align: center;
	padding: 40px;
	backdrop-filter: blur(5px);
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 15px;
`
export const HelpText = styled.div`
	margin-top: 30px;
	font-weight: 700;
	font-size: 35px;
	letter-spacing: 1px;
`


export const ServersPings = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: 20px;
`

export const ServersPing = styled.div<{selected: boolean}>`
	display: grid;
	grid-auto-flow: column;
	column-gap: 10px;
	border-radius: 10px;
	padding: 5px 10px;
	background-color: ${({selected}) => selected ? "purple" : "transparent" };
	&:hover {
		background-color: purple;
	}
	cursor: pointer;
`