import styled from "styled-components"
// import {Button} from '../../../Components/Atoms/Wallet/GradientButton/styles';
import { Button } from "Components/Atoms/Common"
import { Flex } from "../../../globalStyles"
import { LoadingOutlined } from "@ant-design/icons"

export const Container = styled.div`
	display: grid;
	/* justify-content: center; */
	align-items: center;

	width: 100%;
	/* height: 100%; */

	position: relative;
	z-index: 2;
	overflow: hidden;

	padding: 10px;
	margin-top: 20px;
`

// export const ContinueButton = styled(Button)`
//     margin-top: 40px;
//     margin-right: 0;
//     margin-left: auto;
//     width: 200px;
// `
export const ContinueButton = styled(Button)`
	height: 65px;
	margin-top: 30px;
	width: 250px;
	justify-self: right;
`

export const CancelButton = styled(Button)`
	/* margin-top: 40px; */
	width: min-content;
	padding: 20px 30px;
	/* font-size: 5px; */
	line-height: 0px;
	& span {
	}
`

export const Loader = styled(Flex)`
	/* position: absolute; */
	inset: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> div {
		margin-top: 30px;
		font-family: "Red Hat Display", sans-serif;
		font-size: 36px;
	}
`

export const LoaderSpin = styled(LoadingOutlined)`
	margin-top: 15px;

	svg {
		font-size: 60px;
		fill: white;
	}
`
