import React from 'react';
import {Container, MapsContainer} from './styles';
import SelectedMapsTitle from '../../../Atoms/Matchmaking/SelectedMapsTitle';
import SelectedMap from '../../../Molecules/Matchmaking/SelectedMap';

function SelectedMaps() {
    return (
        <Container>
            <SelectedMapsTitle text="selected maps" />
            <MapsContainer>
                <SelectedMap />
                <SelectedMap />
                <SelectedMap />
                <SelectedMap />
            </MapsContainer>
        </Container>
    );
}

export default SelectedMaps;
