import styled from "styled-components"
import { Flex } from "globalStyles"

export const Overlay = styled(Flex)<{ visible: boolean }>`
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: ${({ visible }) => (visible ? 10 : -1)};
	opacity: ${({ visible }) => (visible ? 1 : 0)};
	width: 100%;
	height: 100%;
	background: rgba(4, 12, 30, 0.7);
`

export const Container = styled.div`
	padding: 20px;
	background: #021136;
	border-radius: 12px;
	min-width: 435px;
`

export const Close = styled(Flex)`
	align-items: center;
	justify-content: end;

	> svg {
		:hover {
			cursor: pointer;
		}
	}
`
