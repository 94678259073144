import styled from "styled-components"

export const NFTCards = styled.div`
	/* display: grid;
	grid-template-columns: repeat(auto-fill, minmax(60px, 140px)); */
	/* grid-template-columns: 1fr 1fr 1fr; */
	gap: 20px;
	/* margin: 0 auto; */
	/* text-align: center; */
	/* margin-top: 5px; */
	padding: 2px 5px;

	/* max-width: calc((140px + 10px) * 4.2); */
	max-width: 100%;
	& > div {
		width: 180px;
		margin-right: 10px;
		display: inline-block;
		white-space: normal;
		text-align: left;
	}
	@media (min-width: 1800px) {
		/* grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); */
		/* max-width: calc((170px + 10px) * 4.8); */
		/* max-width: 100%; */
		& > div {
			width: 220px;
			margin-right: 10px;
			display: inline-block;
			white-space: normal;
		}
	}
	@media (max-width: 1350px) {
		/* grid-template-columns: repeat(auto-fill, minmax(80px, 1fr)); */
		/* max-width: calc((140px + 10px) * 2.5); */
		/* max-width: calc((140px + 10px) * 2.5); */
		& > div {
			width: 140px;
			margin-right: 10px;
			display: inline-block;
			white-space: normal;
		}
	}

	scrollbar-width: thin; /* "auto" или "thin"  */
	scrollbar-color: grey transparent; /* плашка скролла и дорожка */

	&::-webkit-scrollbar {
		height: 7px; /* ширина scrollbar */
	}
	&::-webkit-scrollbar-track {
		background: transparent; /* цвет дорожки */
	}
	&::-webkit-scrollbar-thumb {
		background-color: blue; /* цвет плашки */
		border-radius: 20px; /* закругления плашки */
		border: 10px solid grey; //padding вокруг плашки
	}

	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
`

const NFTCardTemplate = styled.div`
	position: relative;
	/* background: rgba(0, 0, 0, .1); */
	backdrop-filter: blur(5px);
	padding: 20px;
	border-radius: 5px;
	/* transition: 0.3s all ease; */
	cursor: pointer;

	@media (max-width: 1800px) {
		padding: 10px;
	}

	/* outline: 4px solid black; */
	&:hover {
		outline: 1px solid blue;
	}

	& > p {
		font-size: 80px;
		position: absolute;
		top: 15px;
		/* margin-left: auto;
		margin-right: auto; */
		left: 0;
		right: 5px;
		text-align: center;
		@media (min-width: 1800px) {
			top: 10px;
		}
	}
`

export const NFTCard = styled(NFTCardTemplate)<{ isActive?: boolean; pulse?: boolean }>`
	&::after {
		content: "ACTIVE";
		position: absolute;
		display: ${({ isActive }) => (isActive ? "block" : "none")};
		top: 5px;
		left: 10px;
		color: #68ff5b;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
	}

	background-color: black;
	border-radius: 4px;

	outline: ${({ isActive }) => (isActive ? "1px solid #272727" : "none")};
`

export const DefaultNFTCard = styled(NFTCard)`
	&::before {
		content: "DEFAULT";
		position: absolute;
		top: 5px;
		right: 5px;
		color: white;
		font-size: 12px;
		font-weight: 700;
		letter-spacing: 1px;
		padding: 0px 3px;
		border-radius: 2px;
	}
`

export const NFTRentText = styled.div`
	font-size: 12px;
	font-weight: 400;
	/* white-space: normal; */
`

export const NFTImage = styled.div`
	text-align: center;
	& img {
		width: 80%;
		margin: 3px 0;
		@media (min-width: 1800px) {
			margin-top: -10px;
		}
	}
	& svg {
		width: 70%;
		/* height: 120px; */
		margin-top: 15px;
		@media (min-width: 1800px) {
			margin-top: 0;
		}
	}
`

export const NFTName = styled.div`
	font-size: 14px;
	font-weight: 900;
	text-transform: uppercase;
	letter-spacing: 1px;
`

export const NFTAttributes = styled.div`
	font-size: 14px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr;
	column-gap: 15px;
	row-gap: 6px;
	margin-top: 5px;
	div:nth-child(2) {
		background-color: #ee2a7b;
	}
	div:nth-child(4) {
		background-color: #009ca6;
	}
	div:nth-child(6) {
		background-color: #ff6b00;
	}
`

export const NFTAttribute = styled.div``

export const NFTAttributeType = styled.div`
	line-height: 15px;
	font-size: 12px;
	@media (max-width: 1800px) {
		font-size: 11px;
	}
`
export const NFTAttributeValue = styled.div<{ value: number }>`
	height: 14px;
	width: ${props => props.value * 10}%;

	padding: 0 3px;

	font-weight: 700;
	font-size: 14px;
	line-height: 14px;

	border-radius: 2px;
	background-color: red;

	text-shadow: -1px 0 rgba(0, 0, 0, 0.5), 0 1px rgba(0, 0, 0, 0.5), 1px 0 rgba(0, 0, 0, 0.5), 0 -1px rgba(0, 0, 0, 0.5);
	color: white;
	/* color: black; */
`
