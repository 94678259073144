import ProgressBar from "Components/Atoms/Auth/ProgressBar/ProgressBar"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getAccountNameFromSS } from "store/reducers/auth/NearSlice"
import { getQueryParameterByName } from "utils/getQueryParameterByName"
import { SuccessfullyText, Container, Wrapper } from "./style"

const timeout_fn = async (ms: number) => {
	return new Promise(res => setTimeout(res, ms))
}

const NearSuccessfully = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [progress, setProgress] = useState(75)

	const startSmoothProgress = async () => {
		// плавное заполнение полосы прогресса (фейк)
		for (let i = 0; i < 25; i++) {
			// после 90% шаг равен секунде
			const timeout = i < 15 ? i ** 2 + 100 : 1000
			setTimeout(() => setProgress(prev => ++prev), timeout)
			await timeout_fn(timeout)
		}
	}

	useEffect(() => {
		const accountName = getQueryParameterByName("account_id")
		if (accountName) {
			startSmoothProgress()
		} else {
			// navigate("/auth/near/failure")
		}
	}, [])

	return (
		<Wrapper>
			<Container>
				<SuccessfullyText>
					<div>Authorization was successful</div>
					<div>Saving data . . . </div>
					<ProgressBar mt={50} importantValue={progress} />
				</SuccessfullyText>
			</Container>
		</Wrapper>
	)
}

export default NearSuccessfully
