import React, {ChangeEvent, useEffect, useState} from 'react';
import {useGetMeQuery, useUpdateUserMutation} from 'services/UserService';
import EnterNickname from 'Components/Molecules/Profile/EnterNickname';
import SelectIcon from 'Components/Molecules/Profile/SelectIcon';
import GradientButton from 'Components/Atoms/Wallet/GradientButton';
import {Container, StatusContainer} from './styles';

interface IProps {
    closePopup: () => void
}

function UpdateUser({ closePopup }: IProps) {
    const { data, refetch } = useGetMeQuery()
    const [updateUser, { isSuccess: updated, isLoading, isError }] = useUpdateUserMutation()
    const [newNickname, setNewNickname] = useState<string>(data?.nickname || '')
    const [newAvatar, setNewAvatar] = useState<string>(data?.icon || '')

    useEffect(() => {
        if (updated) {
            refetch()
        }
    }, [refetch, updated])

    const onChangeNickname = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const { value } = target
        setNewNickname(value.replace(/[^a-zA-Z\d]/ig, ''))
    }

    const onSendUpdate = () => updateUser({
        nickname: newNickname,
        icon: newAvatar
    })

    return (
        <Container>
            {
                !updated && !isLoading && !isError && (
                    <>
                        <EnterNickname value={newNickname} onInput={onChangeNickname}/>
                        <SelectIcon checked={newAvatar} onCheck={(icon) => setNewAvatar(icon)}/>
                        <GradientButton height={45} onClick={onSendUpdate}>Continue</GradientButton>
                    </>
                )
            }
            {
                isLoading && (
                    <span>Loading...</span>
                )
            }
            {
                updated && (
                    <StatusContainer>
                        <span>Updated!</span>
                        <GradientButton height={45} onClick={() => closePopup()}>Okay</GradientButton>
                    </StatusContainer>
                )
            }
            {
                isError && (
                    <StatusContainer>
                        <span>Oops, some wrongs :/</span>
                        <GradientButton height={45} onClick={onSendUpdate}>Try again</GradientButton>
                    </StatusContainer>
                )
            }
        </Container>
    )
}

export default UpdateUser;
