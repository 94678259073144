import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ICoinsNames } from "../../models/ICoin"

interface IState {
	from: ICoinsNames
	to: ICoinsNames
	rate: number | null
}

const initialState: IState = {
	from: null,
	to: null,
	rate: null,
}

export const exchangeSlice = createSlice({
	name: "exchange",
	initialState,
	reducers: {
		setFrom(state, action: PayloadAction<ICoinsNames>) {
			state.from = action.payload
		},
		setTo(state, action: PayloadAction<ICoinsNames>) {
			state.to = action.payload
		},
		reset(state) {
			state.from = null
			state.to = null
			state.rate = null
		},
	},
})

export const { setFrom, setTo, reset } = exchangeSlice.actions

export default exchangeSlice.reducer
