import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { hubQueryWithReauth } from "./index"
import { Error } from "./types"
import { GetAssetByIdResponse } from "../models/IAsset"

export const assetAPI = createApi({
	reducerPath: "assetAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getAssetById: build.query<GetAssetByIdResponse, string>({
			query: asset => ({
				url: `/wallet/assets/${asset}`,
			}),
		}),
	}),
})

export const { useGetAssetByIdQuery, useLazyGetAssetByIdQuery } = assetAPI
