import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { Error } from "./types"
import { hubQueryWithReauth } from "./index"
import { ICoin, ICoinsNames } from "../models/ICoin"
import { GetAssetByIdResponse } from "../models/IAsset"
import { EncodedMessage } from "./MatchmakingService"

interface GetResponse {
	coins: ICoin[]
	assets: GetAssetByIdResponse[]
	message: EncodedMessage
}

interface MakeExchangeBody {
	from: ICoinsNames
	to: ICoinsNames
	input: "from" | "to"
	amount: string
}

export const walletAPI = createApi({
	reducerPath: "walletAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getWallet: build.query<GetResponse, void | null>({
			query: () => ({
				url: "/wallet",
			}),
			keepUnusedDataFor: 5,
		}),
		createWallet: build.mutation<void, void>({
			query: () => ({
				url: "/wallet",
				method: "POST",
			}),
		}),
		makeExchange: build.mutation<any, MakeExchangeBody>({
			query: body => ({
				url: "/wallet/exchange",
				method: "POST",
				body,
			}),
		}),
	}),
})

export default walletAPI
export const { useGetWalletQuery, useCreateWalletMutation, useMakeExchangeMutation } = walletAPI
