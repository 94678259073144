import styled from "styled-components"

export const Wrapper = styled.div`
	display: grid;
	height: 100vh;
	place-items: center;
`

export const Container = styled.div`
    padding: 50px;
	border-radius: 25px;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(6px) opacity(0.9);
    width: 60vw;
`

export const SuccessfullyText = styled.div`
	text-align: center;
`
