import AuthLayout from "Components/Layouts/AuthLayout/Layout"
import GamesLayout from "Components/Layouts/GamesLayout/Layout"
import NearSuccessfully from "Pages/Auth/Near/NearConnect/NearSuccessfully"
import NotFound from "Pages/Auth/NotFound/NotFound"
import NearReturnMoney from "Pages/Near/NearReturnMoney"
import { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"

const NearRoutes = () => {
	const location = useLocation()

	useEffect(() => {
		console.log(location.pathname)
	}, [location])

	return (
		<Routes>
			<Route element={<GamesLayout />}>
				<Route path="return_money" element={<NearReturnMoney />} />
				<Route path="successfully" element={<NearSuccessfully/>}/>
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}
export default NearRoutes
