import React from 'react';
import {Button} from './styles';

interface IGradientButton {
    onClick?: () => void
    children?: React.ReactNode
    disabled?: boolean
    height?: number
}

const GradientButton:React.FC<IGradientButton> = (props) => {
    const {
        onClick,
        children,
        disabled,
        height = 65
    } = props;

    return (
        <Button disabled={disabled} onClick={onClick} height={height}>
            {children}
        </Button>
    )
}

export default GradientButton;
