import React from 'react';
import MatchmakingHeader from '../../../Components/Organisms/Matchmaking/MatchmakingHeader';
import SelectedMaps from '../../../Components/Organisms/Matchmaking/SelectedMaps';
import GameModes from '../../../Components/Organisms/Matchmaking/MatchmakingFooter';
import { Container } from './styles';

function Matchmaking() {
    return (
        <Container>
            <MatchmakingHeader />
            <SelectedMaps />
            <GameModes />
        </Container>
    );
}

export default Matchmaking;
