import { FakeNFT } from "Components/Organisms/Profile/Account"
import { savedNFTs } from "models/savedNFTs"
import React, { FC } from "react"
import { getIntervalValues } from "utils/getDiapazon"
import { NFTCards, DefaultNFTCard, NFTImage, NFTName, NFTAttributes, NFTAttributeType, NFTAttributeValue, NFTCard } from "./style"
import { MetaData } from "store/reducers/WNFTSlice"
import { useAppSelector } from "hooks/redux"

interface NFTCardsComponentProps {
	handleSetNFT: (nftName: string) => void
}

const NFTCardsComponent: FC<NFTCardsComponentProps> = ({ handleSetNFT }) => {
	const { isСhecked: tonIsChecked, isLoading: tonIsLoading, account: tonAccount, assets: tonAssets } = useAppSelector(state => state.ton)
	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)

	return (
		<NFTCards>
			<DefaultNFTCard onClick={() => handleSetNFT(FakeNFT.metadata.name)} isActive={selectedNFT?.wNFTTokenId === FakeNFT.wNFTTokenId}>
				<NFTImage>
					<img src={FakeNFT.metadata.image} alt="" />
				</NFTImage>
				<NFTName>{FakeNFT.metadata.name}</NFTName>
				<NFTAttributes>
					{FakeNFT.metadata.attributes.map((attr, index) => (
						<React.Fragment key={index}>
							<NFTAttributeType>{attr.trait_type}</NFTAttributeType>
							<NFTAttributeValue value={getIntervalValues(attr.min_value, attr.max_value, attr.value)}>
								{getIntervalValues(attr.min_value, attr.max_value, attr.value)}
							</NFTAttributeValue>
						</React.Fragment>
					))}
				</NFTAttributes>
			</DefaultNFTCard>
			{tonIsChecked ? (
				<>
					{tonIsChecked && tonAccount
						? tonAssets?.content.map((nft, index) => (
								<NFTCard
									// onClick={() => handleSetNFT(nft)}
									onClick={() => handleSetNFT(nft.name)}
									isActive={selectedNFT?.metadata.name?.toLocaleLowerCase() === String(nft.name).toLocaleLowerCase()}
									key={index}
								>
									<NFTImage>
										<img src={nft.image} alt="" />
									</NFTImage>
									<NFTName>{nft.name}</NFTName>
									<NFTAttributes>
										{JSON.parse(nft.properties.attributes).map(
											(
												attr: { trait_type: string; value: number; max_value: number; min_value: number },
												index: number
											) => (
												<React.Fragment key={index}>
													<NFTAttributeType>{attr.trait_type}</NFTAttributeType>
													<NFTAttributeValue value={getIntervalValues(attr.min_value, attr.max_value, attr.value)}>
														{getIntervalValues(attr.min_value, attr.max_value, attr.value)}
													</NFTAttributeValue>
												</React.Fragment>
											)
										)}
									</NFTAttributes>
								</NFTCard>
						  ))
						: savedNFTs.map((nft, index) => (
								<NFTCard
									onClick={() => handleSetNFT(nft.metadata.name)}
									pulse={!selectedNFT}
									isActive={selectedNFT?.wNFTTokenId === nft.wNFTTokenId}
									key={index}
								>
									<NFTImage>
										<img src={nft.metadata.image} alt="" />
									</NFTImage>
									<NFTName>{nft.metadata.name}</NFTName>
									<NFTAttributes>
										{nft.metadata.attributes.map((attr, index) => (
											<React.Fragment key={index}>
												<NFTAttributeType>{attr.trait_type}</NFTAttributeType>
												<NFTAttributeValue value={getIntervalValues(attr.min_value, attr.max_value, attr.value)}>
													{getIntervalValues(attr.min_value, attr.max_value, attr.value)}
												</NFTAttributeValue>
											</React.Fragment>
										))}
									</NFTAttributes>
								</NFTCard>
						  ))}
				</>
			) : (
				<></>
			)}
			{/* <NFTCard pulse={!selectedNFT} onClick={() => window.open(process.env.REACT_APP_UNITBOX_URL || "")}>
							<NFTImage>
								<RentNFT />
							</NFTImage>
							<p>+</p>
							<NFTName>rent nft</NFTName>
							<NFTRentText>Try new characters, <br /> weapons and abilities<br /> risk free</NFTRentText>
						</NFTCard> */}
		</NFTCards>
	)
}

export default NFTCardsComponent
