import React, { useEffect, useState } from "react"
import MatchmakingStatistics from "../../../Components/Organisms/Matchmaking/MatchmakingStatistics"
import MatchmakingResultLogo from "../../../Components/Organisms/Matchmaking/MatchmakingResultLogo"
import { QubixLogo } from "Assets/Icons/Matchmaking"
import { Container, MatchmakingResultHeader, Buttons, MainMenuBtn, Wrapper } from "./styles"
import { useNavigate } from "react-router-dom"
import { useGetLastGameStatsMutation } from "services/LedgerService"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { gameSlice } from "store/reducers/GameSlice"
import { useGetMeQuery } from "services/UserService"
import { is } from "immer/dist/internal"
import { isArcanaUser, isNearUser } from "utils/checkUser"
import { formatNearAccountNameForMM } from "utils/formatNearAccountNameForMM"
import { getAccountNameFromSS } from "store/reducers/auth/NearSlice"
import MatchmakingWinBet from "Components/Organisms/Matchmaking/MatchmakingWinBet"

function MatchmakingResult() {
	const navigator = useNavigate()
	const dispatch = useAppDispatch()
	const { data: me, isSuccess: meDataIsSuccess, isError: meDataIsError, isLoading: meDataIsLoading } = useGetMeQuery()
	// const { user: arcanaUser } = useAppSelector(state => state.arcana)

	const game_token = JSON.parse(sessionStorage.getItem("game_token")!)
	const [getStats, { data, isLoading, isSuccess, isError, startedTimeStamp }] = useGetLastGameStatsMutation({
		fixedCacheKey: "after_game",
	})
	const { account: tonAccount, isСhecked: tonIsChecked } = useAppSelector(state => state.ton)

	const mainMenuButtonHandler = () => {
		navigator("/profile")
	}

	const playAgainButtonHandler = async () => {
		await dispatch(gameSlice.actions.startMatchmaking())
		navigator("/games/infinity", {
			state: {
				playAgain: true,
			},
		})
	}

	useEffect(() => {
		const arcanaUserId = sessionStorage.getItem("arcanaUserId")

		if (!tonIsChecked) return
		if (tonIsChecked && tonAccount) {
			const player_id = "ton_" + tonAccount?.decoded_jwt?.username?.replaceAll(".", "_dot_")
			getStats({ userId: player_id })
			return
		}
		if (isArcanaUser() && arcanaUserId) {
			// getStats({ userId: arcanaUserId || "arcana_" + arcanaUser?.address })
			return
		}
		if (isNearUser()) {
			console.log("NEAR")
			const nearAccountName = getAccountNameFromSS()
			if (nearAccountName) {
				getStats({ userId: formatNearAccountNameForMM(`near_${nearAccountName}`) })
			} else {
				console.error("не найдено название кошелька в session storage")
			}
			return
		}
		if (meDataIsSuccess && me) {
			getStats({ userId: me.id })
			return
		}

		const anonymId = localStorage.getItem("anonymUserId")
		// console.log({ anonymId })
		if (anonymId) {
			getStats({ userId: anonymId })
			return
		}

		// if (meDataIsLoading) return
		// if (meDataIsError) {
		// 	const anonymId = localStorage.getItem("anonymUserId")
		// 	console.log({ anonymId })
		// 	if (anonymId) {
		// 		getStats({ userId: anonymId })
		// 		return
		// 	}
		// }
		console.log("redirect1")
		navigator("/profile")
	}, [meDataIsSuccess, meDataIsError, meDataIsLoading, tonIsChecked, tonAccount])

	console.log({ data })
	console.log({ isSuccess })
	console.log({ isError })
	console.log({ isLoading })
	console.log({ startedTimeStamp })
	if (!startedTimeStamp || isLoading) {
		return <div>Loading</div>
	}

	console.log({ iff: startedTimeStamp && !data && isError, isArcanaUser: isArcanaUser() })
	if (startedTimeStamp && !data && isError) {
		console.log({ data })
		console.log({ isSuccess })
		console.log(new Date().toLocaleTimeString())
		console.log("redirect2")
		navigator("/profile")
		return <></>
	}

	if (!data) {
		console.log("redirect3")
		navigator("/profile")
		return <></>
	}

	const isGameWithBet = (bet: string): boolean => {
		if (bet === "none") return false
		return true
	}

	console.log({ last_game_stats: data })
	return (
		<Wrapper victory={data.winner || data.user_id === data.winnerid}>
			<Container>
				{/* <MatchmakingResultHeader>
                <QubixLogo/>
            </MatchmakingResultHeader> */}
				<MatchmakingResultLogo victory={data.winner || data.user_id === data.winnerid} />
				{isGameWithBet(data.bet) && (
					<MatchmakingWinBet bet={data.bet} win={data.game.sum_bets} winner={data.winner || data.user_id === data.winnerid} />
				)}
				<MatchmakingStatistics
					stats={{
						kills: data.kills,
						damage: data.damage,
						deaths: data.deaths,
						avglifetime: data.avglifetime,
					}}
				/>
				<Buttons>
					<MainMenuBtn onClick={mainMenuButtonHandler}>main menu</MainMenuBtn>
				</Buttons>
			</Container>
		</Wrapper>
	)
}

export default MatchmakingResult
