import { Outlet, useNavigate } from "react-router-dom"
import ProgressBar from "../../Atoms/Auth/ProgressBar/ProgressBar"
import { AuthContainer, AuthContentBlock, AuthWrapper } from "./styles"

function AuthLayout() {
	const navigate = useNavigate()
	const navigateToProfile = () => {
		navigate("/profile")
	}

	return (
		<AuthWrapper>
			<AuthContainer>
				<AuthContentBlock>
					<Outlet />
					<ProgressBar mt={40} />
				</AuthContentBlock>
			</AuthContainer>
		</AuthWrapper>
	)
}

export default AuthLayout
