import React from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import GamesLayout from "../Components/Layouts/GamesLayout/Layout"
import Games from "../Pages/Games/Choose"
import NotFound from "../Pages/Auth/NotFound/NotFound"
import InfinityPage from "../Pages/Games/Infinity"
import MatchmakingResult from "../Pages/Games/MatchmakingResult"
import MatchmakingLayout from "../Components/Layouts/MatchmakingLayout"
import ProfileLayout from "Components/Layouts/ProfileLayout/Layout"
import GameError from "Pages/Games/Error"

const GamesRoutes = () => {
	return (
		<Routes>
			<Route element={<GamesLayout />}>
				{/* <Route element={<ProfileLayout/>}> */}
				{/* <Route index element={<Games />}/> */}
				<Route index element={<Navigate to={"/profile"} />} />
				<Route path="/infinity" element={<InfinityPage />} />
			</Route>
			<Route path="/result" element={<MatchmakingLayout />}>
				<Route index element={<MatchmakingResult />} />
			</Route>
			<Route path="/error" element={<MatchmakingLayout />}>
				<Route index element={<GameError/>} />
			</Route>
			<Route path="*" element={<NotFound />} />
		</Routes>
	)
}

export default GamesRoutes
