import { FakeNFT } from "Components/Organisms/Profile/Account"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { savedNFTs } from "models/savedNFTs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { authToNear, getAccountNameFromSS, nearSlice, sendMoneyToCommonWallet, setTransaction } from "store/reducers/auth/NearSlice"
import { addNFT, gameSlice } from "store/reducers/GameSlice"
import { formatNearAccountNameForMM } from "utils/formatNearAccountNameForMM"
import { getQueryParameterByName } from "utils/getQueryParameterByName"
import { Wrapper, Container, ContentBlock, Body } from "./style"
import { useWalletSelector } from "utils/near/wallet"
import { ConvertNearToYoctoNear } from "utils/nearUtils"
import { notification } from "antd"

const PlaceBetLayout = ({ children }: { children: any }) => {
	return (
		<>
			<Wrapper>
				<Container>
					<ContentBlock>{children}</ContentBlock>
				</Container>
			</Wrapper>
		</>
	)
}

const PlaceBet = () => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	const [state, setState] = useState<string>("")
	const { modal, accounts, selector, accountId } = useWalletSelector()
	const {
		preferences: { nft: selectedNFT },
	} = useAppSelector(state => state.game)
	const { account, bet } = useAppSelector(state => state.nearReducer)

	const setNftForNear = () => {
		const nftName = localStorage.getItem("nftName")
		const nft = savedNFTs.find(nft => nft.metadata.name === nftName)
		const nearAccountNameFromSS = getAccountNameFromSS()
		if (nearAccountNameFromSS) {
			if (nft) {
				console.log("set nft", nft)
				dispatch(
					addNFT({ ...nft, metadata: { ...nft.metadata, player_id: "near_" + formatNearAccountNameForMM(nearAccountNameFromSS) } })
				)
			} else {
				dispatch(
					addNFT({
						...FakeNFT,
						metadata: { ...FakeNFT.metadata, player_id: "near_" + formatNearAccountNameForMM(nearAccountNameFromSS) },
					})
				)
			}
		}
	}

	const onSuccess = async (hash: string) => {
		setState("success")

		// чтобы в ссылке не висел хэш транзакции
		await new Promise(res => {
			console.log("promise")
			navigate("/place_a_bet")
			setTimeout(() => {
				res({})
			}, 500)
		})

		await dispatch(setTransaction({ hash }))

		setNftForNear()

		await dispatch(gameSlice.actions.startMatchmaking())
		navigate("/games/infinity", { state: { afterTransaction: true } })
	}

	const sendMoneyFromNC = async () => {
		try {
			const protocol = process.env.NODE_ENV === "production" ? "https://" : "http://"
			const wallet = await selector.wallet()
			await wallet.signAndSendTransaction({
				actions: [
					{
						type: "FunctionCall",
						params: {
							methodName: "send",
							args: { account: { accountId: "qubix_beta.testnet" } },
							gas: "30000000000000",
							deposit: ConvertNearToYoctoNear(bet!)!,
						},
					},
				],
				callbackUrl: protocol + window.location.host + "/place_a_bet",
			}).then((data: any) => {
				console.log({data})
				if (data?.transaction?.hash) {
					window.location.href = "/place_a_bet?transactionHashes=" + encodeURIComponent(data?.transaction?.hash)
				} else {
					const encodedError = encodeURIComponent("Invalid transaction")
					window.location.href = "/profile?infotext=" + encodedError
				}
			}).catch((error: any) => {
				console.error(error)
				try {
					const encodedError = encodeURIComponent(error)
					window.location.href = "/profile?infotext=" + encodedError
				} catch (error: any) {
					window.location.href = "/profile"
				}

			})
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		setNftForNear()

		const transactionHashes = getQueryParameterByName("transactionHashes")
		const errorCode = getQueryParameterByName("errorCode")
		const errorMessage = getQueryParameterByName("errorMessage")
		if (errorCode || errorMessage) {
			navigate("/profile")
			return
		}
		if (transactionHashes) {
			console.log({ transactionHashes })
			onSuccess(transactionHashes)
			return
		}
		if (!account) {
			// не понимаю как работает при редиректе после оплаты
			// navigate("/profile")
			notification.info({ message: "No account" })
		}
		setState("send")
		sendMoneyFromNC()
		// dispatch(sendMoneyToCommonWallet())
	}, [])

	switch (state) {
		case "loading":
			return (
				<PlaceBetLayout>
					<Body>Loading...</Body>
				</PlaceBetLayout>
			)
		case "send":
			return (
				<PlaceBetLayout>
					<Body>Send transaction...</Body>
				</PlaceBetLayout>
			)
		case "success":
			return (
				<PlaceBetLayout>
					<Body>Transaction success !</Body>
				</PlaceBetLayout>
			)
		default:
			return (
				<PlaceBetLayout>
					<Body>Loading...</Body>
				</PlaceBetLayout>
			)
	}
}

export default PlaceBet
