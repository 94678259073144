import styled from "styled-components"
import { Colors, Content, Flex } from "../../../globalStyles"
import background3 from "../../../Assets/Images/Background3.jpg"

// export const ProfileWrapper = styled.div`
export const Wrapper = styled(Content)`
	position: relative;
	min-height: 100vh;
	/* height: 100vh; */
	/* width: 100%; */

	background-color: #04001b;
	z-index: 1;
	box-sizing: border-box;

	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: url(${background3}) no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		z-index: -1;
	}

	transition: 0.3s all ease;
	display: grid;
	place-items: center;
`

export const ErrorBlock = styled.div`
	display: grid;
	place-items: center;

	& > * {
		width: 100%;
	}

	width: min(60vw, 500px);
	height: min(60vh, max-content);
	/* text-align: center; */

	backdrop-filter: blur(5px);
	border-radius: 10px;
	padding: 50px;
`
export const ErrorTitle = styled.div`
	font-size: 29px;
	font-weight: 700;
	text-align: center;
`
export const ErrorImage = styled.div`
	text-align: center;
	img {
		text-align: center;
		margin-top: -15%;
		width: 60%;
	}
`
export const ErrorText = styled.div`
    margin-bottom: 20px;
	background-color: transparent;
	& * {
		background-color: transparent;
		/* color: black; */
	}
	& p {
		word-wrap: wrap;
		/* display: none; */
		overflow-wrap: break-word; /* не поддерживает IE, Firefox; является копией word-wrap */
		word-wrap: break-word;
		word-break: keep-all; /* не поддерживает Opera12.14, значение keep-all не поддерживается IE, Chrome */
		line-break: loose; /* нет поддержки для русского языка */
		-webkit-hyphens: auto;
		-ms-hyphens: auto;
		hyphens: auto;

        overflow-y: scroll;

        height: 200px;

        width: min(45vw, 350px);
	}
`
