import { Colors } from "globalStyles"
import styled from "styled-components"
export const HeadBlock = styled.div`
	display: flex;
	flex-direction: column;
	// width: 64ch;
	margin-bottom: 40px;
`

export const MainHeader = styled.div`
	position: relative;

	font-family: "Red Hat Display", sans-serif;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #ffffff;

	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: start;
`

export const BackButton = styled.div`
	width: 52px;
	height: 52px;
	background-color: #0554d1;
	margin-right: 40px;

	display: grid;
	place-items: center;

	border-radius: 10px;

	cursor: pointer;

	& svg {
		transform: rotate(180deg);
	}
`

export const AboutBlock = styled.div`
	display: grid;
	grid-template-columns: 35% 1fr;

	background-color: ${Colors.cardBG};
	border: 2px solid ${Colors.cardStroke};
	border-radius: 16px;

	padding: 40px;
	margin: 60px 0 80px;

	& a {
		color: ${Colors.blue};
		padding-left: 5px;
	}

	& p:first-child {
		font-weight: 500;
	}
`
