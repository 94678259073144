import styled from "styled-components"

export const Container = styled.div`
	> svg {
		transition: transform 0.2s ease-in-out;

		:hover {
			fill: red;
			cursor: pointer;
			transform: scale(1.05);
		}
	}
`
