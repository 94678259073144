import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const Container = styled(Flex)`
	margin-top: 50px;
	justify-content: space-between;
	align-items: center;

	@media screen and (min-width: 1680px) {
		margin-top: 25px;
	}

	@media screen and (min-width: 1280px) {
		margin-top: 10px;
	}
`

export const ModesContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-top: 20px;
	width: 30%;

	> div:first-child {
		width: 75%;

		@media screen and (max-width: 1680px) {
			width: 60%;
		}

		@media screen and (max-width: 1440px) {
			width: 55%;
		}
	}

	> div:nth-child(n + 2) {
		width: 100%;

		//@media screen and (max-width: 1680px) {
		//    width: 80%;
		//
		//    > div {
		//        font-size: 28px;
		//        line-height: 20px;
		//    }
		//}
		//
		//@media screen and (max-width: 1440px) {
		//    width: 65%;
		//
		//    > div {
		//        font-size: 20px;
		//        line-height: 12px;
		//    }
		//}
	}
`
