import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorPage from 'Pages/Auth/ErrorPage';

interface Props {
    children: ReactNode;
}

interface State {
    errorMessage: string | null;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { errorMessage: null }
    }

    static getDerivedStateFromError(error: Error) {
        return { errorMessage: error.message };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        if (this.state.errorMessage) {
            return <ErrorPage errorInfo={this.state.errorMessage}/>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
