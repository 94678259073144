import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { hubQueryWithReauth } from "./index"
import { Error } from "./types"
import { IMarketplaceData, IAssetMarketplace } from "../models/IMarketplace"

interface BuyQubixItemReq {
	name: string
}

interface BuyUserItemReq {
	name: string
	assetId: string
	walletId: string
}

interface BuyItemArgs {
	body: BuyQubixItemReq | BuyUserItemReq
	owner: string
}

interface GetItemArgs {
	name: string
	owner: string
}

export const marketAPI = createApi({
	reducerPath: "marketAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getMarketItems: build.query<IMarketplaceData, void>({
			query: () => ({
				url: "/marketplace/",
			}),
		}),
		getFreeAssets: build.query<IAssetMarketplace[], void>({
			query: () => ({
				url: "/marketplace/assets/free",
			}),
		}),
		getMarketItem: build.query<IAssetMarketplace, GetItemArgs>({
			query: ({ name, owner }) => ({
				url: owner === "qubix" ? `/marketplace/name/${name}` : `/marketplace/${name}`,
			}),
		}),
		buyMarketItem: build.mutation<BuyItemArgs, any>({
			query: ({ body, owner }) => ({
				url: owner === "qubix" ? "/marketplace/purchase" : "/marketplace/wallet",
				method: "POST",
				body,
			}),
		}),
	}),
})

export const { useGetMarketItemsQuery, useGetMarketItemQuery, useGetFreeAssetsQuery, useBuyMarketItemMutation } = marketAPI
