import styled from "styled-components"
import background from "Assets/Images/Background.jpg"

export const Wrapper = styled.div`
	position: relative;
	display: grid;
	justify-content: center;
	align-items: start;
	// background: url(${background}) no-repeat 100% 100%;
	// background-size: cover;
	background-color: #030b20;
	width: 100%;
	height: 100vh;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.3);
	}
`

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 1160px;
	margin: 100px 0;
`
