import styled from "styled-components"

export const ConnectAnywayButton = styled.span`
    color: white;
    font-size: 14px;
`

export const BugReportFieldTitle = styled.div`
	font-size: 14px;
`
export const HighPingModalText = styled.div`

`

export const CloseButton = styled.div`
	color: black;
    font-size: 14px;
`