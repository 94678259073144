import InstructionTop from '../../../Components/Molecules/Auth/ManualTop/ManualTop'
import InstructionTable from '../../../Components/Organisms/Auth/ManualTable/ManualTable'
import { Container, Wrapper } from './styles'

function Manual() {
    return (
        <Wrapper id="manual">
            <Container>
                <InstructionTop/>
                <InstructionTable/>
            </Container>
        </Wrapper>
    )
}

export default Manual;
