import styled from "styled-components"

export const MapsContainer = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: 30px;
	place-items: center;
`

interface MapTypes {
	bg: string
	active: boolean
    index: number;
}
export const Map = styled.div<MapTypes>`
	position: relative;
	/* overflow: hidden; */
	display: grid;
	align-items: center;
	min-height: 50px;
	min-width: 80%;

	transition: 0.3s all;
	cursor: pointer;
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background: url(${props => props.bg}) no-repeat;
		/* background-size: 100% 300%; */
		/* background-size: 100% auto; */
		background-size: cover;

		transition: 0.3s all;
		border-radius: 10px;
		opacity: ${({ active }) => (active ? 0.9 : 0.6)};
        /* transform: rotateY(180deg);
        filter: invert(90%); */
	}

	border-radius: 10px;
	box-shadow: ${({ active }) => active && "0px 0px 10px 10px purple"};
	&:hover {
		box-shadow: 0px 0px 10px 10px purple;
		&:after {
			opacity: 0.9;
		}
	}
`

export const Title = styled.div`
	position: absolute;
	z-index: 2;
	padding: 0 20px;
    font-weight: 900;
    font-size: 20px;
    letter-spacing: 2px;
    user-select: none;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
`

export const ContinueButton = styled.div`
	background-color: purple;
	padding: 10px 20px;
	border-radius: 5px;	
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 2px;
	user-select: none;
	cursor: pointer;
`