import React, {useState} from 'react';
import {useInitTelegramMutation} from 'services/UserService';
import GradientButton from 'Components/Atoms/Wallet/GradientButton';
import {Container, InputTitle, Input} from './styles';

interface IProps {
    closePopup: () => void
}

const ConnectTelegram = ({ closePopup }: IProps) => {
    const [nickname, setNickname] = useState<string>('')
    const [initTelegram, {isSuccess, isLoading, isError}] = useInitTelegramMutation()

    const onSendNickname = () => initTelegram({ nickname })

    return (
        <Container>
            {
                !isSuccess && !isLoading && !isError && (
                    <>
                        <InputTitle>
                            <span>Enter your nickname</span>
                        </InputTitle>
                        <Input
                            onChange={(e) => setNickname(e.target.value)}
                            value={nickname}
                            autoFocus={true}
                            placeholder="Text input"
                        />
                        <GradientButton
                            height={45}
                            onClick={onSendNickname}
                            disabled={!nickname}
                        >
                            Send
                        </GradientButton>
                    </>
                )
            }
            {
                isLoading && (
                    <span>Loading...</span>
                )
            }
            {
                isError && (
                    <>
                        <span>Ooops... Something went wrong</span>
                        <GradientButton onClick={onSendNickname}>Try again</GradientButton>
                    </>
                )
            }
            {
                isSuccess && (
                    <>
                        <span>Success!</span>
                        <GradientButton onClick={closePopup}>Okey</GradientButton>
                    </>
                )
            }
        </Container>
    );
};

export default ConnectTelegram;
