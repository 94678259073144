import styled from "styled-components"

const widthwrap = 700
const gap = 20

export const HintsWrap = styled.div`
	/* position: absolute; */

	/* right: calc(50% - ${widthwrap / 2}px + ${gap}px); */
	width: ${widthwrap}px;
	display: grid;
	place-items: center;
	text-align: center;
	margin: 0 auto;
	display: grid;
    column-gap: ${gap}px;
	grid-auto-flow: column;
	place-items: center;

	& img {
		/* width: 50px; */
		transform: scale(0.5);
	}
`

export const Images = styled.div`
	display: grid;
	grid-auto-flow: column;
	place-items: center;
`
const Block = styled.div`
	position: relative;
	display: grid;
	grid-template-rows: 70% 20%;
	height: 200px;
`

export const Movement = styled(Block)``
export const Attack = styled(Block)`
	& > div:first-child {
		grid-template-columns: 40% 40% 20%;
	}
`
export const Abilities = styled(Block)``
export const Plus = styled.div`
	font-size: 50px;
	font-weight: 700;
`
export const Deskription = styled.div`
	text-align: center;
`
export const MovementItem = styled.div`
	display: grid;
	height: 100%;
    grid-template-rows: 70% 1fr;

    img {
        align-self: end;
    }
	&:first-child img{
        display: inline-block;
        margin-bottom: -10px;
	}
	&:last-child {
	}
`
export const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
`
