import styled from "styled-components"
import { Layout } from "antd"

export const { Content } = Layout

export const Colors = { bg: "#030B20", blue: "#0554D1", cardBG: "#060E22", cardStroke: "#09132C", grey: "#2C3244", lightBlue: "#858D9A" }

export const Flex = styled.div`
	display: flex;
`

export const Grid = styled.div`
	display: grid;
`
