import { FC } from "react"
import { MainLogo, ResultText } from "./styles"

type Props = { victory: boolean }

const MatchmakingResultLogo: FC<Props> = ({ victory }) => {
	return <MainLogo>{victory ? <ResultText>VICTORY</ResultText> : <ResultText>DEFEAT</ResultText>}</MainLogo>
}

export default MatchmakingResultLogo
