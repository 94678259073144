import { Button, Modal } from "antd"
import { FC } from "react"
import { BuyTicketButton, InfoModalText, CloseButton } from "./style"
import "./style.css"

interface InfoModalProps {
	isModalOpen: boolean
	setIsModalOpen: (mode: boolean) => void
	text: string
}

const InfoModal: FC<InfoModalProps> = ({ isModalOpen, setIsModalOpen, text }) => {
	const handleOnClose = () => {
		setIsModalOpen(false)
	}

	return (
		<Modal
			title="Info"
			open={isModalOpen}
			onOk={handleOnClose}
			onCancel={handleOnClose}
			className="bugreport"
			centered={true}
			style={{ borderRadius: "10px" }}
			footer={null}
		>
			<InfoModalText>
				{text}
			</InfoModalText>
		</Modal>
	)
}

export default InfoModal
