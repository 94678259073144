import { DownOutlined } from "@ant-design/icons"
// import { MenuLogo } from "Assets/Images"
import { WalletOutlined } from "@ant-design/icons"
// import { useAuth } from "@arcana/auth-react"
import { Button, Dropdown, MenuProps, Skeleton, Space } from "antd"
import { MiniArrow } from "Assets/Icons/Common"
import { ReactComponent as Beta } from "Assets/Images/beta.svg"
import { ReactComponent as MenuLogo } from "Assets/Images/Qubix_Logo.svg"
import ChooseMinPlayersForStart from "Components/Atoms/Matchmaking/ChooseMinPlayersForStart"
import { useAppDispatch, useAppSelector } from "hooks/redux"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useGetMeQuery } from "services/UserService"
import { useGetWalletQuery } from "services/WalletService"
// import { setArcanaAuthLoading, setUserInfo } from "store/reducers/auth/AcranaSlice"
import { isAnonym, isMetaMaskUser } from "utils/checkUser"
import { ReactComponent as MetamaskLogo } from "../../../../Assets/Images/metamask.svg"
import { ReactComponent as TemplateAvatar } from "../../../../Assets/Images/TemplateAvatar.svg"
import TemplateAvatarPNG from "../../../../Assets/Images/TemplateAvatarPNG.png"
import {
	Avatar,
	ConnectWalletBtn,
	ConnectWalletText,
	Container,
	Logo,
	Navigate,
	NavItem,
	UserPanel,
	Wallet,
	WalletAddress,
	LogOutButton,
	DropdownOverlay,
	Balance,
	LoadingAccount,
	BalanceValue,
	SingOut,
	AnonymId,
	DropdownOverlayAnonym,
	PlayersCount,
	PlayersCountTitle,
	PlayersCountValue,
} from "./styles"
import genRandomNumberBySeed from "utils/genRandomNumberBySeed"
import { useWalletSelector } from "utils/near/wallet"
import { nearSlice } from "store/reducers/auth/NearSlice"

interface IProps {
	changePopup: (popup: string | null) => void
}

function ProfileHeader({ changePopup }: IProps) {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { pathname } = window.location
	const { data: meData, isSuccess: meIsSuccess, isLoading: isLoadingMeData } = useGetMeQuery()
	const { data: walletData, isError, isSuccess: walletIsSuccess, refetch } = useGetWalletQuery()
	// const { user: ArcanaUserData, isLoading: isLoadingArcanaAuth } = useAppSelector(state => state.arcana)
	const { account: tonAccount } = useAppSelector(state => state.ton)
	const { address, message, signature, chainId } = useAppSelector(state => state.metamaskReducer.account)
	const {
		account: nearAccount,
		contract: nearContract,
		walletConnection: nearWalletConnection,
		isLoading: isLoadingNear,
	} = useAppSelector(state => state.nearReducer)
	const [qbxmState, setQbxm] = useState<{ url: string; balance: string; shortName: string }>()
	const { modal, accounts, selector, accountId } = useWalletSelector()

	// const authArcana = useAuth()

	useEffect(() => {
		// if (!walletData) return
		// const qbxm = walletData.coins.find(coin => coin.shortName === "qbxm")
		// const balance = +(qbxm?.balance?.split(".")[0] as string)
		// setQbxm({
		// 	url: qbxm?.url as string,
		// 	balance: balance.toLocaleString("ru-RU"),
		// 	shortName: qbxm?.shortName?.toLocaleUpperCase() as string,
		// })
	}, [walletData])

	// const sliceBalance = (balance: string) => {
	// 	let b = balance?.split(".")[1]
	// 	b = b.slice(0, 3)
	// 	return [balance?.split(".")[0], b].join(".")
	// }

	const handleLogOut = async () => {
		try {
			localStorage.clear()
			sessionStorage.clear()

			await dispatch(nearSlice.actions.resetAuthData())
			const wallet = await selector.wallet()
			await wallet.signOut()


			// arcana logout
			// await authArcana.logout()
			// dispatch(setUserInfo(null))
			// dispatch(setArcanaAuthLoading(false))

			// TODO: проверить необходимость перезагрузки
			window.location.reload()
		} catch (err) {
			console.log(err)
		}
	}

	console.log({ meIsSuccess, address, noAnonym: !isAnonym(), nearAccount })
	return (
		<Container>
			<Logo href="/">
				<MenuLogo />
				<Beta style={{ position: "absolute" }} />
			</Logo>
			<Navigate>
				{/* <NavItem onClick={() => navigate("/profile")} isActive={pathname.indexOf("profile") >= 0}>
					GAME
				</NavItem> */}
				{/* <NavItem onClick={() => window.open(process.env.REACT_APP_UNITBOX_URL)} disable={!(meIsSuccess && address) || isMetaMaskUser()}> */}
				{/* <NavItem disable={true}>RENT NFT</NavItem>
				<NavItem onClick={() => navigate("")} disable={true}>
					STATS
				</NavItem>
				<NavItem onClick={() => navigate("")} disable={true}>
					ROOMS
				</NavItem>
				<NavItem onClick={() => navigate("")} disable={true}>
					MARKETPLACE
				</NavItem>
				<NavItem onClick={() => navigate("")} disable={true}>
					STAKING
				</NavItem> */}
			</Navigate>
			{/* <SingOut onClick={handleLogOut}>
				Log out
			</SingOut> */}
			<UserPanel>
				<PlayersCount>
					<PlayersCountTitle>Players:</PlayersCountTitle>
					<PlayersCountValue>{genRandomNumberBySeed()}</PlayersCountValue>
				</PlayersCount>
				{isLoadingMeData || isLoadingNear ? (
					// <LoadingAccount>Loading account</LoadingAccount>
					<></>
				) : // <Skeleton.Button active block={false} style={{ width: "15vw" }} />
				// ) : meIsSuccess && address && !isAnonym() ? (
				tonAccount ? (
					<Wallet>
						<div onClick={e => e.preventDefault()} style={{ display: "grid", gridAutoFlow: "column" }}>
							<WalletAddress>{tonAccount.decoded_jwt.username}</WalletAddress>
						</div>
					</Wallet>
				) : nearAccount ? (
					<>
						<Wallet>
							<Dropdown
								overlay={
									<DropdownOverlay>
										<LogOutButton onClick={handleLogOut}>Log out</LogOutButton>
									</DropdownOverlay>
								}
								placement="bottom"
							>
								<div onClick={e => e.preventDefault()} style={{ display: "grid", gridAutoFlow: "column" }}>
									<WalletOutlined style={{ marginRight: "10px", fontSize: "10px" }} />
									<WalletAddress title={nearAccount?.accountId}>
										{nearAccount?.accountId.length > 15 ? (
											<>{`...${nearAccount?.accountId?.slice(nearAccount?.accountId.length - 10)}`}</>
										) : (
											<>{nearAccount?.accountId}</>
										)}
									</WalletAddress>
									<MiniArrow />
								</div>
							</Dropdown>
						</Wallet>
					</>
				) : (
					<AnonymId>ID: {localStorage.getItem("anonymUserId")?.split("-")?.at(-1)}</AnonymId>
				)}
				<Avatar>
					<img src={TemplateAvatarPNG} alt="" />
				</Avatar>
			</UserPanel>
			{/* <div style={{position: 'absolute', top: 0, right: 0, fontSize: "20px"}}>{isAnonym() ? 'anonym' : 'no_anonym'}</div> */}
		</Container>
	)
}

export default ProfileHeader

{
	/* {nearAccount?.accountId.split(".testnet").at(-2)?.slice(0, 10)}...
{nearAccount?.accountId.split(".").at(-1)} */
}
