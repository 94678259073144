import { FC } from "react"
import { Header, Stat, StatisticsBlock, Stats } from "../MatchmakingStatistics/styles"

interface Props {
	bet: string
	win?: string
    winner: boolean
}

const MatchmakingWinBet: FC<Props> = ({bet, win, winner}) => {
    if (winner){
        return (
            <>
                <Header>
                    <span>Win</span>
                </Header>
                <StatisticsBlock>
                    <Stats>
                            <Stat>
                                <span>Your Tickets</span>
                                <span>{bet}</span>
                            </Stat>
                            <Stat>
                                <span>Your Win</span>
                                <span>{win || 0}</span>
                            </Stat>
                    </Stats>
                </StatisticsBlock>
            </>
        )
    } else {
        return (
            <>
                <Header>
                    <span>Lose</span>
                </Header>
                <StatisticsBlock>
                    <Stats>
                            <Stat>
                                <span>Your Tickets</span>
                                <span>{bet}</span>
                            </Stat>
                            <Stat>
                                <span>Your Lose</span>
                                <span>{bet}</span>
                            </Stat>
                    </Stats>
                </StatisticsBlock>
            </>
        )
    }
}

export default MatchmakingWinBet
