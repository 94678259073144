import React from 'react';
import {Link, Wrapper} from './styles';

interface IProps {
    errorInfo: string
}

const ErrorPage = ({ errorInfo }: IProps) => {
    const onClick = () => {
        window.location.href = '/profile'
    }

    return (
        <Wrapper>
            <span>Something went wrong.</span>
            <span>Please contact the developers</span>
            <code>Error message: {errorInfo}</code>
            <Link onClick={onClick}>Go to profile</Link>
        </Wrapper>
    );
};

export default ErrorPage;
