import styled from "styled-components"
import background1 from "../../../../Assets/Images/Background1.png"
import background2 from "../../../../Assets/Images/Background2.png"
import { Content, Flex } from "../../../../globalStyles"

export const Wrapper = styled(Content)`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url(${background2}) no-repeat;
	background-size: 100% 100%;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-clip: padding-box;
	z-index: 1;
	box-sizing: border-box;

	@media (max-height: 550px) {
		overflow-x: scroll;
	}
`

export const Container = styled(Flex)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	// position: relative;
	width: 55%;
	height: 100%;
	z-index: 2;
	background: url(${background1}) no-repeat center;
	background-size: min(100%, 850px);
`

export const ContentBlock = styled(Flex)`
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 40px;
	justify-content: center;
	border-radius: 5px;

	&:before {
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		heigth: 100%;
		position: absolute;
		z-index: -1;
		border-radius: 25px;
		background: rgba(0, 0, 0, 0.5);
		backdrop-filter: blur(6px) opacity(0.9);
	}
`


export const Body = styled.div`
    text-align: center;
    display: grid;
    place-items: center;
    font-size: 30px;
`