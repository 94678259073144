import styled from "styled-components"

export const Button = styled.button<{ height?: number }>`
	font-family: "Red Hat Display", sans-serif;
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	color: #fff;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: 20px;
	width: 100%;
	height: ${({ height }) => height}px;

	background-image: linear-gradient(94.41deg, #da2eaf -3.5%, #02b7b7 102.94%);
	border-radius: 8px;

	outline: none;
	border: none;

	&:hover {
		cursor: pointer;
	}

	transition: all 0.3s ease-in-out;

	:disabled {
		pointer-events: none;
		background: linear-gradient(grey, darkgray);
		color: #fff;
		transition: all 0.3s ease-in-out;
	}
`
