import styled from "styled-components"

export const TonErrorText = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: black;
    color: white;
    font-size: 3vw;
    font-weight: 800;
    letter-spacing: 2px;

    display: grid;
    place-items: center;
    text-align: center;
`