import { BaseQueryFn, createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react"
import { Error } from "./types"
import { hubQueryWithReauth } from "./index"

interface IRate {
	id: number
	from: string
	to: string
	rate: string
	createdAt: string
	updatedAt: string
}

interface CommissionReq {
	from: string
	to: string
	input: "from" | "to"
	value: string
}

export interface CommissionRes {
	fee: string // комиссия
	loss: string // сколько монет ушло
	income: string // сколько монет пришло
	partialLoss: string // сколько монет ушло без комиссии
}

export const exchangeAPI = createApi({
	reducerPath: "exchangeAPI",
	baseQuery: hubQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, Error>,
	endpoints: build => ({
		getAllRates: build.query<IRate[], void>({
			query: () => ({
				url: "/rates",
			}),
		}),
		getLastRate: build.query<IRate, { from: string; to: string }>({
			query: ({ from, to }) => ({
				url: `/rates/last?from=${from}&to=${to}`,
			}),
		}),
		getRates: build.mutation<CommissionRes, CommissionReq>({
			query: body => ({
				url: "/rates/commission",
				method: "POST",
				body,
			}),
		}),
	}),
})

export const { useGetAllRatesQuery, useGetLastRateQuery, useGetRatesMutation } = exchangeAPI
