import React from 'react';
import {Container} from './styles';
import {QubixLogo} from '../../../../Assets/Icons/Matchmaking';
import CloseButton from '../../../Atoms/Matchmaking/CloseButton';

function MatchmakingHeader() {
    return (
        <Container>
            {/*<Timer />*/}
            <QubixLogo />
            <CloseButton />
        </Container>
    );
}

export default MatchmakingHeader;
