import styled from "styled-components"
import { Flex } from "../../../../globalStyles"

export const Container = styled.div`
	position: relative;
	width: 20%;
`

export const Title = styled(Flex)`
	position: absolute;
	inset: 0;
	justify-content: center;
	align-items: center;
	text-transform: uppercase;
	font-family: "Red Hat Display", sans-serif;
	font-size: 36px;
	line-height: 44px;
	color: #da2eaf;

	@media screen and (max-width: 1680px) {
		font-size: 30px;
		line-height: 38px;
	}

	@media screen and (max-width: 1440px) {
		font-size: 22px;
		line-height: 30px;
	}
`
