import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
import { AppStore, RootState } from "store/store"


interface WalletType {
	blockchain: string
	walletAddress: string
	tokenAddress: string
	wNFTs: {
		tokenId: string
		tokenAddress: string
		wNFTTokenId: string
		wNFTTokenAddress: string
	}[]
}

interface IndividualTokens {
	blockchain: string
	tokenId: string
	tokenAddress: string
	wNFTTokenId: string
	wNFTTokenAddress: string
	walletAddress: string
	status: string
}

export interface MetaData {
	metadata: {
		description?: string
		external_url?: string
		image?: string
		player_id?: string
		name?: string
		attributes?: { trait_type: string; value: number; max_value: number }[]
	}
	metadataURI?: string
	blockchain?: string
	tokenId?: string
	tokenAddress?: string
	wNFTTokenId: string
	wNFTTokenAddress?: string
	walletAddress?: string
	status?: string
}

type IState = { walletInfo: WalletType | null; individualTokens: IndividualTokens[]; isPending: boolean; nfts: MetaData[] }

const initialState: IState = {
	walletInfo: null,
	individualTokens: [],
	nfts: [],
	isPending: false,
}

export const getWNFTWalletInfo = createAsyncThunk("getWNFTWalletInfo", async (_, { getState, rejectWithValue }) => {
	try {
		const walletAddress = (getState() as any).metamaskReducer.account.address
		if (!walletAddress) return

		const walletInfo = await axios
			.get(`${process.env.REACT_APP_HUB_SERVER}/api/v1/wnft/wallet`, {
				params: { blockchain: "GOR", walletAddress, tokenContractAddress: process.env.REACT_APP_QBXC_CONTRACT_ADDRESS },
				// params: { blockchain: "GOR", walletAddress, tokenContractAddress: process.env.REACT_APP_UNITBOX_CONTRACT_ADDRESS },
			})
			.then(res => res.data?.data)
		return walletInfo
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

export const getWNFTTokenInfo = createAsyncThunk("getWNFTTokenInfo", async (wNFTTokenId: string, { getState, rejectWithValue }) => {
	try {
		const walletAddress = (getState() as any).metamaskReducer.account.address
		if (!walletAddress) return

		const walletInfo = await axios
			.get(`${process.env.REACT_APP_HUB_SERVER}/api/v1/wnft/token`, {
				params: { blockchain: "GOR", id: wNFTTokenId },
			})
			.then(res => res.data?.data)

		return walletInfo
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

export const getWNFTTokenMetaData = createAsyncThunk("getWNFTTokenMetaData", async (wNFTTokenId: string, { getState, rejectWithValue }) => {
	try {
		const metaDataAddress = (getState() as any).metamaskReducer.account.address
		if (!metaDataAddress) return

		const metaDataInfo = await axios
			.get(`${process.env.REACT_APP_HUB_SERVER}/api/v1/wnft/metadata`, {
				params: { blockchain: "GOR", id: wNFTTokenId },
			})
			.then(res => res.data?.data)
		return metaDataInfo
	} catch (e) {
		if (axios.isAxiosError(e)) {
			return rejectWithValue(e.message)
		}
		return
	}
})

export const wnftSlice = createSlice({
	name: "wnft",
	initialState,
	reducers: {
		clearWNFTsList(state) {
			state.nfts = []
		},
	},
	extraReducers: {
		[getWNFTWalletInfo.fulfilled.type]: (state, action: PayloadAction<WalletType>) => {
			console.log("getWNFTWalletInfo", action.payload)
			state.walletInfo = action.payload
			state.isPending = false
		},
		[getWNFTWalletInfo.pending.type]: (state, action: PayloadAction<WalletType>) => {
			state.walletInfo = action.payload
			state.isPending = true
		},
		[getWNFTTokenInfo.fulfilled.type]: (state, action: PayloadAction<IndividualTokens>) => {
			console.log("getWNFTTokenInfo", action.payload)
			state.individualTokens.push(action.payload)
			state.isPending = false
		},
		[getWNFTTokenMetaData.fulfilled.type]: (state, action: PayloadAction<MetaData>) => {
			console.log("getWNFTTokenMetaData", action.payload)
			if (!state?.nfts?.map(nft => nft.wNFTTokenId)?.includes(action.payload.wNFTTokenId)) {
				state.nfts?.push(action.payload)
			}
			state.isPending = false
		},
	},
})

export default wnftSlice.reducer
