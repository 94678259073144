import React from 'react';
import {useNavigate} from 'react-router-dom';
import MainImage from 'Assets/Images/About/MainImage.jpg';
import HistoryImage from 'Assets/Images/About/HistoryImage.png'
import InsensitiveCube from 'Assets/Images/About/InsensitiveCube.png';
import EmotionalQubix from 'Assets/Images/About/EmotionalQubix.png';
import Useful from 'Assets/Images/About/Useful.png';
import IrreplaceableImage from 'Assets/Images/About/Irreplaceable.png';
import QubixR from 'Assets/Images/About/QubixR.png';
import QubixD from 'Assets/Images/About/QubixD.png';
import {ReactComponent as HistoryDec} from 'Assets/Images/About/HistoryDec.svg';
import {ReactComponent as RunnerIcon} from 'Assets/Images/About/RunnerIcon.svg';
import {ReactComponent as DeathmatchIcon} from 'Assets/Images/About/DeathmatchIcon.svg';
import {
    Wrapper,
    Container,
    Navigate,
    NavItem,
    History,
    Section,
    GradientText,
    DivHeader,
    Irreplaceable,
    Games,
    Game,
    PlayButton
} from './styles';

const About = () => {
    const navigate = useNavigate()

    const scrollToHandle = (id: string) => {
        const el = document.getElementById(id) as HTMLDivElement

        if (el) {
            el.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            })
        }
    }

    return (
        <Wrapper>
            <Navigate>
                <div>
                    <NavItem onClick={() => scrollToHandle('Insensitive')}>Insensitive cube</NavItem>
                    <NavItem onClick={() => scrollToHandle('Emotional')}>Emotional Qubix</NavItem>
                    <NavItem onClick={() => scrollToHandle('Useful')}>Useful</NavItem>
                    <NavItem onClick={() => scrollToHandle('Irreplaceable')}>Irreplaceable</NavItem>
                    <NavItem onClick={() => scrollToHandle('battlefields')}>Virtual battlefields</NavItem>
                </div>
                <div>
                    <NavItem onClick={() => scrollToHandle('Games')}>Games</NavItem>
                    <NavItem onClick={() => navigate('/profile/wallet')}>Back to profile</NavItem>
                </div>
            </Navigate>
            <Container>
                <img src={MainImage} alt="Main Game"/>
                <History>
                    <div>
                        <span>
                            By the year 3127 humanity has reached far beyond the home planet and its<br/>
                            solar system. Building highly advanced space stations and cities. As the<br/>
                            technology developed, humans built Qubixes – powered by state of the art<br/>
                            artificial intelligence, durable, adaptable robots. A valuable asset to help<br/>
                            conquer the cosmos.
                        </span>
                        <div>
                            <div>
                                <HistoryDec/>
                            </div>
                            <div>
                                <HistoryDec/>
                            </div>
                            <div>
                                <HistoryDec/>
                            </div>
                        </div>
                    </div>
                    <img src={HistoryImage} alt="History"/>
                </History>
                <Section id="Insensitive">
                    <div>
                        <DivHeader>Insensitive cube</DivHeader>
                        <img src={InsensitiveCube} alt="InsensitiveCube"/>
                    </div>
                    <div>
                        <span>
                            At first those robots were used at the companies that developed<br/>
                            them, to take on hard and dangerous labors. But soon enough the<br/>
                            idea of a helpful cube was taken outside <GradientText>the company bounds…</GradientText> but<br/>
                            it failed.<br/>
                        </span>
                        <span>
                            <br/>
                            <GradientText>It was hard for a human to believe to and care about an emotionless<br/>
                            cube with lights and buttons.</GradientText> As it was a thousand years ago –<br/>
                            people were afraid those thing they did not understand.
                        </span>
                    </div>
                </Section>
                <Section id="Emotional">
                    <div>
                        <DivHeader>Emotional Qubix</DivHeader>
                        <img src={EmotionalQubix} alt="EmotionalQubix"/>
                    </div>
                    <div>
                        <span>
                            Great minds that developed the Qubix in the first place thought<br/>
                            about it and came with a solution, presenting a new model with<br/>
                            simple, yet comprehensive facial plate module, <GradientText>that allowed Qubix to<br/>
                            show simple emotions and react almost humanly to the situation.</GradientText><br/>
                        </span>
                        <span>
                            <br/>
                            Thanks to marketing geniuses those new models quickly gained the<br/>
                            interest of the public and <GradientText>became highly demanded among common<br/>
                            people.</GradientText>
                        </span>
                    </div>
                </Section>
                <Section id="Useful">
                    <div>
                        <DivHeader>Useful</DivHeader>
                        <img src={Useful} alt="Useful"/>
                    </div>
                    <div>
                        <span>
                            Years went by, <GradientText>production of Qubixes raised</GradientText> and the amount of<br/>
                            companies that made those helpful cubes scaled up, and areas<br/>
                            where people could use Qubixes became increasingly vast.<br/>
                        </span>
                        <span>
                            <br/>
                            As the time went by, those simply shaped and cutely smiling cubes<br/>
                            advanced to a level at which they were sent to <GradientText>nearby asteroid</GradientText> and<br/>
                            far off planets to mine resources.
                        </span>
                    </div>
                </Section>
                <Section id="Irreplaceable">
                    <div>
                        <DivHeader style={{ marginBottom: '30px' }}>Irreplaceable robots</DivHeader>
                        <span>
                            In the span of merely <GradientText>20 years</GradientText> they became <GradientText>irreplaceable</GradientText> in space travels,<br/>
                            thus leaving Corporations behind the production with a question:<br/>
                            <GradientText>Who would hold the monopoly to make new Qubixes?</GradientText>
                        </span>
                        <img src={IrreplaceableImage} alt="Irreplaceable" style={{ marginTop: '40px'}}/>
                    </div>
                    <Irreplaceable>
                        <div>
                            <span>
                                <GradientText>The competition quickly escalated, and became a real
                                war.</GradientText> In the race to be the prime developer – the Corporations
                                searched and savagely destroyed Qubixes of their competitors.<br/>
                            </span>
                            <span>
                                <br/>
                                In this war – <GradientText>the emotion</GradientText> that appeared on the facial plate that allowed
                                to popularize Qubix <GradientText>– now became their weakness.</GradientText>
                            </span>
                        </div>
                        <div>
                            <span>
                                <GradientText>They were easy to influence</GradientText><br/>
                                with negative emotions:<br/>
                                when Qubix saw a sad or an<br/> angry expression they<br/>
                                became confused, puzzled<br/>
                                leaving them <GradientText>vulnerable.</GradientText>
                            </span>
                        </div>
                    </Irreplaceable>
                </Section>
                <Section id="battlefields">
                    <div>
                        <DivHeader style={{ marginRight: '180px' }}>Virtual battlefields</DivHeader>
                    </div>
                    <div>
                        <span>
                            <GradientText>Qubix with a permanent imprint of anger left the production line…</GradientText>
                            <br/>The Corporations frequently destroyed Qubixes of smaller companies<br/>
                            without a doubt, leaving them bankrupt.<br/>
                        </span>
                        <span>
                            <br/>
                            The free miners – fearing for their survival – desperately tried to<br/>
                            stay alive in this war. They united in their attempt to fight off the<br/>
                            Corporations, <GradientText>creating virtual battlefields for Qubixes</GradientText> to
                            learn to<br/> fight.<br/>
                        </span>
                        <span>
                            <br/>
                            There Qubix could select and <GradientText>test different parts and modules,</GradientText>
                            <br/>strategies and tactics, go on different environments to enlarge self-<br/>
                            learning AI’s database and <GradientText>prepare</GradientText> to any unexpected situation.
                        </span>
                    </div>
                </Section>
                <Games id="Games">
                    <DivHeader>Our Games</DivHeader>
                    <Game>
                        <img src={QubixR} alt="QubixRunner"/>
                        <div>
                            <RunnerIcon/>
                            <PlayButton
                                href="https://play.google.com/store/apps/details?id=com.NextGamesGeneration.QubixRunner&hl=ru&gl=US"
                                target="_blank"
                            >
                                Play
                            </PlayButton>
                        </div>
                    </Game>
                    <Game>
                        <img src={QubixD} alt="QubixDeathmatch"/>
                        <div>
                            <DeathmatchIcon/>
                            <PlayButton
                                href="https://deathmatch.qubixinfinity.io/"
                                target="_blank"
                            >
                                Play
                            </PlayButton>
                        </div>
                    </Game>
                </Games>
            </Container>
        </Wrapper>
    );
};

export default About;
