import styled from "styled-components"
import Background from "Assets/Images/Background.jpg"
import { Flex } from "globalStyles"
import { Button } from "../../../Components/Atoms/Wallet/GradientButton/styles"

export const Wrapper = styled(Flex)`
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-image: url(${Background});
	background-size: 100% 100%;
	background-clip: padding-box;
	box-sizing: border-box;

	span {
		font-family: "Red Hat Display", sans-serif;
		font-size: 30px;
		line-height: 36px;
		color: #ffffff;
	}

	> code {
		font-family: "Red Hat Display", sans-serif;
		font-size: 20px;
		line-height: 26px;
		color: #f4f4f4;
		margin-top: 10px;
	}
`

export const Link = styled(Button)`
	margin-top: 20px;
	width: 15%;
	font-size: 24px;
`
